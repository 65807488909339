type ButtonType =
  | "EDIT_MEMBERSHIP"
  | "INSUFFICIENT_ACCESS"
  | "EXPIRED"
  | "CHANGE_BILLING_FREQUENCY"
  | "SUBSCRIBED"
  | "DOWNGRADE_FOR_FREE"
  | "CONTINUE"
  | "JOIN_FOR_FREE"
  | "SUBSCRIBE"
  | "CHANGE_SUBSCRIPTION"
  | "PAST_DUE"

export type BtnDetails = {
  text: string
  px?: number
  spinnerRight?: string
}

export const BtnDetails: Record<ButtonType, BtnDetails> = {
  EDIT_MEMBERSHIP: {
    text: "Edit membership",
  },
  INSUFFICIENT_ACCESS: {
    text: "Insufficient access",
  },
  EXPIRED: {
    text: "Expired",
  },
  CHANGE_BILLING_FREQUENCY: {
    text: "Change Billing Frequency",
    px: 2,
    spinnerRight: "-right-5",
  },
  SUBSCRIBED: {
    text: "Subscribed",
    px: 2,
    spinnerRight: "right-1",
  },
  DOWNGRADE_FOR_FREE: {
    text: "Downgrade for Free",
    px: 4,
    spinnerRight: "-right-4",
  },
  CONTINUE: {
    text: "Continue",
    px: 3,
    spinnerRight: "-right-4",
  },
  JOIN_FOR_FREE: {
    text: "Join for free",
    px: 4,
    spinnerRight: "-right-3.5",
  },
  SUBSCRIBE: {
    text: "Subscribe",
    px: 4,
    spinnerRight: "-right-4",
  },
  CHANGE_SUBSCRIPTION: {
    text: "Change Subscription",
    px: 4,
    spinnerRight: "-right-4",
  },
  PAST_DUE: {
    text: "Payment Renewal Failed",
  },
}

import { CompletedCollectible } from "@/types/collectibles"
import useMintExistingCollectibleV0 from "./v0"
import useMintExistingCollectibleV1 from "./v1"

/**
 * This is a helper method that uses the _proper_
 * mintExistingCollectible hook.
 *
 * The underlying hooks don't run anything if the
 * collectible's version is not the same as the
 * version of the hook.
 *
 * So, this method just returns the relevant hook.
 */
export default function useMintExistingCollectible(
  collectible: CompletedCollectible
) {
  console.log("useMintExistingCollectible", collectible)

  const v0Return = useMintExistingCollectibleV0(collectible)
  const v1Return = useMintExistingCollectibleV1(collectible)

  if (collectible.version === 0) {
    return v0Return
  }

  return v1Return
}

import { BigNumber, constants } from "ethers"

function formatBalance(balance: BigNumber, totalAmount: BigNumber) {
  if (totalAmount.eq(constants.MaxUint256)) {
    return {
      balance: "∞",
      totalAmount: "∞",
    }
  }
  return {
    balance: balance.toString(),
    totalAmount: totalAmount.toString(),
  }
}

function extractChainInfo(inputString: string): [string, string] | null {
  const regex = /Expected "([\w\d\s]+)", received "([\w\d\s]+)"/
  const match = inputString.match(regex)

  if (!match) {
    console.log("er nothing")
    return null
  }
  const expected = match?.[1]
  const received = match?.[2]

  if (!expected || !received) return null

  return [expected, received]
}

// Checks if a given wagmi error is due to an insufficient funds error code.
function checkInsufficientFundsErrorCode(error: Error): boolean {
  return (
    error &&
    "code" in error &&
    error.code == -32603 &&
    "data" in error &&
    // @ts-ignore
    "code" in error.data &&
    error.data.code == -32000
  )
}

enum WagmiErrorType {
  InsufficientBalance = "INSUFFICIENT_BALANCE",
  WrongChain = "WRONG_CHAIN",
  Rejected = "REJECTED",
  Unknown = "UNKNOWN",
}

/**
 * Parsed Wagmi error object that contains the type of error and display strings.
 */
type ParsedWagmiError = {
  type: WagmiErrorType
  msg: string
  msgShort: string
}

/**
 * Parses a Wagmi error and makes it into something useful.
 * @param error The Wagmi error you want parsed.
 * @returns Returns the type of error, as well as a long and short display string for presentation to the user.
 */
export function ParseWagmiError(error: Error): ParsedWagmiError {
  const originalErrMsg =
    // @ts-ignore
    error?.data?.message ||
    // @ts-ignore
    error?.reason ||
    error?.message ||
    JSON.stringify(error)

  console.error("Original error message", originalErrMsg)

  // Detailed "wrong chain" error message, if we have it
  const chainMismatch = extractChainInfo(originalErrMsg)
  if (chainMismatch) {
    return {
      type: WagmiErrorType.WrongChain,
      msg:
        "You're connected to the wrong chain. Switch to " +
        chainMismatch[0] +
        " to collect.",
      msgShort: "Wrong chain",
    }
  }

  // Basic "wrong chain" error message if we have it.
  const wrongChainMessage = [
    "does not match the target chain",
    "Expected Chain ID",
  ]

  for (const msg of wrongChainMessage) {
    if (originalErrMsg?.includes(msg)) {
      return {
        type: WagmiErrorType.WrongChain,
        msg: "You're connected to the wrong chain. Switch to the correct network to collect.",
        msgShort: "Wrong chain",
      }
    }
  }

  const insufficientFundsMessage = ["insufficient funds", "Not enough funds"]

  for (const msg of insufficientFundsMessage) {
    if (originalErrMsg?.includes(msg)) {
      return {
        type: WagmiErrorType.InsufficientBalance,
        msg: "You don't have enough funds in your wallet.",
        msgShort: "Insufficient balance",
      }
    }
  }

  if (checkInsufficientFundsErrorCode(error)) {
    return {
      type: WagmiErrorType.InsufficientBalance,
      msg: "You don't have enough funds in your wallet.",
      msgShort: "Insufficient balance",
    }
  }

  const rejectedMessage = ["User rejected"]

  for (const msg of rejectedMessage) {
    if (originalErrMsg?.includes(msg)) {
      return {
        type: WagmiErrorType.Rejected,
        msg: "You rejected the transaction.",
        msgShort: "Transaction rejected",
      }
    }
  }

  return {
    type: WagmiErrorType.Unknown,
    msg: originalErrMsg,
    msgShort: "Unknown error",
  }
}

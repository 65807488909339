import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  getAllNotes,
  getLatestNotes,
  saveNote,
  newNote,
  deleteNote as deleteNoteApi,
  deleteAllNotes as deleteAllNotesApi,
  getNote,
  getLatestNotePreviews,
} from "api_routes/notes"

import { getPublishedNotes, getPublishedNote } from "api_routes/published"

import { makeToast, selectSearchText } from "features/pageSlice"

import { createSelector } from "@reduxjs/toolkit"

import { analytics } from "hooks/useAnalytics"

import { AppDispatch, RootState, Thunk as AppThunk, Thunk } from "../store"

import {
  ErrorResponse,
  GetNotePreviewResp,
  GetNoteResp,
  GetNoteRespFullOrPreview,
  SuccessResponse,
  isErrorResponse,
} from "@types"

import * as filter from "../util/filter"
import { HYDRATE } from "next-redux-wrapper"
import {
  addPostGatingRuleGroups,
  getPostGatingRuleGroups,
} from "./gatingRuleGroupsSlice"
import { PostMetricsResp, RevenueMetricDisplaySummary } from "@/types/metrics"
import {
  getRevenueMetricsForBlog,
  getPostMetricsForBlog,
} from "api_routes/metrics"
import { setError } from "./errorSlice"

interface NoteToUpdate {
  noteId: string
  note: Partial<GetNoteResp>
}

interface NoteState {
  initialLoad: boolean
  saving: boolean
  currentNoteId: (string | null) | undefined // This can be either the ID, OR the slug. To get the ID, check currentNote.id. If this is undefined, theres NO note ID.
  paginationNoteSize: number // For pagination only (public note display), this is the size of all notes
  allNotes: Array<GetNoteResp> | null
  latestNotes: Array<GetNoteRespFullOrPreview> | null
  pinnedPosts: Array<GetNoteResp> | null
  postMetrics: Array<PostMetricsResp> | null // Metrics related to the latest notes.
  revenueMetrics: RevenueMetricDisplaySummary | null // Revenue metrics for the blog.
}

const initialState: NoteState = {
  initialLoad: true,
  saving: false,
  paginationNoteSize: 0,
  currentNoteId: null, // Only used when the user is currently viewing
  allNotes: null, // ALL notes, retrieved from API. Used for public pages where we don't want to display drafts. Set to null when loading.
  pinnedPosts: null, // Pinned posts, retrieved from API. Set to null when loading.
  /**
   * Latest full notes (including latest unpublished drafts), retrieved from API. Set to undefined when loading.
   * These are partial because they may just be note previews (with just a title and subtitle) and not contain the full note JSON
   * and HTML until a user actually clicks on that note and loads the rest of the record.
   */
  latestNotes: null,
  postMetrics: null, // Metrics related to the latest notes.
  revenueMetrics: null, // Revenue metrics for the blog.
}

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.saving = action.payload
    },
    setCurrentNoteId: (state, action: PayloadAction<string | null>) => {
      console.log("Setting current note ID to: ", action.payload)
      state.currentNoteId = action.payload
      //state.currentNoteId = action.payload || state.allNotes?.[0]?.id || ""
    },
    insertIntoAllNotes: (state, action: PayloadAction<GetNoteResp>) => {
      if (!state.allNotes) state.allNotes = []

      const noteIdx = state.allNotes.findIndex(
        (note) => note.id === action.payload.id
      )

      if (noteIdx >= 0) {
        state.allNotes[noteIdx] = action.payload
      } else {
        state.allNotes.push(action.payload)
      }
    },
    setAllNotes: (state, action: PayloadAction<Array<GetNoteResp>>) => {
      state.allNotes = action.payload
    },
    setPinnedPosts: (state, action: PayloadAction<Array<GetNoteResp>>) => {
      state.pinnedPosts = action.payload
    },
    insertIntoLatestNotes: (
      state,
      action: PayloadAction<GetNoteRespFullOrPreview>
    ) => {
      if (!state.latestNotes) state.latestNotes = []

      const noteIdx = state.latestNotes.findIndex(
        (note) => note.id === action.payload.id
      )

      if (noteIdx >= 0) {
        state.latestNotes[noteIdx] = action.payload
      } else {
        state.latestNotes.push(action.payload)
      }
    },
    setLatestNotes: (
      state,
      action: PayloadAction<Array<GetNoteRespFullOrPreview>>
    ) => {
      state.latestNotes = action.payload
    },
    setPaginationNoteSize: (state, action: PayloadAction<number>) => {
      state.paginationNoteSize = action.payload
    },
    addCategory: (
      state,
      action: PayloadAction<{
        category: string
        note: GetNoteRespFullOrPreview
      }>
    ) => {
      if (!state.latestNotes) return
      const i = state.latestNotes?.findIndex(
        (note) => note.id === action.payload.note.id
      )

      state.latestNotes[i].categories = state.latestNotes[i].categories || []
      state.latestNotes[i]?.categories?.push(action.payload.category)
    },

    removeCategory: (
      state,
      action: PayloadAction<{
        category: string
        note: GetNoteRespFullOrPreview
      }>
    ) => {
      console.log("Removing category...", action.payload)
      if (!state.latestNotes) return
      const i = state.latestNotes.findIndex(
        (n) => n.id === action.payload.note.id
      )
      console.log("Gotindex " + i)
      console.log("Latest notes is", state.latestNotes)

      const removeIndex = state.latestNotes?.[i]?.categories?.indexOf(
        action.payload.category
      )
      console.log("Remove index " + removeIndex)

      removeIndex !== undefined &&
        removeIndex >= 0 &&
        state.latestNotes[i]?.categories?.splice(removeIndex, 1)
    },

    // action.payload = noteId
    deleteNote: (state, action: PayloadAction<string>) => {
      if (!state.allNotes) return
      const noteToDelete1 = state.allNotes.findIndex(
        (note) => note.id === action.payload
      )
      state.allNotes.splice(noteToDelete1, 1)

      if (!state.latestNotes) return
      const noteToDelete2 = state.latestNotes.findIndex(
        (note) => note.id === action.payload
      )
      state.latestNotes.splice(noteToDelete2, 1)

      // TODO: How do we handle updating the current noteID, if the
      // current note was archived?
      //if (action.payload === state.currentNoteId) {
      //  state.currentNoteId = state.allNotes[noteToUpdate - 1]?.id || ""
      //}
    },
    // action.payload = { noteId, note }
    updateAllNotes: (state, action: PayloadAction<NoteToUpdate>) => {
      if (!state.allNotes) return

      const noteToUpdate = state.allNotes.findIndex(
        (note) => note.id === action.payload.noteId
      )
      // if (noteToUpdate === -1) return

      state.allNotes[noteToUpdate] = {
        ...state.allNotes[noteToUpdate],
        ...action.payload.note,
        ...(action.payload.note.draftOf === null && {
          draftOf: "",
        }),
      }

      const draftOf = state.allNotes[noteToUpdate].draftOf

      if (action.payload.note.published && draftOf) {
        const previouslyPublishedNoteToUnpublish = state.allNotes.findIndex(
          (note) => note.id === draftOf
        )
        if (previouslyPublishedNoteToUnpublish === -1) return

        state.allNotes[previouslyPublishedNoteToUnpublish].published = false
      }
    },
    // action.payload = { noteId, note }
    updateLatestNotes: (state, action: PayloadAction<NoteToUpdate>) => {
      if (!state.latestNotes) return

      const noteToUpdate = state.latestNotes.findIndex(
        (note) => note.id === action.payload.noteId
      )

      state.latestNotes[noteToUpdate] = {
        ...state.latestNotes[noteToUpdate],
        ...action.payload.note,
        ...(action.payload.note.draftOf === null && {
          draftOf: "",
        }),
      }

      // If we're publishing the draft, then set draftOf to an empty string.
      if (action.payload.note.published) {
        state.latestNotes[noteToUpdate].draftOf = ""
      }

      // We don't need to set the old published note to unpublished the way that
      // updateAllNotes above does because that note will actually get removed
      // from latest notes entirely.
    },
    // action.payload = { note } (should contain only changed fields)
    addNewNote: (state, action: PayloadAction<GetNoteResp>) => {
      if (!state.allNotes) state.allNotes = []
      if (!state.latestNotes) state.latestNotes = []

      let newNote: GetNoteResp = {
        id: action.payload.id,
        title: "",
        json: "",
        text: "",
        categories: [],
        isAfterGateTruncated: false,
        isPostGateTruncated: false,
        authors: action.payload.authors,
        authorDetails: action.payload.authorDetails,
        isUnlisted: false,
        userId: "",
        blogId: "",
      }

      // If this is a draft, then it will already be coming with some data,
      // so merge that in.
      if (action.payload.draftOf) {
        const previouslyPublishedNote = state.allNotes.findIndex(
          (note) => note.id === action.payload.draftOf
        )

        newNote = { ...newNote, ...state.allNotes[previouslyPublishedNote] }

        // And then merge in whatever changed with the draft.
        newNote = { ...newNote, ...action.payload }
      } else {
        // Only set the createdAt field if this is a new (non-draft!) note.
        // Draft notes will have the createdAt field that is on the original note.
        newNote.createdAt = Date.now()
      }

      // And then set the updatedAt and createdAt fields.
      newNote.updatedAt = Date.now()

      // Add to all notes.
      state.allNotes.unshift(newNote)

      // And add to latest notes.
      state.latestNotes.unshift(newNote)

      // If this is a draft, let's also remove the previous published note from the latest notes.

      // Although the API is pre-sorting and filtering latest notes to not display duplicates
      // (so that each group of notes just has one latest note), in some situations such as
      // when creating a new draft of an existing note, the local data store may show a duplicate
      // until the page is refreshed and latest notes from the back-end are fetched.
      // In that scenario, we want to also apply filtering here by adding a new draft and removing
      // the old published note from the list of latest notes.
      if (action.payload.draftOf) {
        const noteToDelete = state.latestNotes.findIndex(
          (note) => note.id == action.payload.draftOf
        )
        state.latestNotes.splice(noteToDelete, 1)
      }

      console.log(
        "Added new note. The new all notes are: ",
        state.allNotes,
        " and latest notes are: ",
        state.latestNotes
      )
    },
    setPostMetrics: (state, action: PayloadAction<PostMetricsResp[]>) => {
      state.postMetrics = action.payload
    },
    setRevenueMetrics: (
      state,
      action: PayloadAction<RevenueMetricDisplaySummary | null>
    ) => {
      state.revenueMetrics = action.payload
    },
    // Run a refresh on local metrics to make sure that any new posts (drafts, published posts, etc.)
    // that have related posts with metrics are also showing these metrics.
    reparentLocalPostMetricsWithinNotes: (state) => {
      const metrics = state.postMetrics

      // Set metrics on each note record. Use both the noteId and draftOf ID to set these.
      // To ensure we're able to see metrics for previously published notes on new drafts.
      const latestNotes = state.latestNotes
      if (!metrics || !latestNotes) return

      for (const note of latestNotes) {
        const metricIdx = metrics.findIndex(
          (m) => m.postId == note.id || m.postId == note.draftOf
        )

        note.metrics = metrics[metricIdx]
      }

      state.latestNotes = latestNotes
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      /*
      console.log("NOTE STATE HYDRATION")
      console.log("NOTE PAYLOAD: ")
      console.log(action.payload)
      console.log("NOTE STATE: ")
      console.log({ ...state })
      */

      //if (!state.initialLoad) return state

      /*
      console.log("NOTE PAYLOAD: ")
      console.log(action.payload)
      console.log("NOTE STATE: ")
      console.log({ ...state })
      */

      /*
      const nextState = {}
      nextState = {
  
      const nextState = { ...action.payload }
      nextState.notes = { ...state }
  
      console.log("NEXT STATE:")
      console.log(nextState)
      */

      /*
     const nextState = action.payload
     nextState.notes = { ...state }
     */

      /*
      const nextState = {
        notes: {
          ...state,
        },
        ...action.payload,
      }
      */
      const nextState = {
        ...state,
        ...action.payload.notes,
      }

      //console.log("NEXT STATE:")
      //console.log(nextState)
      //nextState.initialLoad = false
      return nextState
    },
  },
})

// Publicly accessible, within components.
export const {
  insertIntoAllNotes,
  insertIntoLatestNotes,
  setIsSaving,
  setCurrentNoteId,
  setAllNotes,
  setPinnedPosts,
  reparentLocalPostMetricsWithinNotes,
} = notesSlice.actions

// Privately accessible, only within Thunks in this file.
const {
  removeCategory,
  setLatestNotes,
  updateAllNotes,
  updateLatestNotes,
  addCategory,
  addNewNote,
  deleteNote,
  setPaginationNoteSize,
  setPostMetrics,
  setRevenueMetrics,
} = notesSlice.actions

export const addCategoryToNote =
  (category: string): AppThunk<void> =>
  async (dispatch, getState) => {
    const note = selectLatestCurrentNote(getState())
    if (!note) return
    dispatch(addCategory({ category, note }))
    const updatedNote = selectLatestCurrentNote(getState())
    if (!updatedNote) return
    dispatch(
      updateAndSaveNote({
        note: { ...updatedNote, updatedAt: Date.now() },
        calledBy: "addCategoryToNote",
      })
    )
  }

export const removeCategoryFromNote =
  (category: string): AppThunk<void> =>
  async (dispatch, getState) => {
    const note = selectLatestCurrentNote(getState())
    if (!note) return
    dispatch(removeCategory({ category, note }))

    // This won't find anything when deleting a category from a filtered category page,
    // because `selectLatestCurrentNote()` filters by selected category, and with that
    // note no longer having one, this pulls up nothing, so then it doesn't get updated
    // in the server. Therefore, we'll pass in `false` so we don't use the filter.
    const updatedNote = selectLatestCurrentNote(getState(), false)
    if (!updatedNote) return
    dispatch(
      updateAndSaveNote({
        note: { ...updatedNote, updatedAt: Date.now() },
        calledBy: "removeCategoryFromNote",
      })
    )
  }

export const toggleArchiveAndSaveNote =
  ({
    id,
    note,
  }: {
    id: string
    note: Partial<GetNoteResp>
  }): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    console.log("Toggling archive and saving note...", note)

    if (note.archived) {
      console.log("The note is being archived, so updating to proper value...")

      note.published = false
      note.draftOf = null

      dispatch(updateCurrentNoteWhenDeleting())
    }
    dispatch(
      updateAndSaveNote({
        noteId: id,
        note,
        calledBy: "toggleArchiveAndSaveNote",
      })
    )
  }

/**
 * note = { text, title }
 * Only supply the changed fields.
 * The one exception to this rule is when we're dealing with scheduled publishes. In that case, even though the `scheduledAt` field
 * would have been set in a previous update, the back-end is relying on us passing that through at the same time as any publish
 * options such as { published: true } to identify that this is a scheduled publish.
 */
export const updateAndSaveNote =
  ({
    noteId,
    note,
    calledBy,
    save = true,
  }: {
    noteId?: string | null
    note: Partial<GetNoteResp>
    calledBy: string
    save?: boolean
  }): AppThunk<Promise<ErrorResponse | SuccessResponse>> =>
  async (dispatch, getState) => {
    console.log(`Started updating and saving note from ${calledBy}.`)

    noteId = noteId || note.id || selectCurrentNoteId(getState())

    if (!noteId) {
      console.log("Unable to updateAndSaveNote since no note ID", note)
      return {
        success: false,
        msg: "Unable to updateAndSaveNote since no note ID",
      }
    }

    // If updatedAt is coming as part of the payload, then keep that information.
    if (!note.updatedAt) {
      // Otherwise, change it to now.
      note.updatedAt = Date.now()
    }

    console.log("Updating note with body: ", note, noteId)

    // Update the note in both the allNotes and latestNotes data stores.
    dispatch(updateAllNotes({ noteId, note }))
    dispatch(updateLatestNotes({ noteId, note }))

    if (save) {
      dispatch(setIsSaving(true))

      const successOrError = await saveNote(noteId, note)

      console.log("Success or error saving post:", successOrError)

      if (isErrorResponse(successOrError)) {
        dispatch(makeToast("error", successOrError.msg, "Error publishing"))
        dispatch(setError(successOrError))
      } else dispatch(setError({ msg: "" }))

      dispatch(setIsSaving(false))
      dispatch(reparentLocalPostMetricsWithinNotes())
      return successOrError.data
    } else {
      return { success: true }
    }
  }

// When a user archives or deletes a note, we need to update the currentNoteId
// so the UI can reflect the change.
export const updateCurrentNoteWhenDeleting =
  (): AppThunk => (dispatch, getState) => {
    const id = selectCurrentNoteId(getState())
    const filteredNotes = selectFilteredNotes(getState())
    const filteredNoteIdx =
      filteredNotes?.findIndex((note) => note.id === id) || 0
    const prevFilteredNote = filteredNotes?.[filteredNoteIdx - 1]
    dispatch(setCurrentNoteId(prevFilteredNote?.id || ""))
  }

// When a user archives or deletes a note, we need to update the currentNoteId
// so the UI can reflect the change - we want to display the original published
// post again.
export const updateCurrentNoteWhenDeletingDraft =
  (): AppThunk => (dispatch, getState) => {
    const id = selectCurrentNoteId(getState())

    // Get the latest notes, which will include the draft.
    const latestFilteredNotes = selectLatestFilteredNotes(getState())

    // Find the draft among them.
    const draftIdx =
      latestFilteredNotes?.findIndex((note) => note.id === id) || 0
    if (!latestFilteredNotes) return
    const draft = latestFilteredNotes[draftIdx]

    // Get all notes, including the previously published note.
    const allFilteredNotes = selectFilteredNotes(getState())

    if (!allFilteredNotes) return

    // Find the previously published note among them.
    const originalPublishedNoteIdx = allFilteredNotes.findIndex(
      (note) => note.id === draft.draftOf
    )
    if (originalPublishedNoteIdx === -1) return

    const originalPublishedNote = allFilteredNotes[originalPublishedNoteIdx]

    // Finally, add this original published note into the latestNotes collection.
    dispatch(insertIntoLatestNotes(originalPublishedNote))

    // Set the previously published note as the new current note.
    dispatch(setCurrentNoteId(originalPublishedNote.id || ""))
  }

export const deleteAndSaveNote =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateCurrentNoteWhenDeleting())
    dispatch(deleteNote(id || ""))
    deleteNoteApi(id || "")
  }

export const deleteDraftNote = (): AppThunk => async (dispatch, getState) => {
  const id = selectCurrentNoteId(getState())
  dispatch(updateCurrentNoteWhenDeletingDraft())
  dispatch(deleteNote(id || ""))
  deleteNoteApi(id || "")
}

export const deleteAllNotes =
  (): Thunk<Promise<ErrorResponse | SuccessResponse>> => async (dispatch) => {
    // Delete all server notes first to run authorization checks.
    const successOrError = await deleteAllNotesApi()

    if (!successOrError.success) {
      return successOrError
    }

    // Now delete all local notes.
    dispatch(setAllNotes([]))

    return successOrError
  }

// This is for the logged-in user ONLY.
// If blogId is present, it's fetching notes for a blog that the user has access to
// but does NOT own.
export const retrieveAllNotes = (): AppThunk => async (dispatch) => {
  const data = await getAllNotes()
  dispatch(setAllNotes(data))

  analytics.identify(analytics.user("userId"), {
    totalPosts: data?.length || 0,
  })
}

// This is for the logged-in user ONLY.
// If blogId is present, it's fetching notes for a blog that the user has access to
// but does NOT own.
export const retrieveLatestNotes = (): AppThunk => async (dispatch) => {
  const data = await getLatestNotes()
  dispatch(setLatestNotes(data))
  dispatch(reparentLocalPostMetricsWithinNotes())

  analytics.identify(analytics.user("userId"), {
    totalPosts: data?.length || 0,
  })
}

/**
 * Retrieves just minimal preview fields for notes and sets them in the `latestNotes` array.
 * That's fields like "id", "draftOf", "updatedAt", "title", "subtitle", and "post_preview", and others.
 * Basically most fields with useful metadata we'd want to display in the sidebar, but not including
 * the post content itself like the JSON and innerHtml.
 * This is for the logged-in user ONLY.
 * If blogId is present, it's fetching notes for a blog that the user has access to
 * but does NOT own.
 */
export const retrieveLatestNotePreviews =
  (): AppThunk<Promise<GetNotePreviewResp[]>> => async (dispatch) => {
    const data = await getLatestNotePreviews()
    dispatch(setLatestNotes(data))
    dispatch(reparentLocalPostMetricsWithinNotes())

    analytics.identify(analytics.user("userId"), {
      totalPosts: data?.length || 0,
    })

    // TODO: This should vary based on order by preference.
    // For better UX, let's also fetch the full versions of the latest 5 notes.
    filterNotes({ allNotes: data, searchText: "" })
      .slice(0, 5)
      .map((note) => {
        if (note.json !== undefined) return // Skip notes we've already loaded.

        dispatch(retrieveLatestFullNoteById(note.id))
      })

    return data
  }

/**
 * This is for the logged-in user ONLY.
 * Retrieves the latest full note (including latest unpublished drafts) and updates the preview in the `latestNotes` array.
 * @param noteId The ID of the note to retrieve.
 */
export const retrieveLatestFullNoteById =
  (noteId: string): AppThunk =>
  async (dispatch) => {
    const data = await getNote(noteId)
    // Update the note in both the allNotes and latestNotes data stores.
    // In the latestNote store we 'update' the note from just the Preview Resp to the
    // Full Resp.
    dispatch(updateLatestNotes({ noteId, note: data }))
    // In the allNotes store we need to insert it, since only full notes are present here.
    dispatch(insertIntoAllNotes(data))
  }

// pageNum starts at 1
export const retrievePublishedNotes =
  (
    blogName: string,
    pageNum: number,
    category?: string,
    communityName?: string
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const data = await getPublishedNotes(
      blogName,
      pageNum,
      category,
      communityName
    )
    dispatch(setAllNotes(data?.notes))
    dispatch(setPaginationNoteSize(data?.size || 0))
    dispatch(setPinnedPosts(data?.pinnedPosts || []))
  }

// This is for PUBLIC note only.
export const retrieveNote =
  (
    blogName: string,
    noteId: string,
    preview?: boolean
  ): AppThunk<Promise<GetNoteResp | undefined>> =>
  async (dispatch: AppDispatch) => {
    const data = await getPublishedNote(blogName, noteId, preview)
    if (data) {
      dispatch(setAllNotes([data]))
    }

    console.log(`Note with ID ${noteId} retrieved`, data)

    return data
  }

export const refreshUsersNote =
  (noteId: string): AppThunk<Promise<GetNoteResp | undefined>> =>
  async (dispatch: AppDispatch) => {
    const data = await getNote(noteId)
    if (data) {
      dispatch(insertIntoLatestNotes(data))
    }
    return data
  }

export const createNewNote =
  (): AppThunk<Promise<string | null>> => async (dispatch) => {
    console.log(`Started creating new note.`)

    dispatch(setIsSaving(true))
    const newNoteFromServer = await newNote()

    if (!newNoteFromServer) return null

    if (isErrorResponse(newNoteFromServer)) {
      console.error("Error creating new note: ", newNoteFromServer)
      dispatch(makeToast("error", newNoteFromServer.msg, "Error editing"))
      return null
    }

    dispatch(setCurrentNoteId(newNoteFromServer.id))
    dispatch(addNewNote(newNoteFromServer))
    dispatch(setIsSaving(false))
    return newNoteFromServer.id
  }

export const createNewDraftNote =
  (
    calledBy: string,
    draftOf?: Partial<GetNoteResp>
  ): AppThunk<Promise<string | null>> =>
  async (dispatch) => {
    console.log(`Started creating new draft note from ${calledBy}.`)

    dispatch(setIsSaving(true))

    const newNoteFromServer = await newNote(draftOf)

    if (!newNoteFromServer) return null

    if (isErrorResponse(newNoteFromServer)) {
      console.error("Error creating new draft note: ", newNoteFromServer)
      dispatch(makeToast("error", newNoteFromServer.msg, "Error editing"))
      return null
    }

    dispatch(setCurrentNoteId(newNoteFromServer.id))
    dispatch(addNewNote(newNoteFromServer))

    // Update gating rules to reparent them to new note.
    console.log("Reparenting gatingRules: Start (PRE)!", draftOf)
    if (draftOf && draftOf.draftOf) {
      console.log("Reparenting gatingRules: Start!")

      const data = await dispatch(getPostGatingRuleGroups(newNoteFromServer.id))

      if (data) {
        // Then update the local state.
        dispatch(addPostGatingRuleGroups(data))
      }
    }

    dispatch(setIsSaving(false))

    dispatch(reparentLocalPostMetricsWithinNotes())

    return newNoteFromServer.id
  }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIsSaving = (state: RootState) => state.notes.saving
export const selectAllNotes = (state: RootState): Array<GetNoteResp> | null =>
  state.notes.allNotes
export const selectLatestNotes = (
  state: RootState
): Array<GetNoteRespFullOrPreview> | null => state.notes.latestNotes
export const selectPaginationNoteSize = (state: RootState): number =>
  state.notes.paginationNoteSize
export const selectPinnedPosts = (
  state: RootState
): Array<GetNoteResp> | null => state.notes.pinnedPosts

export const selectCurrentNoteId = (
  state: RootState
): string | undefined | null => {
  /*
  if (!state.notes.currentNoteId) {
    const currentNote = selectCurrentNote(state)
    return currentNote?.id
  }
  */

  return state.notes.currentNoteId
}

// undefined = still loading. Null = no note.
export const selectLatestCurrentNote = (
  state: RootState,
  useFilter = true // By default, we want to use the filter, but optionally we can not use it.
): GetNoteRespFullOrPreview | undefined | null => {
  let filteredNotes: Array<GetNoteRespFullOrPreview> | undefined | null

  if (useFilter) {
    filteredNotes = selectLatestFilteredNotes(state)
  } else {
    filteredNotes = selectLatestNotes(state)
  }

  if (!filteredNotes) return filteredNotes // If filteredNotes is undefined, it's not done loading yet
  // so return undefined here too. If it's null, return null here.

  // If filteredNotes length is zero, there's no notes in the current view.
  if (filteredNotes.length === 0) return null

  // If there's no currentNoteId, the user hasn't selected a note yet, so
  // return the first note
  //if (!state.notes.currentNoteId) return filteredNotes[0]

  if (!state.notes.currentNoteId) return null

  const currentNote = filteredNotes.find(
    (note) =>
      note.id === state.notes.currentNoteId ||
      note.slug === state.notes.currentNoteId
  )

  const actualCurrentNote = currentNote || filteredNotes[0]

  return actualCurrentNote
}

export const selectAllCategories = createSelector(
  selectAllNotes,
  (allNotes) => {
    const categories = new Set<string>()
    allNotes?.forEach((note) => {
      if (!note.categories) return
      note.categories.forEach((category) => categories.add(category))
    })
    return categories
  }
)

export const selectLatestCategories = createSelector(
  selectLatestNotes,
  (latestNotes) => {
    const categories = new Set<string>()
    latestNotes?.forEach((note) => {
      if (!note.categories || note.archived) return
      note.categories.forEach((category) => categories.add(category))
    })
    return categories
  }
)

export const filterNotes = ({
  allNotes,
  searchText,
}: {
  allNotes: Array<GetNoteRespFullOrPreview>
  searchText: string
}) => {
  allNotes = allNotes.slice()

  // First, sort all notes by date.
  allNotes = filter.sortByDate(allNotes, "updatedAt")

  if (searchText.includes("archived:yes")) {
    searchText = searchText.replace("archived:yes", "")
    allNotes = allNotes.filter((note) => note.archived)
  } else {
    allNotes = allNotes.filter((note) => !note.archived)
  }

  if (searchText.includes("scheduled:yes")) {
    searchText = searchText.replace("scheduled:yes", "")
    allNotes = allNotes.filter((note) => note.scheduledAt)
  }

  if (searchText.includes("published:yes")) {
    searchText = searchText.replace("published:yes", "")
    allNotes = allNotes.filter((note) => note.published || note.draftOf)
  }

  if (searchText.includes("published:no")) {
    searchText = searchText.replace("published:no", "")
    allNotes = allNotes.filter((note) => !note.published && !note.draftOf)
  }

  // TODO: Don't just check if this starts with category. Check if it's present at all
  // in the string. Then, search the category.
  if (searchText.toLowerCase().startsWith("category:")) {
    searchText = searchText.toLowerCase().split("category:")[1]

    return allNotes.filter((note) => {
      if (!note || !note.categories) return false

      for (const category of note.categories) {
        if (category.toLowerCase().includes(searchText.toLowerCase()))
          return true
      }
      return false
    })
  }

  const notes = allNotes.filter((note) => {
    // If no search text being used, display all notes.
    if (!searchText) return true
    if (!note) return false

    let foundInTitle = false
    let foundInSubtitle = false
    let foundInPostPreview = false

    if (note.title) {
      foundInTitle = note.title.toLowerCase().includes(searchText.toLowerCase())
    }

    if (note.subtitle) {
      foundInSubtitle = note.subtitle
        .toLowerCase()
        .includes(searchText.toLowerCase())
    }

    if (note.post_preview) {
      foundInPostPreview = note.post_preview
        .toLowerCase()
        .includes(searchText.toLowerCase())
    }

    return foundInTitle || foundInSubtitle || foundInPostPreview
  })

  // Noisy log. Only use when debugging.
  // console.log(`filterNotes found ${notes.length} notes for search term ${originalSearchText} out of the following notes`, allNotes)

  return notes
}

export const selectFilteredNotes = createSelector(
  selectAllNotes,
  selectSearchText,
  (allNotes, searchText) => {
    console.log("Selecting filtered notes...", allNotes, searchText)
    // console.log("Selecting all filtered notes!")

    // TODO: Need to handle undefined (loading) and null (empty notes) properly.
    if (!allNotes || !Array.isArray(allNotes)) return

    return filterNotes({
      allNotes,
      searchText,
    })
  }
)

export const selectCurrentNote = createSelector(
  selectFilteredNotes,
  selectCurrentNoteId,
  (filteredNotes, currentNoteId) => {
    //console.log("Selecting current note...", filteredNotes, currentNoteId)
    if (filteredNotes === undefined) return undefined // If filteredNotes is undefined, it's not done loading yet
    // so return undefined here too.

    // If filteredNotes length is zero, there's no notes in the current view.
    if (filteredNotes.length === 0) return null

    // If there's no currentNoteId, the user hasn't selected a note yet, so
    // return the first note
    //if (!state.notes.currentNoteId) return filteredNotes[0]

    if (!currentNoteId) return null

    const currentNote = filteredNotes.find(
      (note) => note.id === currentNoteId || note.slug === currentNoteId
    )

    const actualCurrentNote = currentNote || filteredNotes[0]

    console.log(
      "New current note selected",
      actualCurrentNote?.id
      // actualCurrentNote, // Noisy!
      // filteredNotes // Noisy!
    )

    return actualCurrentNote
  }
)

export const selectLatestFilteredNotes = (
  state: RootState
): Array<GetNoteRespFullOrPreview> | undefined => {
  // TODO: Need to handle undefined (loading) and null (empty notes) properly.
  if (!state.notes.latestNotes || !Array.isArray(state.notes.latestNotes))
    return

  // console.log(
  //   "About to selectLatestFilteredNotes. Latest notes is:",
  //   state.notes.latestNotes
  // )

  const filteredNotes = filterNotes({
    allNotes: state.notes.latestNotes,
    searchText: state.page.searchText,
  })

  return filteredNotes
}

export const selectAllDraftsForCurrentNote = (
  state: RootState
): undefined | Array<GetNoteRespFullOrPreview> => {
  // TODO: Need to handle undefined (loading) and null (empty notes) properly.
  if (!state.notes.allNotes || !Array.isArray(state.notes.allNotes)) return

  // Find all drafts for a given note ID.
  const currentNoteId = selectCurrentNoteId(state)

  return state.notes.allNotes.filter(
    (note) => note.draftOf == currentNoteId && note.draftOf
  )
}

export const selectLatestDraftForCurrentNote = (
  state: RootState
): undefined | GetNoteRespFullOrPreview => {
  // TODO: Need to handle undefined (loading) and null (empty notes) properly.
  if (!state.notes.allNotes || !Array.isArray(state.notes.allNotes)) return

  // Find all drafts for a given note ID.
  let drafts = selectAllDraftsForCurrentNote(state)

  if (!drafts) {
    return
  }

  drafts = filter.sortByDate(drafts, "updatedAt")

  return drafts[0]
}

export const selectLatestDraftForNotes = (
  state: RootState
): undefined | GetNoteRespFullOrPreview => {
  // TODO: Need to handle undefined (loading) and null (empty notes) properly.
  if (!state.notes.allNotes || !Array.isArray(state.notes.allNotes)) return

  // Find all drafts for a given note ID.
  let drafts = selectAllDraftsForCurrentNote(state)

  if (!drafts) {
    return
  }

  drafts = filter.sortByDate(drafts, "updatedAt")

  return drafts[0]
}

/**
 * Retrieve post metrics.
 * Possible optimization: To make this more performant, we can retrieve metrics for only the posts we have fully loaded.
 * Note: This is for the logged-in user ONLY.
 * @returns
 */
export const retrievePostMetrics = (): AppThunk => async (dispatch) => {
  const [postMetrics, revenueMetrics] = [
    await getPostMetricsForBlog(),
    // Although this seems unrelated to notes, in the future we're going to be storing note context around revenue so the same re-parenting will happen here too.
    await getRevenueMetricsForBlog(),
  ]

  dispatch(setPostMetrics(postMetrics))
  dispatch(reparentLocalPostMetricsWithinNotes())

  dispatch(setRevenueMetrics(revenueMetrics))
}

export const selectRevenueMetrics = (state: RootState) =>
  state.notes.revenueMetrics

export default notesSlice.reducer

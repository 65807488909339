// This is the URL of the API server corresponding to this deploy.
// This is also used in next.config.js
export const absoluteAPI =
  // Is this in development?
  process.env.NEXT_PUBLIC_VERCEL_ENV === "development"
    ? "http://localhost:3001/api"
    : // If we're on a preview branch, use the preview branch API URL.
      process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
      ? // If we're on production, use paragraph.xyz.
        //
        // We can't use the Vercel URL because it does NOT use the same cache
        // across deployments.
        "https://" + process.env.NEXT_PUBLIC_VERCEL_URL + "/api"
      : // If we're on production, use api.paragraph.xyz.
        //
        // We do NOT want to use paragraph.xyz/api, since that would
        // cause a massive amount of needless edge middleware requests
        // to occur.
        //
        "https://api.paragraph.xyz"

import { Node, nodePasteRule } from "@tiptap/core"
import { Editor, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react"
import { selectUsersBlog } from "features/blogSlice"
import { selectUser } from "features/userSlice"
import { useSelector } from "react-redux"

// https://regex101.com/r/KTkLUW/1
export const ZORA_REGEX =
  /^(https?:\/\/)?(www\.)?(zora\.co\/collect\/([^\/\?\n]*))\/?([\d]*)?.*$/
export const ZORA_REGEX_GLOBAL =
  /^(https?:\/\/)?(www\.)?(zora\.co\/collect\/([^\/\?\n]*))\/?([\d]*)?.*$/g

const chainIds: { [key: string]: number } = {
  eth: 1,
  gor: 5,
  oeth: 10,
  ogor: 420,
  pgn: 424,
  zgor: 999,
  zora: 7777777,
  basegor: 84531,
  base: 8453,
}

const extractEmbedID = (url: string) => {
  const match = url.match(ZORA_REGEX)
  console.log("Zora match: ", match)
  if (match && match[4]) {
    // match[4] is the collectionId, match[5] is the itemId
    if (match[5]) return `${match[4]}/${match[5]}`
    else return match[4]
  }
  return
}

export interface ZoraOptions {
  HTMLAttributes: Record<string, any>
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    zora: {
      setZoraEmbed: (options: { src: string }) => ReturnType
    }
  }
}

export default Node.create<ZoraOptions>({
  name: "zora",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      embedId: { default: null },
      referral: { default: null },
    }
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },

  addCommands() {
    return {
      setZoraEmbed:
        (options) =>
        ({ commands }) => {
          const embedId = extractEmbedID(options.src)
          if (!embedId) return false
          return commands.insertContent({
            type: this.name,
            attrs: { ...options, embedId },
          })
        },
    }
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: ZORA_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          if (!match.input) return false
          const embedId = extractEmbedID(match.input)
          if (!embedId) return false
          return { embedId }
        },
      }),
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { embedId, referral } = HTMLAttributes

    // chain:0x...
    const [chain, id] = embedId.split(":")
    const chainId = chainIds[chain as string]

    const html = `
    <div style="text-align:center;"><img src="https://zora.co/api/thumbnail/${chainId}/${id}" class="zora-embed" /></div>
    <div style="text-align: center; margin-top: 24px; margin-bottom: 24px;">
      <a  style="
    display: inline-block;
    border-radius: 4px;
    background-color: rgb(59, 130, 246);
    padding: 16px 24px;
    font-weight: 600;
    line-height: 100%;
    color: rgb(255, 255, 255);
    text-decoration: none;
    "
    href="https://zora.co/collect/${embedId}${
      referral ? `?referrer=${referral}` : ""
    }">
    Collect NFT
        </a>
      </div>`

    return ["div", { "data-type": this.name, embedId, referral, html }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ZoraEmbedComponent)
  },
})

type Props = {
  editor: Editor
  node: any
  updateAttributes: (attrs: any) => void
}

const ZoraEmbedComponent = ({
  editor,
  node,
  updateAttributes,
}: Props): JSX.Element => {
  const { embedId, referral } = node.attrs
  const blog = useSelector(selectUsersBlog)
  const user = useSelector(selectUser)

  console.log("RENDERING ZORA")

  let wallet = blog.collectibleWalletAddress
  if ("wallet_address" in user) wallet = user["wallet_address"]
  console.log("ZORA: Referral ", referral)
  if (editor && editor.isEditable && wallet && referral !== wallet) {
    console.log("ZORA: Setting referral to ", wallet)
    updateAttributes({ referral: wallet })
  }

  const html = `<div class="embed" style="position:relative;width:330px;height:398px;margin-left:auto;margin-right:auto">
      <iframe src="https://zora.co/collect/${embedId}/embed${
        referral ? `?referrer=${referral}` : ""
      }" style="border:0;background-color:transparent;position:absolute;inset:0" width="100%" height="100%"></iframe>
    </div>
  `

  return (
    <NodeViewWrapper className="react-component my-5" data-drag-handle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </NodeViewWrapper>
  )
}

import { useState, Dispatch, SetStateAction } from "react"

import { CommunityEntry, Subscription } from "@types"
import OptOutsCombobox from "components/subscribers/OptOutsCombobox"
import { NonCommunityEmailsOptOut } from "@/util/constants"
import Button from "components/Button"
import { ModalState } from "./Modal"
import {
  addSubscriptionOptOut,
  removeSubscriptionOptOut,
} from "features/blogSlice"
import { useAppDispatch } from "store"

export default function InnerModalCommunityOptOuts({
  subscription,
  communities,
  setState,
  setOpen,
}: {
  subscription: Subscription | undefined
  communities: CommunityEntry[] | undefined
  setOpen: Dispatch<SetStateAction<boolean>>
  setState: Dispatch<SetStateAction<ModalState>>
}) {
  if (!subscription || !communities || communities.length === 0) return null

  return (
    <div>
      <SelectCommunitiesFromList
        subscription={subscription}
        communities={communities}
        setOpen={setOpen}
        setState={setState}
      />
    </div>
  )
}

function SelectCommunitiesFromList({
  subscription,
  communities,
  setOpen,
  setState,
}: {
  subscription: Subscription
  communities: CommunityEntry[]
  setOpen: Dispatch<SetStateAction<boolean>>
  setState: Dispatch<SetStateAction<ModalState>>
}) {
  const dispatch = useAppDispatch()
  const availableOptouts = [NonCommunityEmailsOptOut, ...communities]

  const [optOuts, setOptOuts] = useState({
    [subscription.id]: [NonCommunityEmailsOptOut, ...communities].filter(
      (optOut) => subscription.optOuts?.includes(optOut.id)
    ),
  })

  return (
    <fieldset className="mx-auto flex justify-center flex-col items-center">
      <div className="flex mt-10 pb-2 uppercase text-xs font-medium border-b-2"></div>
      <div>
        <OptOutsCombobox
          subscriptions={[subscription]}
          availableOptOuts={availableOptouts}
          initialOptOuts={optOuts}
          onOptOutAddedCallback={(subscriptionIds, community) => {
            // Update server state.
            dispatch(addSubscriptionOptOut(subscriptionIds[0], community.id))

            // Update local state to add opt out.
            const tempArr = [...optOuts[subscriptionIds[0]]] || []

            tempArr.push(community)

            setOptOuts({
              [subscriptionIds[0]]: tempArr,
            })
          }}
          onOptOutRemovedCallback={(subscriptionIds, community) => {
            // Update server state.
            dispatch(removeSubscriptionOptOut(subscriptionIds[0], community.id))

            // Update local state to remove opt out.
            const tempArr = [...optOuts[subscriptionIds[0]]] || []

            const index = tempArr.findIndex(
              (optOut) => optOut.id === community.id
            )
            if (index >= 0) {
              tempArr.splice(index, 1)
            }
            setOptOuts({
              [subscriptionIds[0]]: tempArr,
            })
          }}
          isAuthorView={false}
          setParentModalIsOpen={setOpen}
        />
      </div>
      <Button
        text="Continue"
        type="button"
        onClick={() => setState("SUCCESS")}
        className="mt-4 max-w-xs justify-self-center"
      />
    </fieldset>
  )
}

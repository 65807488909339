import { getHtml as _getHtml } from "util/tiptap"
import { extensions } from "@/components/Editor/extensions"

import type { Note } from "@types"

import removeMd from "remove-markdown"

export const getHtml = (content: string): undefined | string => {
  return _getHtml(content, extensions)
}

export const getText = (note?: Partial<Note> | null) => {
  if (!note) return undefined

  if (note.json) {
    // https://regex101.com/r/JtnMaQ/1
    // $2 is the text content of the tag
    return getHtml(note.json)?.replace(/(<[^>]+>)*([^<]*)(<[^>]+>)*/g, "$2")
  }

  if (note.text) {
    return removeMd(note.text)
  }

  return undefined
}

import { Collectible } from "@/types/collectibles"
import { User } from "@/types/users"
import useUser from "hooks/useUser"
import { useAccount } from "wagmi"

export function userCollectingHighlight(
  highlight?: Collectible,
  user?: User,
  connectedWallet?: string
) {
  if (!highlight || highlight?.status !== "PENDING") return false

  if (!user || !connectedWallet) return false

  const isLoggedinUserCollecting =
    "wallet_address" in user &&
    highlight?.collectorWallet?.toLowerCase() ===
      user.wallet_address.toLowerCase()

  const isWalletCollecting =
    connectedWallet?.toLowerCase() === highlight?.collectorWallet?.toLowerCase()

  if (isWalletCollecting && !isLoggedinUserCollecting) {
    console.warn(
      "User's wallet is connected, and they are collecting, but they are not logged in or their wallet address differs from their connected address."
    )
  }

  console.log(
    "isUserCollectingHighlight",
    isWalletCollecting,
    isLoggedinUserCollecting,
    highlight,
    user
  )

  return isWalletCollecting || isLoggedinUserCollecting
}

export default function useUserCollectingHighlight(highlight?: Collectible) {
  const user = useUser()
  const account = useAccount()

  return userCollectingHighlight(highlight, user, account.address)
}

"use client"

import React from "react"
import { SIWEProvider, SIWEConfig, ConnectKitProvider } from "connectkit"
import { loginUsersWallet, logoutUser } from "@/features/userSlice"
import { useAppDispatch } from "@/store"
import { generateNonce, verifyNonce, getWalletSession } from "@/api_routes/user"
import useGetInitialChain from "@/hooks/useGetInitialChain"
import { createSiweMessage, parseSiweMessage } from "viem/siwe"

// Assuming you have these utility functions

export default function ConnectKitSIWEProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const dispatch = useAppDispatch()

  const initialChainId = useGetInitialChain()

  const siweConfig: SIWEConfig = {
    getNonce: async () => {
      const nonce = await generateNonce()
      if (!nonce) {
        throw new Error("Unable to generate nonce")
      }
      return nonce
    },

    createMessage: ({ nonce, address, chainId }) => {
      return createSiweMessage({
        version: "1",
        domain: window.location.host,
        uri: window.location.origin,
        address,
        chainId,
        nonce,
        statement: "Sign in with Ethereum to the app.",
      })
    },

    verifyMessage: async ({ message, signature }) => {
      const address = parseSiweMessage(message).address

      if (!address) {
        console.error("Address not found in message")
        return false
      }
      const verified = await verifyNonce({
        wallet_address: address,
        signature,
        message,
      })

      // TODO: Check if error response
      if (verified) {
        await dispatch(loginUsersWallet(verified))
      }

      return true
    },

    getSession: async () => {
      const response = await getWalletSession()
      if (!response) return null
      return response
    },

    signOut: async () => {
      await dispatch(logoutUser())
      return true
    },

    // Optional configurations
    enabled: true,
    nonceRefetchInterval: 300000, // 5 minutes
    sessionRefetchInterval: 300000, // 5 minutes
    signOutOnDisconnect: false,
    signOutOnAccountChange: false,
    signOutOnNetworkChange: false,
  }

  return (
    <SIWEProvider {...siweConfig}>
      <ConnectKitProvider
        options={{
          initialChainId,
        }}
      >
        {children}
      </ConnectKitProvider>
    </SIWEProvider>
  )
}

import { SubscriptionOptOut_NonCommunityEmails_FakeCommunity } from "@types"
import { isMaster } from "./server"

/*
 * This is the default supply for highlights, if the
 * user has not set a custom supply on the blog or post level.
 */
export const HIGHLIGHTS_DEFAULT_SUPPLY = "0"

// *****------------------------------*****
// *****   COMMUNITY EMAIL OPT-OUTS   *****
// *****------------------------------*****

// Opt out of subscription to the blog's non-community restricted emails.
export const SubscriptionOptOut_NonCommunityEmails = "nonCommunityEmails"

export const NonCommunityEmailsOptOut: SubscriptionOptOut_NonCommunityEmails_FakeCommunity =
  {
    id: "nonCommunityEmails",
    name: "General emails",
    description: "Emails that aren't specific to any community.",
  }

// *****----------------------*****
// *****   REVENUE TRACKING   *****
// *****----------------------*****

/**
 * Different types for tracking revenue on amplitude.
 * Ideally this would have lived in the @types/track file but due to dependency import limitations
 * enums can't go into the types files, so it lives here.
 */
export enum RevenueType {
  CustomDomain = "custom domain",
  CollectibleHighlight = "collectible HIGHLIGHT",
  CollectiblePost = "collectible POST",
  TokenGate = "token gate",
  MembershipFee = "membership fee",
  PlatformRewardFee = "platform reward fee",
}

// *****----------------------*****
// *****   HTML PLACEHOLDER   *****
// *****----------------------*****

export const placeholderTruncatedPostStaticHTML = `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam purus quam, pellentesque eu interdum at, fringilla id mi. Integer semper risus eu ornare aliquam. Ut malesuada massa at nunc viverra congue. Pellentesque at urna luctus, dictum augue sit amet, vulputate sem. Aliquam turpis augue, euismod sed tincidunt non, sollicitudin quis enim. Maecenas feugiat dolor non quam egestas luctus. Ut tristique neque nec viverra dapibus. Nam accumsan vulputate risus, sit amet convallis libero sagittis ut. Sed id libero nisi.</p><p style="text-align: justify">Curabitur tristique laoreet sapien. Morbi at magna ipsum. Morbi scelerisque sollicitudin urna. Praesent tincidunt venenatis velit vulputate sodales. Proin at est nec leo vulputate gravida. Mauris fringilla sit amet risus et auctor. Sed condimentum dolor in dolor suscipit, sed pretium elit fringilla. Donec suscipit ligula nisi, quis imperdiet quam pulvinar ut. Vestibulum bibendum porttitor pellentesque. Nam varius et felis tristique bibendum.</p>`

// *****--------------------------*****
// *****   JSON FOR EMPTY POSTS   *****
// *****--------------------------*****

export const jsonEmptyString = `{"type":"doc","content":[{"type":"paragraph"}]}`
export const jsonEmptyString2 = `{"type":"doc","content":[{"type":"paragraph","attrs":{"textAlign":"left"}},{"type":"emptyState","attrs":null}]}`

// *****-------------------------*****
// *****   NOTE PREVIEW FIELDS   *****
// *****-------------------------*****

export const GetTrendingBlogFields = [
  "id",
  "logo_url",
  "name",
  "lowercase_url",
  "custom_domain",
  "updatedAt",
  "about",
  "summary",
  "disable_highlights",
  "collectibleWalletAddress",
] as const

export const GetNotePreviewRespFields = [
  "id",
  "draftOf",
  "createdAt",
  "updatedAt",
  "published",
  "publishedAt",
  "scheduledAt",
  "isScheduled",
  "manualPublishedAt",
  "archived",
  "slug",
  "title",
  "subtitle",
  "categories",
  "post_preview",
  "communityId",
  "isPostGateTruncated",
  "isAfterGateTruncated",
  "collectibleImgUrl",
  "defaultCollectibleImgUrl",
  "authors",
  "metrics", // This field gets populated on the front-end in a separate fetch.
  "isUnlisted",
  "cover_img",
  "collectiblesDisabled",
  "userId",
  "blogId",
] as const // Ensuring the array is a tuple of literals

export const GetNoteForYouFields = [
  "id",
  "draftOf",
  "createdAt",
  "updatedAt",
  "published",
  "publishedAt",
  "archived",
  "slug",
  "title",
  "subtitle",
  "categories",
  "post_preview",
  "communityId",
  "authors",
  "cover_img",
  "collectiblesDisabled",
  "collectibleImgUrl",
  "defaultCollectibleImgUrl",
  "userId",
  "blogId",
] as const // Ensuring the array is a tuple of literals

// https://docs.loopcrypto.xyz/supported-networks-and-tokens
// If on master, use production networks and if not, use dev network (Goerli)
export const loopSupportedNetworks = isMaster
  ? [
      {
        name: "Ethereum",
        id: 1,
        tokens: [
          {
            name: "USDC",
            address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          },
          {
            name: "GUSD",
            address: "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd",
          },
          {
            name: "WBTC",
            address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
          },
          {
            name: "WETH",
            address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          },
          {
            name: "DAI",
            address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
          },
          {
            name: "BUSD",
            address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
          },
          {
            name: "FRAX",
            address: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
          },
          {
            name: "ENS",
            address: "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
          },
          {
            name: "DYDX",
            address: "0x92D6C1e31e14520e676a687F0a93788B716BEff5",
          },
          {
            name: "UNI",
            address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
          },
        ],
      },
      {
        name: "Optimism",
        id: 10,
        tokens: [
          {
            name: "USDC",
            address: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
          },
          {
            name: "WETH",
            address: "0x4200000000000000000000000000000000000006",
          },
          {
            name: "DAI",
            address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
          },
          {
            name: "WBTC",
            address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
          },
        ],
      },
      {
        name: "Base",
        id: 8453,
        tokens: [
          {
            name: "USDC",
            address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
          },
          {
            name: "USDCbc",
            address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
          },
          {
            name: "DAI",
            address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
          },
          {
            name: "cbETH",
            address: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
          },
        ],
      },
      {
        name: "Polygon",
        id: 137,
        tokens: [
          {
            name: "USDC",
            address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
          },
          {
            name: "WBTC",
            address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
          },
          {
            name: "WETH",
            address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
          },
          {
            name: "DAI",
            address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
          },
          {
            name: "BUSD",
            address: "0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7",
          },
          {
            name: "FRAX",
            address: "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
          },
          {
            name: "UNI",
            address: "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
          },
          {
            name: "WMATIC",
            address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          },
        ],
      },
      {
        name: "Arbitrum",
        id: 42161,
        tokens: [
          {
            name: "USDC",
            address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
          },
          {
            name: "WETH",
            address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
          },
          {
            name: "WBTC",
            address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
          },
          {
            name: "DAI",
            address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
          },
        ],
      },
      {
        name: "Binance",
        id: 56,
        tokens: [
          {
            name: "USDC",
            address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
          },
          {
            name: "WETH",
            address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
          },
          {
            name: "DAI",
            address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
          },
          {
            name: "BUSD",
            address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
          },
          {
            name: "WBNB",
            address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          },
        ],
      },
    ]
  : [
      {
        name: "Goerli",
        id: 5,
        tokens: [
          {
            name: "USDC",
            address: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
          },
          {
            name: "WBTC",
            address: "0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05",
          },
          {
            name: "WETH",
            address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
          },
        ],
      },
    ]

export const loopSupportedTokens = ["USDC", "WETH", "WMATIC"] // "WBTC", "USDT"

export const RSS_FEED_SUFFIXES = [
  "feed",
  "rss",
  "feed/rss",
  "feed/atom",
  "feeds",
  "feeds/rss",
  "feeds/atom",
  "rss.xml",
  "atom.xml",
  "index.rss",
  "?feed=rss2",
  "?feed=atom",
]

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { InformationCircleIcon } from "@heroicons/react/24/solid"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { XCircleIcon } from "@heroicons/react/24/solid"
import { useEffect } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import clsx from "clsx"
import { ErrorType } from "@types"

interface BannerProps {
  type?: ErrorType
  className?: string
  replaceClassName?: string
  skipLogEvent?: boolean

  children?: React.ReactNode
  text?: string
}

function Icon({ type }: { type: ErrorType }) {
  switch (type) {
    case "info":
      return (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400 dark:text-blue-300"
          aria-hidden="true"
        />
      )
    case "success":
      return (
        <CheckCircleIcon
          className="h-5 w-5 text-green-400 dark:text-green-300"
          aria-hidden="true"
        />
      )
    case "warning":
      return (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-400 dark:text-yellow-300"
          aria-hidden="true"
        />
      )
    case "error":
      return (
        <XCircleIcon
          className="h-5 w-5 text-red-400 dark:text-red-300"
          aria-hidden="true"
        />
      )
  }
}

export default function Banner({
  text,
  type = "error",
  className,
  replaceClassName,
  skipLogEvent,
  children,
}: BannerProps) {
  const { track } = useAnalytics()

  useEffect(() => {
    if (!skipLogEvent) {
      track("banner shown", {
        text,
        type,
      })
    }
  }, [])

  return (
    <div
      className={clsx(
        type === "info" ? "bg-blue-50 dark:bg-blue-900" : "",
        type === "warning" ? "bg-yellow-50 dark:bg-yellow-900" : "",
        type === "success" ? "bg-green-50 dark:bg-green-900" : "",
        type === "error" ? "bg-red-50 dark:bg-red-900" : "",

        replaceClassName ? replaceClassName : "rounded-md p-4 " + className
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon type={type} />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          {text && (
            <p
              style={{ margin: 0 }}
              className={clsx(
                type === "info" ? "text-blue-700 dark:text-blue-100" : "",
                type === "warning"
                  ? "text-yellow-700 dark:text-yellow-100"
                  : "",
                type === "success" ? "text-green-700 dark:text-green-100" : "",
                type === "error" ? "text-red-700 dark:text-red-100" : "",

                "lg:my-0 text-sm"
              )}
            >
              {text}
            </p>
          )}

          {children && (
            <div
              style={{ margin: 0 }}
              className={clsx(
                type === "info" ? "text-blue-700 dark:text-blue-100" : "",
                type === "warning"
                  ? "text-yellow-700 dark:text-yellow-100"
                  : "",
                type === "success" ? "text-green-700 dark:text-green-100" : "",
                type === "error" ? "text-red-700 dark:text-red-100" : "",

                "lg:my-0 text-sm"
              )}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { selectPostGatingRuleGroupsForPostId } from "features/gatingRuleGroupsSlice"
import { useSelector } from "react-redux"
import { BalanceResp, Membership, Subscription } from "@types"
import { captureException } from "@sentry/nextjs"

import {
  EnrichedGatingRule,
  EnrichedGatingRuleGroup,
  GatingContentType,
  GatingRequirementMembership,
  GatingRequirementToken,
  GatingRule,
  GatingRuleGroup,
  Token,
} from "@/types/gatingRules"
import {
  selectCurrentTransactionHash,
  selectSubscription,
  selectUser,
} from "features/userSlice"
import { useEffect, useState } from "react"
import useIsUsersOwnBlog from "./useIsUsersOwnBlog"
import { selectTokens } from "features/blogSlice"
import useBalances from "./crypto/useBalances"
import { selectUpsellMemberships } from "features/membershipSlice"
import { isSubscriptionToMembershipExpired } from "@/util/memberships"

// Custom hook that makes it easier to check whether the current user has access to a given post ID.
// Checks whether the post has any gating rules, and if so, whether the user satisfies the gating requirements.
// Returns an array of EnrichedGatingRules which contain information about whether the user has access,
// and if not, why.
// Returns an empty array if there are no gating rules.
// Returns undefined if it has not yet been able to calculate gating rules (if they are still loading).
export default function useGatingRulesForReader(
  postId: string, // ID of the post for which we're fetching gating rules.
  gateContentType: GatingContentType | null, // Type of gate rules we are interested in.
  embedId: string | null, // Specific gate we are interested in.
  requestCameFrom: string // Purely for logging purposes.
): Array<EnrichedGatingRuleGroup> | undefined {
  const gatingRuleGroups = useSelector(
    selectPostGatingRuleGroupsForPostId(postId, gateContentType, embedId)
  )

  console.log(
    `AFTER GATE useGatingRulesForReader:\npostId: ${postId}\ngateContentType: ${gateContentType}\nembedId: ${embedId}\ngatingRuleGroups: ${gatingRuleGroups}`
  )

  const subscription = useSelector(selectSubscription)
  const isUsersOwnBlog = useIsUsersOwnBlog()
  const [gatingRuleGroupsAccessDecisions, setGatingRuleGroupsAccessDecisions] =
    useState<Array<EnrichedGatingRuleGroup> | undefined>()
  const memberships = useSelector(selectUpsellMemberships)
  const tokens = useSelector(selectTokens)
  const user = useSelector(selectUser)
  const currentTransactionHash = useSelector(selectCurrentTransactionHash)
  const [tokensToFetchBalancesFor, setTokensToFetchBalancesFor] = useState<
    Token[]
  >([])
  const balances = useBalances(tokensToFetchBalancesFor, requestCameFrom)

  useEffect(() => {
    console.log(
      "GatingRules: - useGatingRulesForReader - Transaction hash was changed! It's now ",
      currentTransactionHash
    )
  }, [currentTransactionHash])

  // Every time the gating rule groups change, refresh the tokens to fetch balances for.
  useEffect(() => {
    const newTokensToFetchBalancesFor: Token[] = []

    for (const group of gatingRuleGroups || []) {
      // If it's the author looking at their own post, then of course they have access.
      if (isUsersOwnBlog) {
        break
      }

      for (const gr of group.gatingRules) {
        if (gr.gatingRequirement.gateType != "TOKEN") continue

        const tokenOfInterest = getTokenById(gr, tokens)
        if (tokenOfInterest) {
          newTokensToFetchBalancesFor.push(tokenOfInterest)
        }
      }
    }

    setTokensToFetchBalancesFor(newTokensToFetchBalancesFor)
  }, [JSON.stringify(gatingRuleGroups)])

  useEffect(() => {
    const determineAccess = async () => {
      console.log(
        `GatingRules: - useGatingRulesForReader - ${
          gatingRuleGroups?.length || 0
        } Gating rules retrieved in useGatingRulesForReader hook for post with ID ${postId}. Request came from ${requestCameFrom}`,
        gatingRuleGroups
      )

      const decisionArr = new Array<EnrichedGatingRuleGroup>()

      if (!gatingRuleGroups || gatingRuleGroups.length == 0) {
        console.log(
          "GatingRules: - useGatingRulesForReader - No gating rules detected!"
        )
        setGatingRuleGroupsAccessDecisions(decisionArr)

        return
      }

      for (const group of gatingRuleGroups) {
        // If it's the author looking at their own post, then of course they have access.
        if (isUsersOwnBlog) {
          decisionArr.push(makeHasAccessGatingRuleGroup(group))
          console.log(
            `GatingRules: - useGatingRulesForReader - User was viewing their own blog. Access granted! Request came from ${requestCameFrom}`
          )
        } else {
          // Otherwise, if it's the reader, let's check whether they have access.
          console.log("GatingRules: Balances were", balances)

          decisionArr.push(
            checkGatingRuleGroupAccess(
              group,
              subscription,
              memberships || [],
              tokens || [],
              balances
            )
          )
        }
      }

      setGatingRuleGroupsAccessDecisions(decisionArr)
    }

    console.log("GatingRules: Something changed", {
      requestCameFrom,
      userId: user?.id,
      isUsersOwnBlog,
      postId,
      subscription,
      currentTransactionHash,
      gatingRuleGroupDecisions: JSON.stringify(gatingRuleGroupsAccessDecisions),
    })
    determineAccess()
    // Reload the gating rules decision when:
    // 1. Navigating to other posts,
    // 2. Subscribing/unsubscribing,
    // 3. Purchasing a token.
  }, [
    user?.id,
    isUsersOwnBlog,
    postId,
    JSON.stringify(subscription),
    currentTransactionHash,
    balances,
    tokens,
    JSON.stringify(gatingRuleGroups),
  ])

  return gatingRuleGroupsAccessDecisions
}

// Logic for determining whether a user satisfies a gating rule group or not.
// Returns true if user has access, false if not.
function checkGatingRuleGroupAccess(
  group: GatingRuleGroup,
  sub: Subscription | false | undefined,
  memberships: Membership[],
  tokens: Token[],
  tokenBalances: BalanceResp[]
): EnrichedGatingRuleGroup {
  // If the gating rule group has expired, ignore it.
  if (group.expiresOn && group.expiresOn < Date.now()) {
    console.log("GatingRules: Expired gating rule group detected!")
    return makeHasAccessGatingRuleGroup(group)
  }

  const gatingRules: EnrichedGatingRule[] = []

  for (const gr of group.gatingRules) {
    gatingRules.push(
      checkGatingRuleAccess(group, gr, sub, memberships, tokens, tokenBalances)
    )
  }

  return {
    ...group,
    hasAccess: gatingRules.every((g) => g.hasAccess === true), // Group as a whole grants access if every constituent gating rule grants access.
    enrichedGatingRules: gatingRules,
  }
}

// Logic for determining whether a user satisfies a gating rule or not.
// Returns true if user has access, false if not.
function checkGatingRuleAccess(
  group: GatingRuleGroup,
  gr: GatingRule,
  sub: Subscription | false | undefined,
  memberships: Membership[],
  tokens: Token[],
  tokenBalances: BalanceResp[]
): EnrichedGatingRule {
  switch (gr.gatingRequirement.gateType) {
    case "TOKEN":
      const token = getTokenById(gr, tokens)

      const balances = tokenBalances.filter((t) => {
        // If this is an ERC1155 token, then let's make sure to filter by the token onChainId too.
        if (token && token.onChainData && "onChainId" in token?.onChainData) {
          return (
            t.contractAddress == token?.onChainData.contractAddress &&
            t.onChainId == token.onChainData.onChainId
          )
        } else {
          // Otherwise we can just check the contract address matches.
          return t.contractAddress == token?.onChainData.contractAddress
        }
      })

      return checkTokenGatingRuleAccess(
        group,
        gr,
        token,
        balances.length > 0 ? Number(balances[0].balance) : 0
      )
    case "MEMBERSHIP":
      const requiredMembership = getRequiredMembershipById(gr, memberships)

      return checkMembershipGatingRuleAccess(group, gr, sub, requiredMembership)
  }
}

function checkTokenGatingRuleAccess(
  group: GatingRuleGroup,
  gr: GatingRule,
  token: Token | undefined,
  tokenBalance: number | undefined
): EnrichedGatingRule {
  console.log("GatingRules: Token gate detected!", { token, tokenBalance })

  if (
    tokenBalance &&
    tokenBalance >=
      (gr.gatingRequirement as GatingRequirementToken).tokenMinCount
  ) {
    console.log(
      `GatingRules: User had a sufficient balance of token with ID ${token?.onChainData.contractAddress} and name ${token?.onChainData.name}. ` +
        `Balance was ${tokenBalance} and minCount was ${
          (gr.gatingRequirement as GatingRequirementToken).tokenMinCount
        }`
    )

    return makeHasAccessGatingRule(gr, token)
  }

  // User had no access.
  console.log(
    `GatingRules: User had an insufficient balance of token with ID ${token?.onChainData.contractAddress} and name ${token?.onChainData.name}. ` +
      `Balance was ${tokenBalance} and minCount was ${
        (gr.gatingRequirement as GatingRequirementToken).tokenMinCount
      }`
  )

  return {
    ...gr,
    hasAccess: false,
    noAccessMessage: "You're missing a token.",
    gatingRuleRequirementTypeDisplayTitle:
      populateGatingRuleRequirementTypeDisplayTitle(gr),
    gatingRuleRequirementTypeDisplayMessage:
      populateGatingRuleRequirementTypeDisplayMessage(gr, false, token),
    gatingRuleContentTypeDisplayTitle:
      populateGatingRuleContentTypeDisplayTitle(group),
    gatingRuleContentTypeDisplayMessage:
      populateGatingRuleContentTypeDisplayMessage(group),
    callToActionButtonText: "Purchase",
  }
}

function checkMembershipGatingRuleAccess(
  group: GatingRuleGroup,
  gr: GatingRule,
  sub: Subscription | false | undefined,
  membership: Membership | undefined
): EnrichedGatingRule {
  console.log("GatingRules: Membership gate detected!")

  // First check if they have an active subscription.
  if (sub && sub.status == "ACTIVE") {
    console.log(`GatingRules: User was subscribed ${sub}.`)

    // Now let's also check that they have the appropriate membership.
    const membershipId = (gr.gatingRequirement as GatingRequirementMembership)
      .membershipId

    const isExpired = isSubscriptionToMembershipExpired(membership, sub)

    if (
      membershipId == sub.membershipId &&
      // Either they canceled but the membership is still active, or they never canceled so it's not applicable.
      (isExpired === "ACTIVE" || isExpired === "NOT_APPLICABLE")
    ) {
      console.log(
        `GatingRules: User had the required membership ${membershipId}.`
      )

      return makeHasAccessGatingRule(gr)
    }
  }

  console.log(
    `GatingRules: User was NOT subscribed or was missing required membership.`,
    sub
  )

  return {
    ...gr,
    hasAccess: false,
    noAccessMessage: "You're missing a newsletter subscription.",
    gatingRuleRequirementTypeDisplayTitle:
      populateGatingRuleRequirementTypeDisplayTitle(gr),
    gatingRuleRequirementTypeDisplayMessage:
      populateGatingRuleRequirementTypeDisplayMessage(gr, false),
    gatingRuleContentTypeDisplayTitle:
      populateGatingRuleContentTypeDisplayTitle(group),
    gatingRuleContentTypeDisplayMessage:
      populateGatingRuleContentTypeDisplayMessage(group),
    callToActionButtonText: "Subscribe",
  }
}

// Used when the user has access to the gating rule group and you just need
// a quick way to make an enriched gating rule allowing access.
function makeHasAccessGatingRuleGroup(
  group: GatingRuleGroup
): EnrichedGatingRuleGroup {
  const gatingRules: EnrichedGatingRule[] = []

  for (const gr of group.gatingRules) {
    gatingRules.push(makeHasAccessGatingRule(gr))
  }

  return {
    ...group,
    hasAccess: true,
    enrichedGatingRules: gatingRules,
  }
}

// Used when the user has access to the gating rule and you just need
// a quick way to make an enriched gating rule allowing access.
function makeHasAccessGatingRule(
  gr: GatingRule,
  token?: Token
): EnrichedGatingRule {
  return {
    ...gr,
    hasAccess: true,
    gatingRuleRequirementTypeDisplayTitle:
      populateGatingRuleRequirementTypeDisplayTitle(gr),
    gatingRuleRequirementTypeDisplayMessage:
      populateGatingRuleRequirementTypeDisplayMessage(gr, true, token),
  }
}

function populateGatingRuleRequirementTypeDisplayTitle(
  gatingRule: GatingRule
): string {
  switch (gatingRule.gatingRequirement.gateType) {
    case "MEMBERSHIP":
      return "Membership"
    case "TOKEN":
      return "Token"
    default:
      return ""
  }
}

function populateGatingRuleRequirementTypeDisplayMessage(
  gatingRule: GatingRule,
  hasAccess: boolean,
  token?: Token
): string {
  switch (gatingRule.gatingRequirement.gateType) {
    case "MEMBERSHIP":
      if (hasAccess) return "You're already subscribed!"

      return "a membership"

    case "TOKEN":
      if (hasAccess)
        return `You already have ${
          "tokenMinCount" in gatingRule.gatingRequirement &&
          gatingRule.gatingRequirement.tokenMinCount <= 1
            ? "a "
            : ""
        }${token?.onChainData.name} token`

      if (!token) {
        console.error(
          "An error has occurred processing token gate information. Token was missing",
          gatingRule,
          hasAccess
        )

        return "an unknown token"
      }

      return `${
        !("tokenMinCount" in gatingRule.gatingRequirement)
          ? ""
          : gatingRule.gatingRequirement.tokenMinCount == 1
            ? "a "
            : gatingRule.gatingRequirement.tokenMinCount > 1
              ? `${gatingRule.gatingRequirement.tokenMinCount}x `
              : ""
      }${token.onChainData.name}`
    default:
      return ""
  }
}

function populateGatingRuleContentTypeDisplayTitle(
  group: GatingRuleGroup
): string {
  switch (group.contentType) {
    case "POST":
      return "Post"
    case "AFTER":
      return "Content"
    // case "BLOCK": // Punted for now.
    //   return "Content"
    // case "COMMENTS": // Punted for now.
    //   return "Comments"
    default:
      return ""
  }
}

function populateGatingRuleContentTypeDisplayMessage(
  group: GatingRuleGroup
): string {
  switch (group.contentType) {
    case "POST":
      return "this post"
    case "AFTER":
      return "the content below"
    // case "BLOCK": // Punted for now.
    //   return "the highlighted parts of the post"
    // case "COMMENTS": // Punted for now.
    //   return "to post and view comments"
    default:
      return ""
  }
}

function getTokenById(gr: GatingRule, tokens?: Token[]): Token | undefined {
  if (gr.gatingRequirement.gateType != "TOKEN") {
    console.error(
      "Couldn't find token for ID as the gate type was of type",
      gr.gatingRequirement.gateType
    )

    return
  }

  const tokenId = (gr.gatingRequirement as GatingRequirementToken).tokenId

  const token = tokens?.find((t) => t.id == tokenId)

  if (!token) {
    const err = new Error("Error occurred trying to retrieve token data.")

    console.error(err, {
      token,
      tokenId,
      tokens,
    })

    captureException({
      err,
      token,
      tokenId,
      tokens,
    })
  }

  return token
}

function getRequiredMembershipById(
  gr: GatingRule,
  memberships?: Membership[]
): Membership | undefined {
  if (gr.gatingRequirement.gateType != "MEMBERSHIP") return

  const membershipId = (gr.gatingRequirement as GatingRequirementMembership)
    .membershipId

  const membership = memberships?.find((m) => m.id == membershipId)

  if (!membership) {
    console.error("Error occurred trying to retrieve membership data.", {
      membership,
      membershipId,
      memberships,
    })
  }

  return membership
}

// Navbar displayed at the top of the gate modal wizard.

import { CheckIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import { SharedProps } from "./SharedProps"

export default function GateModalNavBar(props: SharedProps) {
  return (
    <nav
      aria-label="Progress"
      className={`flex justify-center ${
        props.steps && props.steps.length <= 1 && props.notMovedAStepYet // If we've only got one step available and not yet moved from it (new modal)...
          ? "h-0 invisible" // Then show no nav bar.
          : props.steps && props.steps.length > 1 // If there's more than one step, show a navbar with a transition.
            ? "h-8 visible transition-all duration-700"
            : "h-0 invisible transition-all duration-300" // Collapse navbar with a smooth animation when not needed anymore (e.g. ending the final step of token gate creation).
      }`}
    >
      <ol role="list" className="flex items-center">
        {props.steps?.map((step, stepIdx) => (
          <li
            key={step.name}
            className={clsx(
              stepIdx !== props.steps.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative"
            )}
          >
            {step.status === "COMPLETED" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-blue-600" />
                </div>
                <a
                  className="cursor-pointer relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                  onClick={() => props.changeStep(step.id)}
                >
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "CURRENT" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  className="cursor-pointer relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blue-600 bg-white"
                  aria-current="step"
                  onClick={() => props.changeStep(step.id)}
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-blue-600"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  className="group cursor-pointer relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  onClick={() => props.changeStep(step.id)}
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

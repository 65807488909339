import { JSONContent } from "@tiptap/react"
import type { MembershipWithNFT, Subscription } from "@types"
import { isPlaceholderImage } from "components/Editor/Image/constants"

import { CompletedCollectible } from "@/types/collectibles"
import { isMaster } from "@/util/constants/server"

import _ from "lodash"
import { fixImageNodeName } from "./tiptap"

type ALL_KEYS = "page" | "category" | "days" | "community"

const KEYS: Array<ALL_KEYS> = ["page", "category", "days", "community"]

export function getPathValue(arr: Array<string> | undefined, key: ALL_KEYS) {
  console.log("About to get path value for this...", arr, key)
  if (!arr) return

  const indx = arr.findIndex((param) => param === key)

  console.log("Found one!", indx, arr[indx + 1])

  if (indx >= 0 && indx < arr.length - 1) {
    return arr[indx + 1]
  }

  return
}

export function truncateString(str: string) {
  if (str.length > 6) {
    return str.substring(0, 6) + "..." + str.substring(str.length - 2)
  }
  return str
}

function updatePathValue(
  arr: Array<string> | undefined,
  key: ALL_KEYS,
  value: any
) {
  const toReturn: Array<string> = []

  console.log("Updating path value...", arr, key, value)

  const allPathValues = KEYS.map((allKey) => {
    if (allKey === key) {
      toReturn.push(key)
      toReturn.push(value)
    } else {
      const pathValue = getPathValue(arr, allKey)
      if (!pathValue) return
      toReturn.push(allKey)
      toReturn.push(pathValue)
    }
  })

  console.log("Updated all path values!", toReturn)
  return toReturn
}

// Remove base64 image placeholders
const removeImagePlaceholders = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (
      element &&
      element.type === "image" &&
      isPlaceholderImage(element.attrs.src)
    )
      return false
    if (
      element &&
      element.type === "figure" &&
      element.content[0].type === "image" &&
      isPlaceholderImage(element.content[0].attrs.src)
    )
      return false
    if (Array.isArray(element.content))
      return _.isEqual(removeImagePlaceholders(element), element.content)
    return true
  })
  return content
}

// Remove link embed placeholder
const removeLinkPlaceholders = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (element && element.type === "embedly" && !element.attrs.data)
      return false
    if (Array.isArray(element.content))
      return _.isEqual(removeLinkPlaceholders(element), element.content)
    return true
  })
  return content
}

// Remove Twitter embed placeholder
const removeTwitterPlaceholders = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (element && element.type === "twitter" && !element.attrs.tweetData)
      return false
    if (Array.isArray(element.content))
      return _.isEqual(removeTwitterPlaceholders(element), element.content)
    return true
  })
  return content
}

// Remove Sound.xyz embed placeholder
const removeSoundXyzPlaceholders = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (element && element.type === "soundXyz" && !element.attrs.embedId)
      return false
    if (Array.isArray(element.content))
      return _.isEqual(removeSoundXyzPlaceholders(element), element.content)
    return true
  })
  return content
}

const ignoreCommandListSlash = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (
      element &&
      element.type === "text" &&
      // https://regex101.com/r/224zLm/1
      /(^|\s)\/([^\s]*)$/gm.test(element.text)
    ) {
      console.log("cleanup, found command trigger", element.text)
      return false
    }
    if (Array.isArray(element.content))
      return _.isEqual(ignoreCommandListSlash(element), element.content)
    return true
  })
  return content
}

// Don't save Read More Gate modal changes
const ignoreReadMoreGateModalState = (json: JSONContent) => {
  const content: any = json.content?.map((element: any) => {
    if (element && element.type === "afterGate")
      return {
        ...element,
        attrs: { ...element.attrs, open: open === null ? null : false },
      }
    else if (Array.isArray(element.content))
      return ignoreReadMoreGateModalState(element)
    else return element
  })
  return { ...json, content }
}

// Don't save Empty State loading state changes
const ignoreEmptyStateState = (json: JSONContent) => {
  const content: any = json.content?.map((element: any) => {
    if (element && element.type === "emptyState")
      return {
        ...element,
        attrs: null,
      }
    else return element
  })
  return { ...json, content }
}

// Remove AskAi prompt
const removeAskAI = (json: JSONContent) => {
  const content: any = json.content?.filter((element: any) => {
    if (element && element.type === "askAi") return false
    else if (Array.isArray(element.content))
      return _.isEqual(removeAskAI(element), element.content)
    else return true
  })
  return content
}

const convertImageToFigure = (json: JSONContent) => {
  const content: any = json.content?.map((element: any) => {
    if (
      element &&
      element.type === "image" &&
      !isPlaceholderImage(element.attrs.src)
    )
      return {
        type: "figure",
        content: [element],
      }
    else if (Array.isArray(element.content) && element.type !== "figure")
      return convertImageToFigure(element)
    else return element
  })
  return { ...json, content }
}

export const cleanupJsonBeforeLoad = (json: JSONContent) => {
  let cleanJson = fixImageNodeName(json)
  cleanJson = convertImageToFigure(cleanJson)
  return cleanJson
}

export const cleanupJsonBeforeSave = (json: JSONContent) => {
  if (!_.isEqual(json.content, removeImagePlaceholders(json))) {
    console.log("Pending image embed. Skipping update...")
    return { json, skipSave: true }
  }
  if (!_.isEqual(json.content, removeLinkPlaceholders(json))) {
    console.log("Pending link embed. Skipping update...")
    return { json, skipSave: true }
  }
  if (!_.isEqual(json.content, removeTwitterPlaceholders(json))) {
    console.log("Pending Twitter embed. Skipping update...")
    return { json, skipSave: true }
  }
  if (!_.isEqual(json.content, removeSoundXyzPlaceholders(json))) {
    console.log("Pending Sound.xyz embed. Skipping update...")
    return { json, skipSave: true }
  }
  if (!_.isEqual(json.content, removeAskAI(json))) {
    console.log("AskAI prompt. Skipping update...")
    return { json, skipSave: true }
  }

  // Filter out specific state changes
  let cleanJson = ignoreEmptyStateState(json)
  cleanJson = ignoreReadMoreGateModalState(cleanJson)

  return { json: cleanJson, skipSave: false }
}

export const getInitials = (name: string) => {
  const data = name.split(" ")
  let output = ""

  for (let i = 0; i < data.length; i++) {
    output += data[i].substring(0, 1)
  }

  return output
}

type OpenSeaChains =
  | "ethereum"
  | "matic"
  | "optimism"
  | "base"
  | "zora"
  | "zora-testnet"

const openseaUrl = (
  chain: OpenSeaChains,
  address: string,
  tokenId?: string
) => {
  if (chain === "zora") {
    return `https://zora.co/collect/zora:${address}/${tokenId || ""}`
  }

  if (chain === "zora-testnet") {
    return `https://testnet.zora.co/collect/zgor:${address}/${tokenId || ""}`
  }

  return `https://${
    !isMaster && !process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS ? "testnets." : ""
  }opensea.io/assets/${chain}/${address}/${tokenId || ""}`
}

export const openseaUrlFromCollectible = (
  collectible: CompletedCollectible
) => {
  let chain: string = collectible.chain

  if (chain === "polygon") {
    chain = "matic"
  }

  if (
    chain === "zora" &&
    !isMaster &&
    !process.env.NEXT_PUBLIC_FORCE_PROD_CHAINS
  ) {
    chain = "zora-testnet"
  }

  return openseaUrl(chain as OpenSeaChains, collectible.contractAddress)
}

export const openseaUrlFromMembership = (
  membership: MembershipWithNFT,
  subscription?: Subscription
) => {
  let chain: string = membership.chain || "matic"

  if (chain === "polygon") {
    chain = "matic"
  }

  return openseaUrl(
    chain as OpenSeaChains,
    membership.contractAddress,
    subscription?.tokenId
  )
}

/**
 * Extract hours and minutes from a date object and formats it as a string in the format hh:mm,
 * useful for input field of type time.
 * @param date Date object.
 * @returns String in the format hh:mm.
 */
export function formatTimeFromDate(date: Date): string {
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")

  return `${hours}:${minutes}`
}

/**
 * Checks if a time is after another time, using hh:mm format for time.
 * @param time Time to check.
 * @param after Time to check against.
 * @returns True if time is after "after", false otherwise.
 */
export function isTimeAfter(time: string, after: string): boolean {
  const [hours, minutes] = time.split(":")
  const [afterHours, afterMinutes] = after.split(":")

  return (
    parseInt(hours) > parseInt(afterHours) ||
    (parseInt(hours) === parseInt(afterHours) &&
      parseInt(minutes) > parseInt(afterMinutes))
  )
}

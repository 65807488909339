export const validImageDomains = ["storage.googleapis.com"]
export const base64ImageRegex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/

export const isPlaceholderImage = (src: string) =>
  base64ImageRegex.test(src) || isExternalImage(src)

export const isBase64Image = (src: string) => base64ImageRegex.test(src || "")
export const isSvgImage = (src: string) => src.includes("data:image/svg+xml")
export const isExternalImage = (src: string) =>
  !validImageDomains.some((domain: string) => src?.includes(domain))

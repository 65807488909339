import { Collectible } from "@/types/collectibles"
import { User } from "@/types/users"
import { getCollectorsOverview } from "api_routes/collectibles"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectPostCollectors } from "features/pageSlice"

function sortUsers(a?: Partial<User>, b?: Partial<User>) {
  if (!a && !b) return 0
  if (!a) return -1
  if (!b) return 1

  if (a.avatar_url && b.avatar_url) return 0
  if (a.avatar_url) return -1
  if (b.avatar_url) return 1
  return 0
}

/**
 * remainingSupply is the number on-chain that's remaining.
 * Whenever it's changed (since we re-fetch on-chain data periodically),
 * we refetch the collectors and populate this, so it's updated in real-time.
 */
export default function useCollectors(
  collectible?: Collectible,
  remainingSupply?: string | number | bigint
) {
  const postCollectors = useSelector(selectPostCollectors)

  let defaultState: Array<Partial<User>> = []

  if (collectible?.type === "POST") {
    defaultState = postCollectors.records.map((c) => c.user).sort(sortUsers)
  }

  const [collectors, setCollectors] =
    useState<Array<Partial<User>>>(defaultState)

  const stringifiedCollectible = JSON.stringify(collectible)
  const stringifiedPostCollectors = JSON.stringify(postCollectors)

  useEffect(() => {
    if (!collectible || !collectible.id) return

    // Re-fetch collectors on the client-side, if the collectible changes
    // or if the supply changes.
    //
    // Supply is fetched onchain periodically.
    getCollectorsOverview(collectible.id, 5).then((res) => {
      const { total, records } = res
      const users = records.map((r) => r.user).sort(sortUsers)

      setCollectors(users)
    })
  }, [stringifiedCollectible, remainingSupply, stringifiedPostCollectors])

  console.log("Returning these collectors", collectors)

  return collectors
}

import { Subscription, SubscriptionOptOutOrIn } from "@types"
import { getLoggedInUsersBlog } from "features/blogSlice"
import { useAppDispatch } from "store"

import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Combobox, Switch } from "@headlessui/react"
import clsx from "clsx"
import { deleteSubscription } from "features/userSlice"
import { unsubscribeSubscriberBulk } from "api_routes/blogs"
import AreYouSureConfirmationDialogue from "components/AreYouSureConfirmationDialogue"
import { SubscriptionToOptOutsMap } from "./ManageCommunitiesModal"

/**
 * Displays subscription opt-outs and allows readers and writers to manage their preferences.
 * @param param0
 * @returns
 */
export default function OptOutsCombobox({
  subscriptions,
  availableOptOuts,
  initialOptOuts,
  onOptOutAddedCallback,
  onOptOutRemovedCallback,
  isAuthorView,
  setParentModalIsOpen,
}: {
  /**
   * Can be a single subscription (in which case, let's preload their opt-outs)
   * or an array of subscriptions (in which case, we're doing a bulk edit so let's start by showing no opt-outs).
   */
  subscriptions: Subscription[]
  /**
   * Array of all possible opt-outs, including both communities and the "General Emails" opt-out.
   */
  availableOptOuts: SubscriptionOptOutOrIn[]
  /**
   * Opt outs the user has actually chosen.
   */
  initialOptOuts: SubscriptionToOptOutsMap
  /**
   * Gets called when an opt-out is added (meaning a user has opted out of a community).
   */
  onOptOutAddedCallback: (
    subscriptionIds: string[],
    community: SubscriptionOptOutOrIn
  ) => void
  /**
   * Gets called when an opt-out is removed (meaning a user has opted back in to a community).
   */
  onOptOutRemovedCallback: (
    subscriptionIds: string[],
    community: SubscriptionOptOutOrIn
  ) => void
  /**
   * Choose between author and reader view.
   */
  isAuthorView: boolean
  /**
   * Optional when this is embedded somewhere other than a modal.
   */
  setParentModalIsOpen?: Dispatch<SetStateAction<boolean>>
}): JSX.Element | null {
  const dispatch = useAppDispatch()
  const [query, setQuery] = useState("")
  const [confirmUnsubscribe, setConfirmUnsubscribe] = useState(false)
  const [showDialogue, setShowDialogue] = useState(false)
  const [unsubscribeAttempt, setUnsubscribeAttempt] = useState(false)

  const isSingleSubscription = subscriptions.length === 1
  const penultimateOptOut =
    isSingleSubscription &&
    availableOptOuts.length - initialOptOuts?.[0]?.length == 1

  const filteredCommunities =
    query === ""
      ? availableOptOuts
      : availableOptOuts.filter((optOut: SubscriptionOptOutOrIn) => {
          return optOut.name.toLowerCase().includes(query.toLowerCase())
        })

  useEffect(() => {
    if (penultimateOptOut && unsubscribeAttempt) {
      setShowDialogue(true)
    }
  }, [unsubscribeAttempt])

  const unsubscribe = () => {
    for (const subscription of subscriptions) {
      // If we're dealing with an unsubscribed user, do nothing.
      if (subscription.status == "UNSUBSCRIBED") return

      // If reader is deleting their own subscription.
      if (!isAuthorView) {
        dispatch(deleteSubscription(subscription.id))
      } else {
        // If author is deleting a reader's subscription.
        const authorUnsubscribe = async () => {
          await unsubscribeSubscriberBulk([subscription.id])

          // Refresh the currently logged-in users blog.
          // This will update the subscriber count, and thus, will
          // cause the proper data to be fetched.
          dispatch(getLoggedInUsersBlog())
        }

        authorUnsubscribe()
      }
    }

    // And close the modal.
    setParentModalIsOpen?.(false)
  }

  if (subscriptions.length === 0) return null

  return (
    <Combobox>
      {/* Leaving this in for later in case we want to make this into a generic modal or enable search. */}
      {/* <div className="relative">
          <MagnifyingGlassIcon
            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <Combobox.Input
            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            onChange={(event) => setQuery(event.target.value)}
          />
        </div> */}

      {filteredCommunities.length > 0 && (
        <Combobox.Options
          static
          className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
        >
          {filteredCommunities.map((community: SubscriptionOptOutOrIn) => (
            <Combobox.Option
              key={community.id}
              value={community}
              className={({ active, disabled }) =>
                clsx(
                  "select-none px-4 py-2",
                  // active && !disabled && "bg-blue-600 text-white",
                  disabled ? "bg-gray-100 text-gray-400" : ""
                )
              }
              disabled={false}
            >
              {({ active, disabled }) => (
                <div className="grid grid-cols-8 space-x-2 items-center">
                  <div className="col-span-1 flex items-center">
                    <ShortToggle
                      community={community}
                      initialOptOuts={initialOptOuts}
                      onOptOutAddedCallback={onOptOutAddedCallback}
                      onOptOutRemovedCallback={onOptOutRemovedCallback}
                      penultimateOptOut={penultimateOptOut}
                      setUnsubscribeAttempt={setUnsubscribeAttempt}
                      confirmUnsubscribe={confirmUnsubscribe}
                    />
                  </div>
                  <div className="col-span-3">{community.name}</div>
                  {community.description && (
                    <div
                      className={clsx(
                        "col-span-4"
                        // active && !disabled
                        //   ? "text-gray-200"
                        // : disabled
                        // ? "text-gray-400"
                        // : "text-gray-500"
                      )}
                    >
                      {community.description}
                    </div>
                  )}
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}

      {penultimateOptOut && !confirmUnsubscribe && unsubscribeAttempt && (
        <div className="px-2 py-4">
          <AreYouSureConfirmationDialogue
            isDestructiveAction={true}
            showDialogue={showDialogue}
            setShowDialogue={setShowDialogue}
            onConfirmCallback={() => {
              setConfirmUnsubscribe(true)
              unsubscribe()
            }}
            onCancelCallback={() => setUnsubscribeAttempt(false)}
            confirmTrackingData={
              isSingleSubscription && {
                userId: subscriptions[0].userId,
                blogId: subscriptions[0].blogId,
                subscriptionId: subscriptions[0].id,
              }
            }
            customPromptText={
              isAuthorView
                ? "Are you sure? This will unsubscribe this reader."
                : "Are you sure? This will unsubscribe you."
            }
          />
        </div>
      )}

      {isAuthorView && query === "" && filteredCommunities.length === 1 && (
        <p className="flex justify-center p-4 text-sm text-gray-500 whitespace-pre">
          Want to segment your audience? Add a community{" "}
          <a
            href="/settings/publication/communities"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 hover:text-blue-700 underline"
          >
            here
          </a>
          .
        </p>
      )}
    </Combobox>
  )
}

enum ToggleStatus {
  true,
  false,
  /**
   * For instance when some subscriptions have this community enabled and some disabled.
   */
  "mixed",
}

function ShortToggle({
  community,
  initialOptOuts,
  onOptOutAddedCallback,
  onOptOutRemovedCallback,
  penultimateOptOut,
  setUnsubscribeAttempt,
  confirmUnsubscribe,
}: {
  community: SubscriptionOptOutOrIn
  initialOptOuts: SubscriptionToOptOutsMap
  /**
   * Gets called when an opt-out is added (meaning a user has opted out of a community).
   */
  onOptOutAddedCallback: (
    subscriptionIds: string[],
    community: SubscriptionOptOutOrIn
  ) => void
  /**
   * Gets called when an opt-out is removed (meaning a user has opted back in to a community).
   */
  onOptOutRemovedCallback: (
    subscriptionIds: string[],
    community: SubscriptionOptOutOrIn
  ) => void
  penultimateOptOut: boolean
  setUnsubscribeAttempt: Dispatch<SetStateAction<boolean>>
  confirmUnsubscribe: boolean
}) {
  // Get an array of booleans indicating whether each community is enabled or not across all subscriptions.
  const enabledArr = Object.entries(initialOptOuts).map(
    ([, communities]) => !communities?.some((c) => c.id === community.id)
  )

  // If all subscriptions have this community enabled, then we want to show the toggle as enabled.
  // If all subscriptions have this community disabled, then we want to show the toggle as disabled.
  // If some subscriptions have this community enabled and some disabled, then we want to show the toggle as indeterminate.
  const enabled = enabledArr.every((e) => e === true)
    ? ToggleStatus.true
    : enabledArr.every((e) => e === false)
      ? ToggleStatus.false
      : ToggleStatus.mixed

  const subscriptionIds = Object.keys(initialOptOuts)

  return (
    <Switch
      checked={
        enabled === ToggleStatus.mixed ? false : enabled === ToggleStatus.true
      }
      onChange={(newValue) => {
        // If this is the last opt-out being selected, and we're about to unsubscribe the user, do nothing until we have confirmation.
        if (!newValue && penultimateOptOut && !confirmUnsubscribe) {
          setUnsubscribeAttempt(true)
          return
        } else {
          setUnsubscribeAttempt(false)
        }

        // If the community was previously enabled, then we now want to disable it (opt-out of emails for it).
        if (newValue === false) {
          console.log("Adding community to subscription opt-out.")

          onOptOutAddedCallback(subscriptionIds, community)
        } else {
          // If the community was previously disabled (opted out of), then we now want to enable it (opt in again).
          console.log("Removing community from subscription opt-out.")

          onOptOutRemovedCallback(subscriptionIds, community)
        }
      }}
      className={
        "group relative inline-flex h-5 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
      }
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className="pointer-events-none absolute h-full w-full rounded-md bg-white"
      />
      <span
        aria-hidden="true"
        className={clsx(
          "pointer-events-none absolute mx-auto h-4 w-11 rounded-full transition-colors duration-200 ease-in-out",
          enabled === ToggleStatus.true
            ? "bg-blue-600"
            : enabled === ToggleStatus.false
              ? "bg-gray-200"
              : "bg-mixed-left-blue-600-right-gray-200"
        )}
      />
      <span
        aria-hidden="true"
        className={clsx(
          "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out",
          enabled === ToggleStatus.true
            ? "translate-x-7"
            : enabled === ToggleStatus.false
              ? "translate-x-0"
              : "translate-x-3.5"
        )}
      />
    </Switch>
  )
}

import { axios, customDomainAxios, params } from "./resolve"
import { AxiosResponse } from "axios"

import {
  CommunityEntryRes,
  EmptyBlog,
  SubscriberCountResp,
  UserSubscriptionAndMembershipPage,
  SuccessResponse,
  Blog,
  Link,
  ErrorResponse,
  Subscription,
  ImportFileResponse,
  GetCustomDomainResponse,
  UserAndSubscription,
  Community,
  CommunityEntry,
  ImportType,
  BlogRecommendation,
  RecommendationItem,
  BlogIntroduction,
  PostSubscribeState,
} from "@types"

import { Token } from "@/types/gatingRules"
import { getCreatorReferrer, getUtmCookie } from "util/cookies"
import { RecommendationModalItem } from "components/AddRecommendationModal"

const API = "/blogs"

// Get the logged in user's blog.
// NOTE: The server-side API returns an array of blogs,
// but right now we only support 1 blog per user, so grab the first.
// If blogId is present, we're getting a different blog that the user has access to edit.
export async function getLoggedInUsersBlog(): Promise<Blog> {
  const res: AxiosResponse<undefined | Array<Blog>> = await axios.get(
    API,
    params
  )
  return res?.data?.[0] || EmptyBlog
}

export async function getBlogByUrl(url: string): Promise<Blog> {
  console.log("API call: getting  blog by url...", url)

  const res: AxiosResponse<Blog> = await axios.get(`${API}/${url}`)

  console.log("Retrieved blog by URL!")

  return res.data || EmptyBlog
}

/**
 * Doesn't return any user information. Currently only used by the logged out unsubscribe user flow
 * e.g. when a user clicks unsubscribe in an email.
 * @param blogId ID of the blog to unsubscribe from.
 * @returns Blog object if successful, ErrorResponse if not.
 */
export async function getBlogById(
  blogId: string
): Promise<Blog | ErrorResponse> {
  const res: AxiosResponse<Blog | ErrorResponse> = await axios.get(
    `${API}/id/${blogId}`
  )

  return res.data
}

export async function updateBlog(
  blog: Blog
): Promise<ErrorResponse | SuccessResponse> {
  console.log("Updating blog... Using the following data:")
  console.log(blog)

  try {
    // If the user has a creator referrer cookie, we want to pass it along to the
    // server so we can update the user's creator referrer in certain instances
    // (eg if this is a brand new blog they're updating).
    const referrer = getCreatorReferrer()

    const queryParams: {
      params: {
        blogId?: string
        referrerWalletAddress?: string
      }
    } = params

    if (referrer) {
      queryParams.params.referrerWalletAddress = referrer
    }

    const res: AxiosResponse<SuccessResponse> = await axios.put(
      `${API}/${blog.id}`,
      blog,
      queryParams
    )
    return res.data
  } catch (err: any) {
    if (err?.response?.status === 400) {
      return {
        msg: err.response.data.msg,
      }
    }

    console.error("Error encountered", err)

    return { msg: "An unknown error occurred updating your blog" }
  }
}

export async function changeLogo(file: File): Promise<boolean> {
  const formData = new FormData()
  formData.append("file", file)

  const res: AxiosResponse<boolean> = await axios.post(
    `${API}/logo`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...params,
    }
  )

  return res.data
}

export async function removeLogo(): Promise<boolean> {
  const res: AxiosResponse<boolean> = await axios.delete(`${API}/logo`)

  return res.data
}

// Import either blogposts or subscribers from another blog.
export async function importFile(
  file: File,
  type: ImportType,
  importIntoCommunities: boolean,
  communityIds: string[]
): Promise<ImportFileResponse | ErrorResponse> {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("importType", type)
  formData.append("importIntoCommunities", importIntoCommunities.toString())
  formData.append("communityIds", communityIds.join(","))

  console.log("About to POST file for import", { type })

  try {
    const res: AxiosResponse<ImportFileResponse | ErrorResponse> =
      await axios.post(`${API}/import`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...params,
      })

    return res.data
  } catch (err) {
    return { msg: "Something went wrong importing file." + err, success: false }
  }
}

/**
 * Used when a subscriber is organically subscribing to a blog
 * (eg a writer is NOT adding them manually to their blog).
 */
export async function subscribeUserToBlog({
  lowercaseBlogUrl,
  blogId,
  referrer,
  skipWelcomeEmail,
  email,
}: {
  lowercaseBlogUrl: string

  blogId: string

  // If subscription is coming from a recommendation, this is the blog ID and wallet of the blog that did the recommending
  referrer?: { id: string; wallet: string }

  // If we want to skip the welcome email (eg, if the user is mass-subscribing to Farcaster followers)
  skipWelcomeEmail?: boolean

  // Email only needs to be present if the user is NOT logged in
  email?: string
}): Promise<ErrorResponse | { sub: Subscription, nextState: PostSubscribeState }> {
  const _params = params.params

  if (skipWelcomeEmail) {
    // @ts-ignore
    _params.skipWelcomeEmail = skipWelcomeEmail
  }

  const utm = getUtmCookie(blogId)

  const customDomainAxiosInstance = customDomainAxios()

  // If we're on a custom domain, try subscribing here first to properly
  // set cookies on this domain.
  if (customDomainAxiosInstance) {
    const res: AxiosResponse = await customDomainAxiosInstance.post(
      `${API}/${lowercaseBlogUrl}/subscribe`,
      { email, utm, referrer },
      { params: _params }
    )

    return res.data
  }

  const res: AxiosResponse = await axios.post(
    `${API}/${lowercaseBlogUrl}/subscribe`,
    { email, utm, referrer },
    { params: _params }
  )

  return res.data
}

/**
 * Used when a subscriber is organically subscribing to a blog
 * (e.g. a writer is NOT adding them manually to their blog).
 * Specifically when they are RE-subscribing to a blog they've
 * previously unsubscribed from, using an unsubscribe code.
 */
export async function resubscribeExistingUserUsingmanageSubscriptionCode({
  lowercaseBlogUrl,
  manageSubscriptionCode,
}: {
  lowercaseBlogUrl: string
  manageSubscriptionCode: string
}): Promise<ErrorResponse | Subscription> {
  const res: AxiosResponse = await axios.post(
    `${API}/${lowercaseBlogUrl}/subscribe/manageSubscriptionCode/${manageSubscriptionCode}`
  )

  return res.data
}

export async function unsubscribeSubscriberBulk(
  subscriptionIds: Array<string>
): Promise<ErrorResponse | Subscription> {
  const res: AxiosResponse = await axios.post(
    `${API}/subscriptions/delete/bulk`,
    { subscriptionIds },
    { params }
  )

  return res.data
}

export async function unsubscribeSubscriber(
  subId: string
): Promise<ErrorResponse | Subscription> {
  const res: AxiosResponse = await axios.delete(
    `${API}/subscriptions/${subId}`,
    params
  )

  return res.data
}

export async function unsubscribeSubscriberUsingmanageSubscriptionCode(
  subId: string,
  manageSubscriptionCode: string
): Promise<ErrorResponse | Subscription> {
  const res: AxiosResponse = await axios.delete(
    `${API}/subscriptions/${subId}/manageSubscriptionCode/${manageSubscriptionCode}`,
    params
  )

  return res.data
}

/**
 * Adds an opt out to a subscription.
 * A subscription can have 0 or more opt outs. 0 means they're subscribed to every community.
 * Otherwise, the list serves as a blacklist of what communities they've opted out of.
 * @param subscriptionId The ID of the subscription from which we're adding an opt out to.
 * @param communityId The ID of the community which we're opting out of.
 * @returns Response from API, either the updated subscription or an error.
 */
export async function addSubscriptionOptOut(
  subscriptionId: string,
  optOutId: string
) {
  const res: AxiosResponse<UserAndSubscription | ErrorResponse> =
    await axios.post(
      `${API}/subscriptions/${subscriptionId}/optoutid/${optOutId}`,
      {},
      params
    )

  return res.data
}

/**
 * Adds an opt out to a subscription.
 * A subscription can have 0 or more opt outs. 0 means they're subscribed to every community.
 * Otherwise, the list serves as a blacklist of what communities they've opted out of.
 * Logged out user flow.
 * @param subscriptionId The ID of the subscription from which we're adding an opt out to.
 * @param communityId The ID of the community which we're opting out of.
 * @param manageSubscriptionCode The unsubscribe code of the subscription.
 * @returns Response from API, either the updated subscription or an error.
 */
export async function addSubscriptionOptOutWithmanageSubscriptionCode(
  subscriptionId: string,
  optOutId: string,
  manageSubscriptionCode: string
) {
  const res: AxiosResponse<UserAndSubscription | ErrorResponse> =
    await axios.post(
      `${API}/subscriptions/${subscriptionId}/optoutid/${optOutId}/manageSubscriptionCode/${manageSubscriptionCode}`,
      {},
      params
    )

  return res.data
}

/**
 * Adds an opt out to an array of subscriptions in bulk.
 * @param subscriptionIds Array of subscription IDs to add the opt out to.
 * @param optOutId ID of the community to opt out of, or the string "nonCommunityEmails".
 * @returns Response from API, either the updated subscriptions or an error.
 */
export async function addSubscriptionOptOutsInBulk(
  subscriptionIds: string[],
  optOutId: string,
  blogId: string
) {
  const res: AxiosResponse<UserAndSubscription[] | ErrorResponse> =
    await axios.post(
      `${API}/subscriptions/optouts/bulk/add`,
      { subscriptionIds, optOutId, blogId },
      params
    )

  return res.data
}

/**
 * Removes a community opt out from a subscription (opting them back in).
 * A subscription can have 0 or more opt outs. 0 means they're subscribed to every community.
 * Otherwise, the list serves as a blacklist of what communities they've opted out of.
 * @param subscriptionId The ID of the subscription from which we're removing opt outs.
 * @param communityId The ID of the community which we're removing the opt out for.
 * @returns Response from API, either the updated subscription or an error.
 */
export async function removeSubscriptionOptOut(
  subscriptionId: string,
  communityId: string
) {
  const res: AxiosResponse<UserAndSubscription | ErrorResponse> =
    await axios.delete(
      `${API}/subscriptions/${subscriptionId}/optoutid/${communityId}`,
      params
    )

  return res.data
}

/**
 * Removes a community opt out from a subscription (opting them back in).
 * A subscription can have 0 or more opt outs. 0 means they're subscribed to every community.
 * Otherwise, the list serves as a blacklist of what communities they've opted out of.
 * Logged out user flow.
 * @param subscriptionId The ID of the subscription from which we're removing opt outs.
 * @param communityId The ID of the community which we're removing the opt out for.
 * @param manageSubscriptionCode The unsubscribe code of the subscription.
 * @returns Response from API, either the updated subscription or an error.
 */
export async function removeSubscriptionOptOutWithmanageSubscriptionCode(
  subscriptionId: string,
  communityId: string,
  manageSubscriptionCode: string
) {
  const res: AxiosResponse<UserAndSubscription | ErrorResponse> =
    await axios.delete(
      `${API}/subscriptions/${subscriptionId}/optoutid/${communityId}/manageSubscriptionCode/${manageSubscriptionCode}`,
      params
    )

  return res.data
}

/**
 * Removes an opt out from an array of subscriptions in bulk.
 * @param subscriptionIds Array of subscription IDs to remove the opt out from.
 * @param optOutId ID of the community to opt into, or the string "nonCommunityEmails".
 * @returns Response from API, either the updated subscriptions or an error.
 */
export async function removeSubscriptionOptOutsInBulk(
  subscriptionIds: string[],
  optOutId: string,
  blogId: string
) {
  const res: AxiosResponse<UserAndSubscription[] | ErrorResponse> =
    await axios.post(
      `${API}/subscriptions/optouts/bulk/remove`,
      { subscriptionIds, optOutId, blogId },
      params
    )

  return res.data
}

// Used when a logged-in user is manually adding
// a new subscriber to the blog
export async function addSubscriberToBlog(
  email?: string,
  wallet?: string
): Promise<UserAndSubscription | ErrorResponse> {
  const res: AxiosResponse = await axios.post(
    `${API}/add-subscriber`,
    {
      email,
      wallet,
    },
    params
  )

  return res.data
}

export async function getBlogsSubscribersBySearch(
  blogId: string,
  pageSize: number,
  offset = 0, // Default to 0 offset (start at the beginning).
  searchTerm = "",
  filter: Array<"Active" | "Unsubscribed"> = ["Active"],
  communityFilter?: CommunityEntry
): Promise<UserSubscriptionAndMembershipPage | undefined> {
  if (!blogId || !searchTerm) {
    console.error(
      "Unable to fetch blogs subscribers: no blogId or searchtermpassed"
    )
    return
  }

  const res: AxiosResponse = await axios.get(
    `${API}/${blogId}/subscribers/search/${searchTerm}?pageSize=${pageSize}&offset=${offset}&filter=${
      filter.length > 0 ? filter : "Active"
    }${communityFilter ? `&communityFilter=${communityFilter?.id}` : ""}`,
    params
  )

  return res.data
}

export async function getBlogsSubscribersCount(
  blogId: string
): Promise<SubscriberCountResp> {
  if (!blogId) {
    console.error("Unable to fetch blogs subscribers: no blogId passed")
    return {
      total: 0,
      active: 0,
      unsubscribed: 0,
      activeAndOptedIntoGeneral: 0,
    }
  }

  const res: AxiosResponse = await axios.get(
    `${API}/${blogId}/subscribers/count`,
    params
  )

  return res.data || { total: 0, active: 0, unsubscribed: 0 }
}

export async function getBlogsSubscribersPage(
  blogId: string,
  pageSize: number,
  offset = 0, // Default to 0 offset (start at the beginning).
  filter: Array<"Active" | "Unsubscribed"> = ["Active"],
  communityFilter?: CommunityEntry
): Promise<UserSubscriptionAndMembershipPage | undefined> {
  if (!blogId) {
    console.error("Unable to fetch blogs subscribers: no blogId passed")
    return
  }

  const res: AxiosResponse = await axios.get(
    `${API}/${blogId}/subscribers?pageSize=${pageSize}&offset=${offset}&filter=${
      filter?.length > 0 ? filter : "Active"
    }${communityFilter ? `&communityFilter=${communityFilter?.id}` : ""}`,
    params
  )

  return res.data
}

export async function addCustomDomain(
  domain: string
): Promise<ErrorResponse | boolean> {
  const res: AxiosResponse<ErrorResponse | boolean> = await axios.post(
    `${API}/domain`,
    { domain },
    params
  )

  return res.data
}

export async function removeCustomDomain(
  domain: string
): Promise<Array<Subscription>> {
  const res: AxiosResponse<Array<Subscription>> = await axios.delete(
    `${API}/domain/${domain}`,
    params
  )

  return res.data
}

export async function updateLinks(links: Array<Link>): Promise<boolean> {
  const res: AxiosResponse<boolean> = await axios.post(
    `${API}/links`,
    {
      links,
    },
    params
  )

  return res.data
}

export async function verifyCustomDomain(
  domain: string
): Promise<GetCustomDomainResponse | ErrorResponse> {
  const res: AxiosResponse<GetCustomDomainResponse | ErrorResponse> =
    await axios.get(`${API}/domain/${domain}`, params)

  return res.data
}

export async function deleteCommunity(communityId: string) {
  const res: AxiosResponse<ErrorResponse | null> = await axios.delete(
    `${API}/community/${communityId}`,
    params
  )

  return res.data
}

export async function deleteToken(id: string) {
  const res: AxiosResponse<ErrorResponse | null> = await axios.delete(
    `${API}/token/${id}`,
    params
  )

  return res.data
}

export async function addCommunity(
  community: Community,
  addSubscribersToNewCommunity: boolean
) {
  const res: AxiosResponse<GetCustomDomainResponse | ErrorResponse> =
    await axios.post(
      `${API}/community`,
      { community, addSubscribersToNewCommunity },
      params
    )

  return res.data
}

export async function updateCommunity(community: CommunityEntry) {
  const res: AxiosResponse<GetCustomDomainResponse | ErrorResponse> =
    await axios.put(`${API}/community/${community.id}`, { community }, params)

  return res.data
}

export async function addToken(token: Partial<Token>): Promise<Token> {
  const res: AxiosResponse<Token> = await axios.post(
    `${API}/token`,
    { token },
    params
  )

  // Returns the entire token with the server-generated ID.
  return res.data
}

export async function updateToken(token: Token) {
  const res: AxiosResponse<ErrorResponse | null> = await axios.put(
    `${API}/token/${token.id}`,
    { token },
    params
  )

  return res.data
}

export async function getCommunities(blogId: string) {
  const res: AxiosResponse<Array<CommunityEntryRes>> = await axios.get(
    `${API}/${blogId}/communities`
  )

  return res.data
}

export async function getTokens(blogId: string) {
  const res: AxiosResponse<Array<Token>> = await axios.get(
    `${API}/${blogId}/tokens`
  )

  return res.data
}

export async function getRecommendations(blogId: string) {
  const res: AxiosResponse<BlogRecommendation[] | ErrorResponse> =
    await axios.get(`${API}/${blogId}/recommendations`)

  return res.data
}

export async function updateRecommendations(
  recommendations: RecommendationItem[]
) {
  const res: AxiosResponse<ErrorResponse> = await axios.post(
    `${API}/recommendations`,
    { recommendations },
    params
  )

  return res.data
}

export async function addRecommendation(
  recommendation: Partial<RecommendationModalItem>
) {
  const res: AxiosResponse<ErrorResponse> = await axios.put(
    `${API}/recommendations`,
    { recommendation },
    params
  )

  return res.data
}

export async function removeRecommendation(recommendation: RecommendationItem) {
  const res: AxiosResponse<ErrorResponse> = await axios.delete(
    `${API}/recommendations`,
    { data: { recommendation }, ...params }
  )
  return res.data
}

/**
 * Retrieve blog introduction for a given blogId.
 * This is a public endpoint anybody can use to retrieve a blog's introduction.
 */
export async function getBlogIntroduction(
  blogId: string
): Promise<BlogIntroduction | ErrorResponse | null> {
  const res: AxiosResponse<BlogIntroduction | ErrorResponse | SuccessResponse> =
    await axios.get(`${API}/${blogId}/introduction`)

  console.log("blogIntroduction data API response", { data: res.data })

  if (res.data && "success" in res.data && res.data.success === true) {
    return null
  }

  return res.data
}

/**
 * Create a blog introduction for a given blogId.
 */
export async function createBlogIntroduction(
  blogId: string
): Promise<BlogIntroduction | ErrorResponse> {
  const res: AxiosResponse<BlogIntroduction | ErrorResponse> = await axios.post(
    `${API}/${blogId}/introduction`,
    params // To support impersonation.
  )

  return res.data
}

/**
 * Update a blog introduction for a given blogId.
 */
export async function updateBlogIntroduction(
  blogIntroduction: BlogIntroduction
): Promise<SuccessResponse | ErrorResponse> {
  const res: AxiosResponse<SuccessResponse | ErrorResponse> = await axios.put(
    `${API}/${blogIntroduction.blogId}/introduction`,
    { blogIntroduction },
    params // To support impersonation.
  )

  return res.data
}

/**
 * Delete a blog introduction for a given blogId.
 */
export async function deleteBlogIntroduction(
  blogId: string
): Promise<SuccessResponse | ErrorResponse> {
  const res: AxiosResponse<SuccessResponse | ErrorResponse> =
    await axios.delete(
      `${API}/${blogId}/introduction`,
      params // To support impersonation.
    )

  return res.data
}

import { params, axios } from "./resolve"
import {
  PostNoteResponse,
  Note,
  GetNoteResp,
  SuccessResponse,
  ErrorResponse,
  isErrorResponse,
  ImageWithPlaceholder,
  GetNotePreviewResp,
} from "@types"
import { AxiosResponse } from "axios"
import { getHtml } from "util/tiptap-generate"

const API = "/notes"

export async function saveNote(noteId: string, note: Partial<Note>) {
  //if (!text && !title && !categories && !published) return
  // console.log(
  //   "About to call SAVE NOTE on " + API + "/" + noteId,
  //   note.json,
  //   note.categories
  // )

  // TODO: Remove after implementing generation on API server
  const staticHtml = getHtml(note.json || "")
  console.log("[Editor]: staticHtml", staticHtml)

  const resp: AxiosResponse<ErrorResponse | SuccessResponse> = await axios
    .put(API + `/${noteId}`, { ...note, staticHtml }, params)
    .then((res) => res.data)

  console.log("Got the API response: ", resp)
  return resp
}

export async function sendTestNewsletter(noteId: string): Promise<boolean> {
  const resp = await axios.post(API + `/send-test/${noteId}`, null, params)

  return resp.data
}

export async function newNote(
  draftOf?: Partial<Note>
): Promise<ErrorResponse | GetNoteResp> {
  let res: AxiosResponse<ErrorResponse | PostNoteResponse>

  try {
    if (draftOf) {
      console.log("About to call NEW DRAFT NOTE on " + API)

      res = await axios.post(API, draftOf, params)
    } else {
      console.log("About to call NEW NOTE on " + API)

      res = await axios.post(API, null, params)
    }

    if (isErrorResponse(res.data)) {
      console.error(
        "Error creating new note, inside try: ",
        res.status,
        res.statusText,
        res.data
      )

      return res.data
    }

    return res.data.note
  } catch (e) {
    if (isErrorResponse(e)) {
      console.error("Error creating new note, inside catch: ", e)

      return e
    }

    console.error(
      "Error creating new note, inside catch but error was not of expected type: ",
      { e }
    )

    return {
      msg: `${e}`,
    }
  }
}

export async function getThirdPartyImage(
  imageUrl: string
): Promise<undefined | ImageWithPlaceholder> {
  const res: AxiosResponse<ErrorResponse | { image: ImageWithPlaceholder }> =
    await axios.get(`${API}/thirdPartyImage`, {
      params: { imageUrl },
    })

  if (isErrorResponse(res.data)) {
    console.error("Unable to upload image: ", res.data)
    return
  }

  return res.data.image
}

export async function uploadImage(
  file: File | Blob
): Promise<undefined | ImageWithPlaceholder> {
  const formData = new FormData()

  formData.append("file", file)
  const { blogId } = params.params

  const res: AxiosResponse<ErrorResponse | { image: ImageWithPlaceholder }> =
    await axios.post(`${API}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...{ params: { blogId } },
    })

  if (isErrorResponse(res.data)) {
    console.error("Unable to upload image: ", res.data)
    return
  }

  return res.data.image
}

export async function removeImage(imageUrl: string): Promise<boolean> {
  console.log("Removing image ", imageUrl)
  const res: AxiosResponse<boolean> = await axios.delete(`${API}/image`, {
    data: { imageUrl },
  })

  return res.data
}

// TODO: Handle Error response here
export async function getNote(noteId: string): Promise<GetNoteResp> {
  console.log("About to call GET NOTE on " + API + `/${noteId}`)
  const response: AxiosResponse<GetNoteResp> = await axios.get(
    API + `/${encodeURIComponent(noteId)}`,
    params
  )
  console.log("Amazing, got response!")
  console.log(response.data)
  return response.data
}

export async function deleteNote(noteId: string) {
  if (noteId == "") {
    console.error("Error: Attempt was made to delete a note with no ID.")
    return
  }

  console.log("About to call DELETE NOTE on " + API + `/${noteId}`)
  const response: AxiosResponse<SuccessResponse> = await axios.delete(
    API + `/${noteId}`,
    params
  )
  console.log("Amazing, got response!")
  console.log(response.data)
  return response.data
}

export async function deleteAllNotes() {
  console.log("About to call DELETE ALL NOTES on " + API)

  const response: AxiosResponse<ErrorResponse | SuccessResponse> =
    await axios.delete(API + "/bulk/all", params)

  console.log(response.data)
  return response.data
}

export async function getAllNotes(): Promise<Array<GetNoteResp>> {
  const response: AxiosResponse<Array<GetNoteResp>> = await axios.get(
    API,
    params
  )
  return response.data
}

export async function getLatestNotes(): Promise<Array<GetNoteResp>> {
  const response: AxiosResponse<Array<GetNoteResp>> = await axios.get(
    `${API}/latest`,
    params
  )
  return response.data
}

export async function getLatestNotePreviews(): Promise<
  Array<GetNotePreviewResp>
> {
  const response: AxiosResponse<Array<GetNotePreviewResp>> = await axios.get(
    `${API}/latest/preview`,
    params
  )
  return response.data
}

export async function importNoteExport(
  note: Partial<Note>,
  opts?: {
    overwrite?: boolean
    publish?: boolean
    storeOnArweave?: boolean
  }
) {
  const resp: AxiosResponse<ErrorResponse | { id: string }> = await axios.post(
    API + `/import`,
    { ...note },
    {
      params: {
        ...params.params,
        ...opts,
      },
    }
  )

  // Posts already exists, and overwrite not set, so skipped
  if (resp.status === 409) {
    return {
      id: "ALREADY_EXISTS",
    }
  }

  if (isErrorResponse(resp.data)) {
    return {
      id: "FAILURE",
    }
  }

  console.log("Got the API response: ", resp)
  return {
    id: resp.data.id,
  }
}

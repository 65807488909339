import {
  Blog,
  BlogSubscriptionAndMembership,
  Membership,
  Plan,
  Subscription,
} from "@types"
import { Dispatch, SetStateAction } from "react"
import { Frequency, newEmptyTier } from "./SharedTypes"
import { EnrichedGatingRuleGroup } from "@/types/gatingRules"
import { getSubscriptions, subscribeToMembership } from "api_routes/memberships"
import {
  getAllUsersSubscriptions,
  getUsersSubscriptionToBlog,
} from "features/userSlice"
import { User } from "@sentry/nextjs"
import { StripePaymentMethodObj } from "@/types/stripe"

function handleCreateNewTier(
  creatingId: string,
  setCreatingId: Dispatch<SetStateAction<string | undefined>>,
  tiers: Membership[],
  setTiers: Dispatch<SetStateAction<Membership[]>>
) {
  setCreatingId(creatingId)

  setTiers([...tiers, { ...newEmptyTier, id: creatingId }])
}

export function isFreeMembership(membership: Membership) {
  return (
    membership.plans.length == 0 || membership.plans.every((p) => p.price == 0)
  )
}

export function hasPaidMemberships(memberships?: Membership[]) {
  if (!memberships) return false
  return memberships.some((m) => !isFreeMembership(m))
}

export function subscriptionIsPaid(
  subscription?: Subscription,
  memberships?: Membership[]
) {
  return memberships?.find(
    (m) => m.id === subscription?.membershipId && !isFreeMembership(m)
  )
}

export function hasTokenGates(
  gatingRuleGroups: EnrichedGatingRuleGroup[] | undefined
) {
  return gatingRuleGroups?.some((group) =>
    group.gatingRules.some((gr) => gr.gatingRequirement.gateType === "TOKEN")
  )
}

export function isSubscribedToThisMembershipAndFrequency(
  frequency: Frequency,
  subscription?: Subscription,
  membership?: Membership
): boolean {
  const isSubscribedToThisMembership =
    subscription?.membershipId === membership?.id

  // If this is a free membership and we're actively subscribed to it, there is no concept of a frequency so just return true.
  if (
    membership &&
    subscription?.status === "ACTIVE" &&
    isSubscribedToThisMembership &&
    isFreeMembership(membership)
  )
    return true

  const frequencySubscribedAt = getMembershipSubscribedToFrequency(
    subscription,
    membership
  )

  return (
    isSubscribedToThisMembership &&
    frequencySubscribedAt !== undefined &&
    frequency.id === frequencySubscribedAt.period
  )
}

export function getMembershipSubscribedToFrequency(
  subscription?: Subscription,
  membership?: Membership
): Plan | undefined {
  const frequencySubscribedAt = membership?.plans.find(
    (p) =>
      p.stripePriceId === subscription?.planId ||
      p.loopItemId === subscription?.planId
  )

  return frequencySubscribedAt
}

/**
 * Poll for subscription status until it's updated.
 * Once we've found a subscription that matches the membershipId, we return it.
 * This is necessary since the subscription is created asynchronously via a Stripe webhook.
 * @param blogId ID of the blog we're subscribing to.
 * @param membershipId ID of the membership we're subscribing to.
 * @param dispatch Redux dispatch function.
 * @returns The subscription that matches the membershipId, once loaded.
 */
export async function pollForSubscriptionCreationStatus(
  blogId: string,
  membershipId: string,
  dispatch: any
) {
  const predicate = (s: BlogSubscriptionAndMembership) => {
    return (
      s.subscription.membershipId === membershipId &&
      s.subscription.status === "ACTIVE"
    )
  }

  return pollForSubscriptionGeneric(
    blogId,
    dispatch,
    predicate,
    "pollForSubscriptionCreationStatus"
  )
}

/**
 * Poll for subscription status until it's updated.
 * Once we've found a subscription that matches the membershipId, we return it.
 * This is necessary since the subscription is created asynchronously via a Stripe webhook.
 * @param blogId ID of the blog we're subscribing to.
 * @param membershipId ID of the membership we're subscribing to.
 * @param dispatch Redux dispatch function.
 * @returns The subscription that matches the membershipId, once loaded.
 */
export async function pollForSubscriptionCancellationStatus(
  blogId: string,
  membershipId: string,
  dispatch: any
) {
  const predicate = (s: BlogSubscriptionAndMembership) => {
    return (
      s.subscription.membershipId === membershipId &&
      (("toBeUnsubscribedAt" in s.subscription &&
        s.subscription.toBeUnsubscribedAt !== undefined) ||
        s.subscription.status !== "ACTIVE")
    )
  }

  return pollForSubscriptionGeneric(
    blogId,
    dispatch,
    predicate,
    "pollForSubscriptionCancellationStatus"
  )
}

/**
 * Poll for subscription status until it's updated.
 * Once we've found a subscription that matches the membershipId, we return it.
 * This is necessary since the subscription is created asynchronously via a Stripe webhook.
 * @param blogId ID of the blog we're subscribing to.
 * @param membershipId ID of the membership we're subscribing to.
 * @param dispatch Redux dispatch function.
 * @returns The subscription that matches the membershipId, once loaded.
 */
export async function pollForSubscriptionReactivationStatus(
  blogId: string,
  membershipId: string,
  dispatch: any
) {
  const predicate = (s: BlogSubscriptionAndMembership) => {
    return (
      s.subscription.membershipId === membershipId &&
      (!("toBeUnsubscribedAt" in s.subscription) ||
        s.subscription.toBeUnsubscribedAt === undefined)
    )
  }

  return pollForSubscriptionGeneric(
    blogId,
    dispatch,
    predicate,
    "pollForSubscriptionReactivationStatus"
  )
}

/**
 * Poll for subscription status until it's updated.
 * Once we've found a subscription that matches the membershipId, we return it.
 * This is necessary since the subscription is created asynchronously via a Stripe webhook.
 * This is the generic function that does most of the work. The other functions are just thin wrappers
 * that alter the conditions passed into this function.
 * @param blogId ID of the blog we're subscribing to.
 * @param dispatch Redux dispatch function.
 * @param predicate The predicate to use to find the subscription.
 * @param functionName The name of the function calling this function.
 * @returns The subscription that matches the membershipId, once loaded.
 */
async function pollForSubscriptionGeneric(
  blogId: string,
  dispatch: any,
  predicate: any,
  functionName: string
) {
  let allSubscriptions: BlogSubscriptionAndMembership[] = []
  let subscription: BlogSubscriptionAndMembership | undefined

  while (subscription === undefined) {
    console.log(`${functionName}: Polling for subscription status...`)
    await new Promise((resolve) => setTimeout(resolve, 1000))

    allSubscriptions = await getSubscriptions()
    subscription = allSubscriptions.find((s) => predicate(s))
  }

  console.log(`${functionName}: Sub status updated!`, subscription)

  // Now that we've gotten subscription status, let's save it the latest subscriptions in Redux!
  await dispatch(getAllUsersSubscriptions())
  // And let's refresh the user, as their subscription is used in many places fetching from Redux.
  await dispatch(getUsersSubscriptionToBlog(blogId))

  return subscription
}

export async function handleMembershipCheckoutForExistingPaymentMethod(
  selectedPlan: Plan,
  blog: Blog,
  noteId: string | null,
  user: User,
  membership: Membership,
  coupon: string | null,
  dispatch: any,
  successCallback?: () => void,
  paymentMethod?: StripePaymentMethodObj
) {
  if (!selectedPlan.stripePriceId) return

  console.log("Buying membership", blog.id, user.id, membership, coupon)

  await subscribeToMembership(
    blog.id,
    noteId,
    "",
    membership.id,
    selectedPlan.period ? "stripe-subscription" : "stripe-payment",
    selectedPlan.stripePriceId,
    coupon,
    paymentMethod
  )

  await pollForSubscriptionCreationStatus(blog.id, membership.id, dispatch)

  successCallback?.()
}

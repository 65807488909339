import { configureStore, Action } from "@reduxjs/toolkit"
import notesSlice from "./features/noteSlice"
import pageSlice from "./features/pageSlice"
import blogSlice from "./features/blogSlice"
import userSlice from "./features/userSlice"
import errorSlice from "./features/errorSlice"
import { ThunkAction } from "redux-thunk"
import { useDispatch } from "react-redux"
import { createWrapper } from "next-redux-wrapper"
import commentSlice from "features/commentSlice"
import notificationSlice from "features/notificationSlice"
import jobSlice from "features/jobSlice"
import gatingRulesSlice from "features/gatingRuleGroupsSlice"
import membershipSlice from "features/membershipSlice"

const _store = configureStore({
  reducer: {
    notes: notesSlice,
    page: pageSlice,
    blog: blogSlice,
    user: userSlice,
    comments: commentSlice,
    error: errorSlice,
    notifications: notificationSlice,
    jobs: jobSlice,
    gatingRuleGroups: gatingRulesSlice,
    memberships: membershipSlice,
  },
  devTools: process.env.VERCEL_ENV !== "production",
})

const makeStore = () => _store

type AppStore = ReturnType<typeof makeStore>

// TODO: Rewrite dispatch with useAppDispatch everywhre
export type AppDispatch = AppStore["dispatch"]
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export type RootState = ReturnType<AppStore["getState"]>
type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
// TODO: Replace everything that uses AppThunk with Thunk, to allow returning
// actual values.
export type Thunk<R = void> = ThunkAction<R, RootState, undefined, Action>

export const wrapper = createWrapper<AppStore>(makeStore)

import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import { getCollectibleImageUrl } from "@/util/crypto"
import clsx from "clsx"

import Image from "next/image"
import { useState } from "react"
import { HighlightLoader as Placeholder } from "components/Placeholder"
import { selectCurrentNote } from "features/noteSlice"
import { CollectibleType } from "@/types/collectibles"

// NOTE: This needs to match the exact signature
// of the generated NFT on the backend in routes/crypto; otherwise,
// users will view a different image than what they'll collect!
export default function CollectibleImage({
  text,
  collectibleType,
  placeholderSize = 240,
}: {
  text: string
  collectibleType: CollectibleType
  placeholderSize?: number
}) {
  const blog = useSelector(selectBlog)
  const [loaded, setLoaded] = useState(false)
  const note = useSelector(selectCurrentNote)

  let path

  if (text) {
    path = getCollectibleImageUrl({
      post: note,
      text: text,
      blog,
      blogOwnerUser: blog.user,
      collectibleType: collectibleType,
    })
  }

  return (
    <div>
      <div className={clsx(loaded ? "hidden" : "block", "w-full")}>
        <Placeholder width={placeholderSize} height={placeholderSize} />
      </div>
      {path && (
        <Image
          onLoadingComplete={() => setLoaded(true)}
          unoptimized
          className={clsx(
            loaded ? "opacity-100" : "opacity-0 w-0",
            "border border-gray-200 shadow-md rounded-lg "
          )}
          width={300}
          height={300}
          src={path}
          alt="highlight"
        />
      )}
    </div>
  )
}

import { useEffect, useState } from "react"
import { useEnsName } from "wagmi"

type WalletNameType = "WALLET_ADDRESS" | "ENS"

type WalletNameAndType = {
  name: string
  type: WalletNameType
}

type CryptoAddress = `0x${string}`

// Returns an ENS name if available, and if not, the wallet address.
export default function useWalletName(
  walletAddress: string
): WalletNameAndType {
  const {
    data: ensName,
    isError,
    isSuccess,
    isLoading,
  } = useEnsName({
    address: walletAddress as CryptoAddress,
    chainId: 1,
  })

  const [walletName, setWalletName] = useState<WalletNameAndType>(
    // Default to wallet address.
    { name: walletAddress, type: "WALLET_ADDRESS" }
  )

  // Needs to be done client-side to prevent
  // nextJS hydration issues.
  useEffect(() => {
    if (!isSuccess || !ensName) return

    // If we find it, then set ENS name.
    setWalletName({ name: ensName, type: "ENS" })
  }, [walletAddress, ensName, isError, isSuccess, isLoading])

  return walletName
}

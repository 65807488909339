"use client"

import { CompletedCollectible } from "@/types/collectibles"
import CollectExistingCollectibleBtn from "./CollectExistingCollectibleBtn"
import RainbowConnectWalletBtn from "@/components/ConnectWalletButton"
import useExistingHighlightMetadata from "hooks/collectibles/useExistingHighlightMetadata"
import BasePopover from "../BasePopover"
import { BigNumber } from "ethers"
import useCollectors from "hooks/collectibles/useCollectors"
import { CollectibleAvatars } from "components/public/BlogTheme/AuthorAvatars"
import useUser from "hooks/useUser"
import Button from "components/Button"

import { openseaUrlFromCollectible } from "util/format"
import MintFeeText from "../MintFeeText"
import { AvatarLoader } from "components/Placeholder"
import { FiExternalLink } from "react-icons/fi"
import { User } from "@/types/users"
import { useState } from "react"
import CollectorModal from "components/Collectibles/CollectorModal"

// Display fake collectors when loading the real collectors avatars.
const FakeCollectors = [{}, {}, {}] as User[]

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function ViewNftBtn(props: { highlight: CompletedCollectible }) {
  return (
    <a
      href={openseaUrlFromCollectible(props.highlight)}
      target="_blank"
      className="flex-none"
    >
      <Button replaceClassName="bg-white justify-self-center align-middle focus:ring-primary-500 justify-center px-4 py-2 text-sm font-medium text-gray-500 border-gray-200 border shadow-sm rounded-md focus:outline-none focus:ring-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 w-full flex flex-row justify-center items-center">
        View NFT
        <FiExternalLink className="ml-2" />
      </Button>
    </a>
  )
}

export default function ExistingCollect(props: {
  highlight: CompletedCollectible
}) {
  const metricData = useExistingHighlightMetadata({
    collectible: props.highlight,
  })

  const [modalOpen, setModalOpen] = useState(false)

  const { walletReadyAndLoggedIn } = useUser()

  const { userAlreadyOwns, remainingSupply, supply } = metricData

  const type = props.highlight.type === "POST" ? "post" : "highlight"
  const collectors = useCollectors(props.highlight, remainingSupply.toString())
  const numCollectors = BigNumber.from(supply).toNumber() || collectors.length

  const mintedOut = BigNumber.from(remainingSupply).eq(0)

  console.log("COLLECTORS AND NUM", collectors, numCollectors)

  const combinedAndFakeCollectors = collectors
    .concat(FakeCollectors)
    .slice(0, Math.min(4, numCollectors))

  const Body = (
    <>
      <p className="text-gray-500 mt-3 text-center">
        {mintedOut ? (
          "This is sold out. You can collect it on a secondary marketplace."
        ) : userAlreadyOwns ? (
          <>
            You've collected this on {capitalizeFirstLetter(metricData.chain)}.
          </>
        ) : (
          <>
            Collect this {type} to permanently own it on{" "}
            {capitalizeFirstLetter(metricData.chain)}.
          </>
        )}
      </p>

      {numCollectors > 0 ? (
        <div
          className="flex items-center justify-center flex-row "
          onClick={() => setModalOpen(true)}
        >
          <CollectibleAvatars
            users={combinedAndFakeCollectors as User[]}
            color={"light"}
          />

          <div className="text-xs text-gray-500">
            {numCollectors} {numCollectors === 1 ? "collector" : "collectors"}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-row h-[56px]">
          <AvatarLoader />
        </div>
      )}

      {/*
      <div className="flex justify-center align-middle py-2">
        <SocialIcon
          Icon={FaEthereum}
          href={`https://etherscan.io/token/${props.highlight.contractAddress}`}
        />
        </div> */}
    </>
  )

  let FooterInner = null

  if (mintedOut) {
    FooterInner = (
      <a
        href={openseaUrlFromCollectible(props.highlight)}
        target="_blank"
        rel="noreferrer"
      >
        <Button text="Collect on secondary" />
      </a>
    )
  } else if (walletReadyAndLoggedIn) {
    FooterInner = (
      <>
        <div className="flex flex-col space-y-3">
          <CollectExistingCollectibleBtn
            highlight={props.highlight}
            btnText="Collect"
          />
          <ViewNftBtn highlight={props.highlight} />
        </div>

        <div className="mt-6">
          <MintFeeText
            collectibleVersion={props.highlight.version}
            chain={metricData.chain}
          />
        </div>
      </>
    )
  } else {
    FooterInner = (
      <div className="flex justify-center flex-col w-full">
        <div className=" flex flex-col space-y-3 w-full">
          <RainbowConnectWalletBtn text="Connect wallet" />
          <ViewNftBtn highlight={props.highlight} />
        </div>

        <MintFeeText
          collectibleVersion={props.highlight.version}
          chain={metricData.chain}
        />
      </div>
    )
  }

  const Footer = <div className="flex flex-col">{FooterInner}</div>

  return (
    <>
      <BasePopover
        body={Body}
        footer={Footer}
        imageText={props.highlight.text}
        metricData={metricData}
        collectibleType={props.highlight.type}
      />
      <CollectorModal open={modalOpen} setOpen={setModalOpen} />
    </>
  )
}

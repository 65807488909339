import { Dispatch, SetStateAction } from "react"
import Button from "./Button"
import clsx from "clsx"

type Props = {
  showDialogue: boolean
  setShowDialogue: Dispatch<SetStateAction<boolean>>
  /**
   * Whether the action is destructive (e.g. "Delete" vs "Checkout").
   * Destructive actions are red, non-destructive actions are blue.
   */
  isDestructiveAction: boolean
  /**
   * Called when the user clicks "Confirm" on the confirmation dialogue.
   * @returns Void.
   */
  onConfirmCallback: () => void
  /**
   * Called when the user clicks "Cancel" on the confirmation dialogue.
   * @returns Void.
   */
  onCancelCallback?: () => void
  /**
   * Optional custom text to display in the confirmation dialogue.
   */
  customPromptText?: string
  /**
   * Optional custom styling to apply to the text in the confirmation dialogue.
   */
  customPromptTextStyling?: string
  /**
   * Optional custom text to display on the "Confirm" button.
   */
  customConfirmButtonText?: string
  /**
   * Optional custom styling to apply to the "Confirm" button.
   */
  customConfirmButtonStyling?: string
  /**
   * Optional custom text to display on the "Cancel" button.
   */
  customCancelButtonText?: string
  /**
   * Optional custom styling to apply to the "Cancel" button.
   */
  customCancelButtonStyling?: string
  confirmTrackingData?: object | undefined
  cancelTrackingData?: object | undefined
  isDisabled?: boolean
}

export default function AreYouSureConfirmationDialogue(props: Props) {
  if (!props.showDialogue) {
    return null
  }

  const handleOnClickYes = () => {
    // Do whatever the parent wants us to do.
    props.onConfirmCallback()

    // And then close the confirmation dialogue.
    props.setShowDialogue(false)
  }

  const handleOnClickNo = () => {
    // Do whatever the parent wants us to do.
    props.onCancelCallback?.()

    // And then close the confirmation dialogue.
    props.setShowDialogue(false)
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {props.customPromptText && (
        <span
          className={clsx(
            "mb-2 dark-text-title",
            props.customPromptTextStyling
          )}
        >
          {props.customPromptText}
        </span>
      )}
      <div className="flex justify-items-center items-center">
        <Button
          type="button"
          text={props.customCancelButtonText || "Cancel"}
          disabled={props.isDisabled}
          onClick={handleOnClickNo}
          trackingData={props.cancelTrackingData}
          replaceClassName={clsx(
            "dark-btn-cancel inline-flex justify-center rounded-md px-4 py-2 text-base font-medium text-gray-900 border border-gray-150 hover:border-gray-300 hover:text-gray-700 shadow-sm sm:text-sm cursor-pointer",
            props.customCancelButtonStyling
              ? props.customCancelButtonStyling
              : "w-18"
          )}
        />
        <Button
          type="button"
          text={props.customConfirmButtonText || "Confirm"}
          disabled={props.isDisabled}
          onClick={handleOnClickYes}
          trackingData={props.confirmTrackingData}
          replaceClassName={clsx(
            "ml-2 inline-flex justify-center rounded-md px-4 py-2 text-base font-medium text-white hover:text-white shadow-sm sm:text-sm cursor-pointer",
            props.isDestructiveAction
              ? "bg-red-600 hover:bg-red-500"
              : "bg-primary",
            props.customConfirmButtonStyling
              ? props.customCancelButtonStyling
              : "w-18"
          )}
        />
      </div>
    </div>
  )
}

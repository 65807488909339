import { PendingCollectible } from "@/types/collectibles"

import { parseEther } from "viem"

import BasePopover from "../BasePopover"

export default function PendingCollectibleCollect(props: {
  pendingCollectible: PendingCollectible
}) {
  const Body = (
    <>
      <p className="text-gray-500 mt-3">Collecting...</p>
    </>
  )

  const Footer = <p className="text-gray-500 mt-2 text-sm">Waiting...</p>

  return (
    <BasePopover
      body={Body}
      footer={Footer}
      imageText={props.pendingCollectible.text}
      collectibleType={props.pendingCollectible.type}
      metricData={{
        maxSupply: BigInt(props.pendingCollectible.supply),
        remainingSupply: BigInt(props.pendingCollectible.supply),
        priceWei: parseEther(props.pendingCollectible.costEth.toString()),
        chain: props.pendingCollectible.chain,
      }}
    />
  )
}

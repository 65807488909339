import { CHAINS } from "@/types/collectibles"

import { useAccount } from "wagmi"
import { getChainId } from "@/util/crypto"

/**
 * Determines if we're on the right chain or not, given a desired chain.
 *
 * @param props.chain The desired chain.
 */
export default function useOnRightChain(props: { chain: CHAINS }) {
  const network = useAccount()
  const desiredChainId = getChainId(props.chain)
  const onRightChain = network.chain?.id === desiredChainId

  return { onRightChain, currentChainId: network.chain?.id, desiredChainId }
}

import { axios, baseUrl } from "./resolve"
import { AxiosResponse } from "axios"
import type {
  Collectible,
  CompletedCollectible,
  EnrichedCollectRecord,
  ExtendedCollectible,
  PendingCollectible,
  UnsavedCollectible,
} from "@/types/collectibles"
import { ErrorResponse, isErrorResponse } from "@types"

const API = baseUrl + "/collectibles"

// This is creating a pending highlight (not necessarily complete).
export async function addOrUpdateCollectible(
  body: UnsavedCollectible | CompletedCollectible | PendingCollectible
) {
  const res: AxiosResponse<{ success: boolean; id: string } | ErrorResponse> =
    await axios.post(`${API}`, body)

  return res.data
}

type getCollectorsResponse = {
  total: number
  records: Array<EnrichedCollectRecord>
}

/**
 * Fetches numToFetch avatars of collectors.
 *
 * Note that this does NOT fetch the full collector records, just the avatars.
 *
 */
export async function getCollectorsOverview(
  collectibleId: string,
  numAvatarsToFetch?: number
): Promise<getCollectorsResponse> {
  const res: AxiosResponse<getCollectorsResponse | ErrorResponse> =
    await axios.get(`${API}/collectors/overview/${collectibleId}`, {
      params: { size: numAvatarsToFetch || 5 },
    })

  if (isErrorResponse(res.data)) {
    console.error("Error getting collectors", res)
    return { total: 0, records: [] }
  }

  return res.data
}

/**
 * This fetches an enriched list of post collectors.
 */
export async function getCollectors(
  collectibleId: string,
  numToFetch: number,
  createdAtCursor?: number,
  blogIdContext?: string
): Promise<Array<EnrichedCollectRecord>> {
  const res: AxiosResponse<Array<EnrichedCollectRecord> | ErrorResponse> =
    await axios.get(`${API}/collectors/${collectibleId}`, {
      params: { limit: numToFetch, createdAtCursor, blogIdContext },
    })

  if (isErrorResponse(res.data)) {
    console.error("Error getting collectors", res)
    return []
  }

  return res.data
}

export async function updateHighlight(id: string) {
  const res: AxiosResponse<{ success: boolean } | ErrorResponse> =
    await axios.put(`${API}/${id}`)

  return res.data
}

export async function getHighlights(noteId: string) {
  const res: AxiosResponse<Array<ExtendedCollectible>> = await axios.get(
    `${API}/notes/${noteId}`
  )

  if (!Array.isArray(res.data)) {
    return []
  }

  return res.data
}

export async function getPostCollectible(noteId: string) {
  const res: AxiosResponse<Collectible | Record<string, never>> =
    await axios.get(`${API}/notes/${noteId}/postcollectible`)

  return res.data
}

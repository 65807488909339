import { TweetSyndication } from "@/types/twitter"
import Twemoji from "twemoji"

export const constructHtml = (
  data: TweetSyndication,
  isStatic = false,
  isQuotedTweet = false
) => {
  try {
    const {
      meta,
      html: content,
      user,
      media_html,
      card_html,
      quoted_tweet,
    } = getTweetContents(data, isStatic)
    const quoted_html = getQuotedHtml(quoted_tweet as any, isStatic)
    const tweet_class = isQuotedTweet
      ? "twitter-quoted twitter-embed"
      : "twitter-embed embed"
    const favorite_count_str = formatFavorites(meta.favorite_count)

    const userUrl = `https://twitter.com/${user.screen_name}`
    const tweetUrl = `${userUrl}/status/${data.id_str}`

    const html = ` 
  <div class="${tweet_class}">
    <div class="twitter-header">
        <div style="display:flex">
          <a target="_blank" href="${userUrl}">
              <img alt="User Avatar" class="twitter-avatar" src="${
                user.profile_image_url_https
              }" />
            </a>
            <div style="margin-left:4px;margin-right:auto;line-height:1.2;">
              <a target="_blank" href="${userUrl}" class="twitter-displayname">${
                user.name
              }</a>
              <p><a target="_blank" href="${userUrl}" class="twitter-username">@${
                user.screen_name
              }</a></p>
    
            </div>
            <a href="${tweetUrl}" target="_blank">
              <img alt="Twitter Logo" class="twitter-logo" src="https://paragraph.xyz/editor/twitter/logo.png" />
            </a>
          </div>
        </div>
      
    <div class="twitter-body">
      ${content}
      ${media_html}
      ${card_html}
      ${quoted_html} 
    </div>
    ${
      !isQuotedTweet
        ? `
     <div class="twitter-footer">
          <a target="_blank" href="${tweetUrl}" style="margin-right:16px; display:flex;">
            <img alt="Like Icon" class="twitter-heart" src="https://paragraph.xyz/editor/twitter/heart.png">
            ${favorite_count_str}
          </a>
          <a target="_blank" href="${tweetUrl}"><p>${new Date(
            meta.created_at
          ).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          })} • ${new Date(meta.created_at).toLocaleDateString([], {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}</p></a>
        </div>
    `
        : ""
    }
  </div> 
  `
    return { html, meta }
  } catch (err) {
    throw err
  }
}

export const getTweetContents = (data: TweetSyndication, isStatic: boolean) => {
  try {
    const {
      display_text_range,
      user,
      card,
      entities,
      text,
      quoted_tweet,
      photos,
      video,
    } = data
    let html = text?.substr(display_text_range[0])

    const meta = {
      user_id: user.id_str,
      name: user.name,
      screen_name: user.screen_name,
      verified: user.verified,
      profile_image_url_https: user.profile_image_url_https,
      url: "https://twitter.com/" + user.screen_name + "/status/" + data.id_str,
      profile_url: "https://twitter.com/" + user.screen_name,
      created_at: data.created_at,
      favorite_count: data.favorite_count,
      conversation_count: data.conversation_count,
    }

    // Parse Links
    const linkClass = "twitter-content-link"
    entities.urls?.forEach((i) => {
      let linkIndex = 0
      // Find first index of unhandled instance of i.url
      while (linkIndex !== -1) {
        linkIndex = html.indexOf(i.url, linkIndex)
        if (html.substring(linkIndex - 6, linkIndex - 1) === "href=")
          linkIndex++
        else break
      }
      // Replace i.url with link
      const link = i.display_url.includes("twitter.com")
        ? ""
        : `<a class="${linkClass}" href="${i.url}" target="_blank">${i.display_url}</a>`
      html =
        html.substring(0, linkIndex) +
        link +
        html.substring(linkIndex + i.url.length)
    })

    // Parse Media
    entities.media?.forEach((i) => {
      html = html.replace(i.url, "")
    })

    // Parse Hashtags
    entities.hashtags?.forEach((i) => {
      html = html.replace(
        `#${i.text}`,
        `<a class="${linkClass}" href="https://twitter.com/hashtag/${i.text}" target="_blank">#${i.text}</a>`
      )
    })

    // Parse User Mentions
    entities.user_mentions?.forEach((i) => {
      html = html.replace(
        `@${i.screen_name}`,
        `<a class="${linkClass}"  href="https://twitter.com/${i.screen_name}" target="_blank">@${i.screen_name}</a>`
      )
    })

    // Replace new lines with breaks
    html = html.replace(/\n/g, "<br />")

    // Parse Emojis
    html = Twemoji.parse(html, {
      ext: ".png",
      className: "twitter-emoji",
    })

    // Rewrite emoji image URLs do to new Twitter URL format
    html = html.replace(
      /\/\/twemoji.maxcdn.com\/v\/14.0.2\/72x72\/(.*?).png/g,
      "//abs-0.twimg.com/emoji/v2/72x72/$1.png"
    )

    let card_html = ""
    const mediaClass = "twitter-media"

    // Handle large card
    if (card?.name === "summary_large_image" && isValidCard(card)) {
      // https://regex101.com/r/CIYzl5/1
      const re = new RegExp(
        `(<br \/>)?<a [^<]* href="${card.url}"[^<]*<\/a>$`,
        "gi"
      )
      html = html.replace(re, "")
      card_html = `
        <a class="twitter-card-link" href="${card.url}" target="_blank">
          <div class="${mediaClass} twitter-summary-large-image">
            <img src="${card.binding_values.thumbnail_image_large.image_value.url}" >
            <div class="twitter-summary-card-text">
              <span>${card.binding_values.vanity_url.string_value}</span>
              <h2>${card.binding_values.title.string_value}</h2>
              <p>${card.binding_values.description.string_value}</p>
            </div>
          </div>
        </a>`
    }

    // Handle normal card
    if (card?.name === "summary" && isValidCard(card)) {
      // https://regex101.com/r/CIYzl5/1
      const re = new RegExp(
        `(<br \/>)?<a [^<]* href="${card.url}"[^<]*<\/a>$`,
        "gi"
      )
      html = html.replace(re, "")
      card_html = `
        <a class="twitter-card-link" href="${card.url}" target="_blank">
          <div class="${mediaClass} twitter-summary">
            <img src="${card.binding_values.thumbnail_image_large.image_value.url}" >
            <div class="twitter-summary-card-text">
              <span>${card.binding_values.vanity_url.string_value}</span>
              <h2>${card.binding_values.title.string_value}</h2>
              <p>${card.binding_values.description.string_value}</p>
            </div>
          </div>
        </a>`
    }

    // Insert media
    let media_html = ""
    if (photos) {
      media_html = `<div class="${mediaClass}">`
      if (photos.length === 2) media_html += '<div class="twitter-two-images">'
      if (photos.length === 3)
        media_html += '<div class="twitter-three-images">'
      if (photos.length === 4) media_html += '<div class="twitter-four-images">'
      photos.map((photo, i) => {
        // Note: Photo grids aren't supported in newsletter so only include first image
        if (isStatic && i !== 0) return
        media_html += `<img class="twitter-image" src="${photo.url}" />`
      })
      if (photos.length > 1) media_html += "</div>"
      media_html += `</div>`
    }
    if (video) {
      const mp4 = video.variants.find((i) => i.type === "video/mp4")
      media_html = !isStatic
        ? `
    <div class="${mediaClass}">
      <video controls muted loop src="${mp4?.src}"></video> 
    </div>`
        : `<div class="${mediaClass}">
      <img class="twitter-image" src="${video.poster}"> 
    </div>`
    }

    return {
      meta,
      html,
      user,
      card_html,
      media_html,
      quoted_tweet,
    }
  } catch (err) {
    throw err
  }
}

// Handle quote tweet
const getQuotedHtml = (data: TweetSyndication, isStatic: boolean): string => {
  if (!data) return ""

  const url = `https://twitter.com/${data.user.screen_name}/status/${data.id_str}`
  return `<div class="twitter-quoted">
       ${constructHtml(data, isStatic, true).html} 
    </div>`
}

export const formatFavorites = (favorite_count: number) => {
  let favorite_count_str
  if (favorite_count >= 1000000) {
    favorite_count_str = (favorite_count / 1000000).toFixed(1) + "m"
  } else if (favorite_count >= 10000) {
    favorite_count_str = (favorite_count / 1000).toFixed(1) + "K"
  } else {
    favorite_count_str = favorite_count?.toLocaleString("en-US")
  }
  favorite_count_str = favorite_count_str.replace(".0", "")
  return favorite_count_str
}

const isValidCard = (card: any) => {
  if (
    card.binding_values?.thumbnail_image_large?.image_value?.url &&
    card.binding_values?.vanity_url?.string_value &&
    card.binding_values?.title?.string_value &&
    card.binding_values?.description?.string_value
  )
    return true
  return false
}

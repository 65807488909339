import { CompletedCollectible } from "@/types/collectibles"
import { erc721ABI } from "@/util/typechain"

import { useAccount } from "wagmi"
import { useSelector } from "react-redux"
import { selectBlog } from "features/blogSlice"
import { parseEther, zeroAddress } from "viem"
import { useReaderReferrer } from "hooks/useReferrer"
import useContractInteraction from "../useContractInteraction"

export default function useMintExistingCollectibleV1(
  collectible: CompletedCollectible
) {
  const enabled = collectible.version === 1
  const accountData = useAccount()
  const blog = useSelector(selectBlog)
  const referrer = useReaderReferrer(blog.id)
  const collectibleCostWei = parseEther(collectible.costEth.toString())

  const contractArgs = [
    accountData.address as `0x${string}`,
    referrer ? referrer : zeroAddress,
  ]

  return useContractInteraction({
    functionName: "mintWithReferrer",
    address: collectible.contractAddress as "0x${string}",
    collectibleCostWei,
    abi: erc721ABI,
    chain: collectible.chain,
    args: contractArgs,
    enabled,
    collectible,
  })
}

import { mergeAttributes, Node, nodePasteRule } from "@tiptap/core"
import { nodeInputRule } from "@tiptap/react"
import { Plugin } from "@tiptap/pm/state"
import { isPlaceholderImage } from "../Image/constants"

// Markdown image input regex
const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/
// https://regex101.com/r/XoAXXB/1
const GIPHY_REGEX_GLOBAL = /^(https?:\/\/)?media\.giphy\.com\/media\/.*\.gif$/g

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    figure: {
      setFigure: (options: {
        src?: string
        file?: File
        alt?: string | undefined
        title?: string
        blurDataURL?: string
        float?: "left" | "none" | "right"
        width?: string
        caption?: string
      }) => ReturnType
      setFloat: (value: "left" | "right" | "none") => ReturnType
      setWidth: (value: string) => ReturnType
    }
  }
}

export default Node.create({
  name: "figure",
  group: "block",
  content: "block figcaption",
  draggable: true,
  priority: 999,

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      float: {
        default: "none",
        parseHTML: (element) => {
          const classNames = element.getAttribute("class")
          if (!classNames) return "none"
          if (classNames.includes("img-float-left")) return "left"
          if (classNames.includes("img-float-right")) return "right"
          return "none"
        },
      },
      width: {
        default: null,
        parseHTML: (element) => element.getAttribute("width"),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `figure`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    let floatStyle = "img-center"
    if (HTMLAttributes.float === "left") floatStyle = "img-float-left"
    else if (HTMLAttributes.float === "right") floatStyle = "img-float-right"

    const className = {
      class: `${floatStyle}`,
      style: `max-width: ${HTMLAttributes.width};`,
    }

    return [
      "figure",
      mergeAttributes(HTMLAttributes, { "data-type": this.name }, className),
      0,
    ]
  },

  addCommands() {
    return {
      setFigure:
        (attrs) =>
        ({ chain }) => {
          return chain()
            .insertContent({
              type: this.name,
              attrs: { float: attrs.float, width: attrs.width },
              content: [
                { type: "image", attrs: { src: attrs.src } },
                {
                  type: "figcaption",
                  content: attrs.caption
                    ? [{ type: "text", text: attrs.caption }]
                    : [],
                },
              ],
            })
            .run()
        },
      setFloat:
        (float) =>
        ({ commands }) => {
          return commands.updateAttributes("figure", {
            float,
            width: float === "left" || float === "right" ? "50%" : "100%",
          })
        },
      setWidth:
        (width) =>
        ({ commands }) => {
          return commands.updateAttributes("figure", {
            width,
          })
        },
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match
          return { src, alt, title }
        },
      }),
    ]
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: GIPHY_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match) => {
          console.log("GIPHY: Match ", match)
          const src = match[0]
          return { src }
        },
      }),
    ]
  },

  addKeyboardShortcuts() {
    return {
      // When in figure and user hits enter -> create a paragraph node after figure & select it
      Enter: () => {
        if (
          this.editor.state.selection.$anchor.parent.type.name === this.name
        ) {
          const nodePos = this.editor.state.selection.$anchor.pos
          // @ts-ignore
          const nodeSize = this.editor.state.selection.node.nodeSize

          this.editor
            .chain()
            // Note: Originally I was inserting a paragraph but couldn't get it focused.
            //       Focusing right after the figure automatically creates and selects a
            //       trailing paragraph
            .focus(nodePos + nodeSize)
            .run()
          this.editor.commands.setTextSelection(nodePos + nodeSize + 1)
        }

        return false
      },
      // Forbid Delete key because it doesn't work well in Tiptap
      // Delete: () => {
      //   return true
      // },
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          const tr = newState.tr
          let modified = false
          newState.doc.descendants((node, pos, _parent) => {
            // Delete the entire figure node if imageNode is empty
            if (node.type.name === "figure") {
              const imageNode = node.firstChild
              if (
                !imageNode ||
                (!imageNode.attrs.src && !imageNode.attrs.href)
              ) {
                tr.delete(pos, pos + node.nodeSize)
                modified = true
              }
            }
            // Put image in figure if not in one already (web paste or web d&d)
            if (node.type.name === "image") {
              if (
                _parent?.type.name !== "figure" &&
                _parent?.type.name !== "imageLink" &&
                !isPlaceholderImage(node.attrs.src)
              ) {
                tr.replaceWith(
                  pos,
                  pos + node.nodeSize,
                  this.editor.schema.nodes.figure.create(null, [
                    node,
                    this.editor.schema.nodes.figcaption.create(),
                  ])
                )
                modified = true
              }
            }
          })

          if (modified) return tr
        },
      }),
      new Plugin({
        props: {
          handleDOMEvents: {
            // Prevent dragging nodes out of the figure
            dragstart: (view, event) => {
              if (!event.target) return false

              const pos = view.posAtDOM(event.target as HTMLElement, 0)
              const $pos = view.state.doc.resolve(pos)

              if ($pos.parent.type === this.type) {
                event.preventDefault()

                //event.dataTransfer
                // I think may need to hijack image drag and drag figure
                // const figure = view.nodeDOM(
                //   $pos.doc.resolve($pos.before(1)).pos
                // )
                // if (!figure) return false

                // event.dataTransfer?.setDragImage(figure, 0, 0)
                // event.dataTransfer?.setData("text", "figure")

                // console.log("PM Modified image drag", event)
              }

              return false
            },
          },
        },
      }),
    ]
  },
})

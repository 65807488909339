import { UseWriteContractReturnType } from "wagmi"
import {
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi"
import { useGetMintTotalCost } from "./useGetMintFee"
import {
  CHAINS,
  CompletedCollectible,
  PendingCollectible,
  UnsavedCollectible,
} from "@/types/collectibles"
import { getChainId } from "@/util/crypto"
import { useEffect, useState } from "react"
import { useAnalytics } from "hooks/useAnalytics"
import {
  addOrUpdateCollectible,
  getCollectorsOverview,
} from "@/api_routes/collectibles"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "@/features/noteSlice"
import { useAppDispatch } from "@/store"
import { retrieveHighlights, setPostCollectors } from "@/features/pageSlice"

interface UseContractInteractionProps {
  collectible:
    | PendingCollectible
    | CompletedCollectible
    | Omit<UnsavedCollectible, "txHash">
  functionName: string
  address: `0x${string}`
  abi: any
  args: any[]
  collectibleCostWei: bigint
  enabled: boolean
  chain: CHAINS
}

interface UseContractInteractionReturn {
  write: () => void
  //prepareError: SimulateContractErrorType | null
  isTxnConfirmed: boolean

  /**
   * The transaction hash, immediately when the txn is subsmitted.
   *
   * It does NOT need to be confirmed at this point.
   */
  txnHash: `0x${string}` | undefined
  error: Error | null
  simulateResult?: any[]
  writeResult?: UseWriteContractReturnType
  pending: boolean
}

function useContractInteraction({
  functionName,
  address,
  abi,
  chain,
  args,
  enabled,
  collectibleCostWei,
  collectible,
}: UseContractInteractionProps): UseContractInteractionReturn {
  console.log("useContractInteraction", {
    functionName,
    address,
    abi,
    chain,
    args,
    enabled,
    collectibleCostWei,
    collectible,
  })

  const chainId = getChainId(chain)
  const note = useSelector(selectCurrentNote)
  const dispatch = useAppDispatch()

  const { track } = useAnalytics()

  const mintData = useGetMintTotalCost({
    collectibleCostWei,
    chain: chain,
    disabled: !enabled,
  })

  const simulateData = useSimulateContract({
    address,
    abi,
    chainId,
    functionName,
    args,
    value: BigInt(mintData.totalCost || 0),
    query: { enabled, retry: false },
  })

  const [buyTriggered, setBuyTriggered] = useState(false)

  const writeData = useWriteContract()
  const txReceipt = useWaitForTransactionReceipt({
    hash: writeData.data,
    chainId,
    confirmations: 2,
    pollingInterval: 1000,
  })

  const write = () => {
    setBuyTriggered(true)
    track("collectible buy triggered")
  }

  useEffect(() => {
    if (!buyTriggered) return

    if (!simulateData?.data?.request) {
      console.warn(
        "Write contract data missing request, so unable to proceed with buying."
      )
      return
    }

    writeData.writeContract(simulateData?.data?.request)
    setBuyTriggered(false)
  }, [buyTriggered, simulateData.data])

  const error = simulateData?.error || writeData.error

  const pending =
    simulateData.isPending || writeData.isPending || txReceipt.isLoading

  useEffect(() => {
    track("collectible mint error", {
      error,
      functionName,
      address,
      chain,
      args,
    })
  }, [error])

  useEffect(() => {
    if (!writeData.data) {
      console.warn(
        "Write data is missing, so unable to proceed with updating collectible."
      )
      return
    }

    if (!("txHash" in collectible)) {
      (collectible as UnsavedCollectible).txHash = writeData.data
    }

    console.log("Updating collectible", { collectible, txReceipt })

    addOrUpdateCollectible(collectible as UnsavedCollectible).then((res) => {
      if (!note?.id) {
        console.error("Note ID is missing, so unable to retrieve highlights.")
        return
      }
      dispatch(retrieveHighlights(note.id))
      if (res?.success) {
        getCollectorsOverview(res.id).then((res) => {
          dispatch(setPostCollectors(res))
        })
      }
    })
  }, [txReceipt.isSuccess, writeData.data])

  console.log("useContractInteraction", {
    pending,
    error,
    simulateData,
    writeData,
    txReceipt,
  })

  return {
    write,
    error,
    pending,
    txnHash: writeData.data,
    isTxnConfirmed: txReceipt.isSuccess,
    //writeData,
  }
}

export default useContractInteraction

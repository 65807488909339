import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { isErrorResponse } from "@types"
import { Thunk } from "../store"
import { setError } from "./errorSlice"
import { HYDRATE } from "next-redux-wrapper"
import { RootState } from "store"
import { Job } from "@/types/jobs"
import { getJob as apiGetJob } from "api_routes/jobs"

interface JobState {
  // Either the latest email import job or one currently in progress.
  emailImportJob: Job | null
}

const initialState: JobState = {
  emailImportJob: null,
}

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setEmailImportJob: (state, action: PayloadAction<Job>) => {
      state.emailImportJob = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.emailImportJob,
      }
      nextState.initialLoad = false
      return nextState
    },
  },
})

const { setEmailImportJob } = jobSlice.actions

// Retrieve job details from API.
export const getJob =
  (jobId: string): Thunk<Promise<Job | null>> =>
  async (dispatch) => {
    const data = await apiGetJob(jobId)

    if (isErrorResponse(data)) {
      dispatch(setError(data))
      return null
    }

    dispatch(setEmailImportJob(data))

    return data
  }

export const selectLatestEmailImportJob = (state: RootState) => {
  if (!state.jobs) return

  return state.jobs.emailImportJob
}

export const setFakeEmailImportJobToShowError =
  (job: Job): Thunk =>
  async (dispatch) => {
    dispatch(setEmailImportJob(job))
  }

export default jobSlice.reducer

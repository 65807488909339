import { AxiosResponse } from "axios"
import {
  BlogSubscriptionAndMembership,
  ErrorResponse,
  ImageWithPlaceholder,
  Membership,
  MembershipDiscount,
  MembershipSubscriberCountResp,
  PlanType,
  UserSubscriptionAndMembership,
  isErrorResponse,
} from "@/types/types"

import { params, axios } from "./resolve"
import { StripePaymentMethodObj } from "@/types/stripe"

const API = "/blogs"

/**
 * Creates a new membership.
 * @param blogId Blog ID for which you want to create the membership.
 * @param membership Membership object to create.
 * @returns Returns the Firestore record of the newly created membership.
 */
export async function createMembership(
  blogId: string,
  membership: Membership
): Promise<Membership | ErrorResponse> {
  const res: AxiosResponse<Membership | ErrorResponse> = await axios.post(
    `${API}/${blogId}/memberships`,
    { membership },
    params
  )

  return res.data
}

export async function getMemberships(blogId: string): Promise<Membership[]> {
  console.log("getMemberships", blogId)
  if (!blogId) return []

  try {
    const res: AxiosResponse<Membership[]> = await axios.get(
      `${API}/${blogId}/memberships`
    )

    console.log("getMemberships", res.data)

    return res.data
  } catch (err) {
    console.error("Exception occurred fetching memberships.", err)

    return []
  }
}

export async function getPlanDiscount(
  blogId: string,
  membershipId: string,
  planId: string | null,
  couponId: string | null
): Promise<MembershipDiscount | ErrorResponse | null> {
  console.log("getPlanDiscount", planId)
  if (!blogId || !membershipId || !planId || !couponId) return null

  try {
    const res: AxiosResponse<MembershipDiscount | ErrorResponse | null> =
      await axios.get(
        `${API}/${blogId}/memberships/${membershipId}/plans/${planId}/discounts/${couponId}`
      )

    console.log("getPlanDiscount", res.data)

    return res.data
  } catch (err) {
    console.error("Exception occurred fetching plan discount.", err)

    return null
  }
}

export async function updateMembership(
  blogId: string,
  membership: Membership
): Promise<string> {
  const res: AxiosResponse<string> = await axios.put(
    `${API}/${blogId}/memberships/${membership.id}`,
    { membership },
    params
  )
  return res.data
}

export async function deleteMembership(
  blogId: string,
  membershipId: string
): Promise<{ notify: boolean }> {
  const res: AxiosResponse<{ notify: boolean }> = await axios.delete(
    `${API}/${blogId}/memberships/${membershipId}`,
    params
  )
  return res.data
}

export async function subscribeToMembership(
  blogId: string,
  noteId: string | null,
  redirectTo: string,
  membershipId: string,
  planType: PlanType,
  planId: string,
  coupon: string | null,
  paymentMethod?: StripePaymentMethodObj
): Promise<string> {
  const res: AxiosResponse<{ clientSecret: string }> = await axios.post(
    `${API}/${blogId}/memberships/${membershipId}/subscribe`,
    {
      noteId,
      redirectTo,
      planType,
      planId,
      coupon,
      paymentMethod,
    }
  )

  return res.data.clientSecret
}

export async function getSubscriptions(): Promise<
  BlogSubscriptionAndMembership[]
> {
  const res: AxiosResponse<BlogSubscriptionAndMembership[]> =
    await axios.get(`users/subscriptions`)
  return res.data
}

export async function getBlogSubscriptions(
  blogId: string
): Promise<UserSubscriptionAndMembership[]> {
  const res: AxiosResponse<UserSubscriptionAndMembership[]> = await axios.get(
    `${API}/${blogId}/subscribers`
  )
  return res.data
}

export async function unsubscribeFromMembership(
  blogId: string,
  membershipId: string,
  subscriptionId: string
): Promise<string> {
  const res: AxiosResponse<string> = await axios.post(
    `${API}/${blogId}/memberships/${membershipId}/unsubscribe/${subscriptionId}`
  )
  return res.data
}

export async function uploadMembershipImage(
  file: File | Blob
): Promise<undefined | ImageWithPlaceholder> {
  const formData = new FormData()

  formData.append("file", file)

  const res: AxiosResponse<ErrorResponse | { image: ImageWithPlaceholder }> =
    await axios.post(`notes/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

  if (isErrorResponse(res.data)) {
    console.error("Unable to upload image: ", res.data)
    return
  }

  return res.data.image
}

export async function removeMembershipImage(
  imageUrl: string
): Promise<boolean> {
  console.log("Removing image ", imageUrl)
  const res: AxiosResponse<boolean> = await axios.delete(`notes/image`, {
    data: { imageUrl },
  })

  return res.data
}

export async function getNumberOfSubscribersForMembership(
  blogId: string,
  membershipId: string
): Promise<MembershipSubscriberCountResp | undefined> {
  const res: AxiosResponse<MembershipSubscriberCountResp | ErrorResponse> =
    await axios.get(
      `${API}/${blogId}/memberships/${membershipId}/subscribers/count`
    )

  if (isErrorResponse(res.data)) {
    console.error("Error retrieving membership count.", res.data)
    return
  }

  return res.data
}

import { useReadContract } from "wagmi"
import { erc721FactoryABI } from "@/util/typechain"

import { getChainId } from "@/util/crypto"
import { CHAINS } from "@/types/collectibles"
import { COLLECTIBLE_DATA } from "@/util/crypto"

type FeeData = {
  fee: bigint | undefined
  isLoading: boolean
  error: Error | undefined | null
}

/**
 * Get the fee that a user must pay for a collectible.
 *
 * This returns the mint fee ONLY (not the total cost of the collectible).
 *
 * Note that this is in wei, NOT ether.
 *
 */
export function useGetMintFee(props: {
  chain: CHAINS
  disabled?: boolean
}): FeeData {
  const chainId = getChainId(props.chain)
  const factoryAddress = COLLECTIBLE_DATA[props.chain].contractAddress

  const {
    data: fee,
    error,
    isError,
    isLoading,
  } = useReadContract({
    address: factoryAddress,
    abi: erc721FactoryABI,
    chainId: chainId,
    functionName: "getMintFee",
    query: {
      enabled: !props.disabled,
    },
  })

  console.log("Mint fee", fee, isLoading, isError, error)

  return { fee, isLoading, error }
}

/**
 * Get the total cost of the mint (the fee plus the collectible cost).
 */
export function useGetMintTotalCost(props: {
  chain: CHAINS
  collectibleCostWei: bigint
  disabled?: boolean
}) {
  const { error, isLoading, fee } = useGetMintFee(props)
  const totalCost = BigInt(fee || 0) + BigInt(props.collectibleCostWei)

  return { error, isLoading, totalCost }
}

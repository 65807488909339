import { useMemo } from "react"
import useWalletName from "./useWalletName"
import { truncateString } from "util/format"
import { GetTeamMemberResp } from "@/types/teams"
import { Blog } from "@types"

type Props = {
  user?: GetTeamMemberResp
  blog?: Blog
  isPrivate?: boolean
}

type AuthorNameResp = {
  name: string
  isTruncated: boolean
  fullName: string
}

/**
 * Hook that makes it consistent to get the author's name.
 * This is used when someone leaves a comment, or in notifications.
 * isPrivate defaults to false, meaning it won't show the user's email address for instance.
 * If however you are a logged in user looking at a team member and an email address is appropriate,
 * then pass in isPrivate: true.
 */
export default function useAuthorName(props: Props): AuthorNameResp {
  const { user, blog, isPrivate } = props

  const walletName = useWalletName(
    user?.wallet_address ||
      user?.lowercase_wallet_address ||
      user?.walletAddressLowercase ||
      ""
  )

  const authorName = useMemo(() => {
    console.log("FETCHING AUTHOR NAME", user)

    // In order of priority:
    // 1. Author's set name.
    if (user?.authorName) {
      return {
        name: user.authorName,
        isTruncated: false,
        fullName: user.authorName,
      }
    }

    // 2. Author's ENS.
    if (walletName.type === "ENS") {
      return {
        name: walletName.name,
        isTruncated: false,
        fullName: walletName.name,
      }
    }

    // 3. Author's email address (only when private data enabled).
    if (isPrivate && user?.email) {
      return {
        name: user.email,
        isTruncated: false,
        fullName: user.email,
      }
    }

    // 4. Wallet address (truncated).
    if (walletName.type === "WALLET_ADDRESS" && walletName.name !== "") {
      return {
        name: truncateString(walletName.name),
        isTruncated: true,
        fullName: walletName.name,
      }
    }

    // 5. Blog name.
    if (blog?.name) {
      return {
        name: blog.name,
        isTruncated: false,
        fullName: blog.name,
      }
    }

    // 6. If all else fails, display "Anon".
    return {
      name: "Anon",
      isTruncated: false,
      fullName: "Anon",
    }
  }, [JSON.stringify(user), JSON.stringify(blog), isPrivate, walletName])

  return authorName
}

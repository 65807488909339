import Button from "components/Button"
import ErrorMessage from "components/Collectibles/ErrorMessage"
import RainbowConnectWalletBtn from "@/components/ConnectWalletButton"
import { selectBlog } from "features/blogSlice"
import { selectCurrentNote } from "features/noteSlice"
import useUser from "hooks/useUser"
import { ReactNode, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import ShareButtons from "components/ShareButtons"
import useNoteUrl from "hooks/useNoteUrl"
import { RiSparkling2Fill } from "react-icons/ri"
import useOnRightChain from "@/hooks/crypto/useOnRightChain"
import { useSwitchChain } from "wagmi"
import { CHAINS } from "@/types/collectibles"

function Text({ children }: { children: ReactNode }) {
  return <div className="text-gray-500 text-sm mt-2">{children}</div>
}
export default function CollectButton(props: {
  btnText?: string
  /**
   * The text of the collectible (eg post title),
   * for social sharing.
   */
  collectibleText: string
  desiredChain: CHAINS

  isLoading: boolean
  isSuccess: boolean

  onClick: () => void
  error?: any
}) {
  const note = useSelector(selectCurrentNote)
  const blog = useSelector(selectBlog)
  const user = useUser()

  let { fullNoteUrl } = useNoteUrl({ blog, note })

  if ("wallet_address" in user && user.wallet_address) {
    fullNoteUrl = fullNoteUrl + "?referrer=" + user.wallet_address
  }
  const { switchChain } = useSwitchChain()
  const { onRightChain, desiredChainId } = useOnRightChain({
    chain: props.desiredChain,
  })

  const [isSwitchingChain, setIsSwitchingChain] = useState(false)

  useEffect(() => {
    console.log(
      "Switching chain use effect triggering!",
      isSwitchingChain,
      onRightChain,
      props.isLoading
    )

    if (!isSwitchingChain) return

    if (onRightChain && !props.isLoading) {
      console.log("Triggering buy...")
      props.onClick()
    }

    // If the user is switching chains, wait for it to switch then
    // trigger the buy.
  }, [isSwitchingChain, onRightChain])

  console.log("Are we on the right chain?", onRightChain)

  return (
    <div className="flex justify-center flex-col w-full">
      {!user.walletReadyAndLoggedIn && (
        <>
          <RainbowConnectWalletBtn text="Connect wallet" />
        </>
      )}
      {user.walletReadyAndLoggedIn && !props.isSuccess && (
        <Button
          type="button"
          loading={props.isLoading}
          disabled={!!props.error && !props.error.message?.includes("rejected")}
          onClick={async () => {
            if (!blog?.id || !note?.id) return

            if (!onRightChain) {
              switchChain({ chainId: desiredChainId })
              setIsSwitchingChain(true)
              return
            }

            console.log("Triggering buy...")
            props.onClick()
          }}
        >
          {props.btnText || "Collect highlight"}
          <RiSparkling2Fill className="ml-2" />
        </Button>
      )}

      {/* Only display the error message if the user doesn't already own */}
      {props.error && (
        <>
          <ErrorMessage error={props.error} />
        </>
      )}

      {props.isLoading && <Text>Loading...</Text>}

      {props.isSuccess && (
        <>
          <Text>Successfully collected! Share:</Text>

          <ShareButtons
            url={fullNoteUrl}
            socialMediaShareText={`I just collected ${props.collectibleText} on Paragraph.`}
          />
        </>
      )}
    </div>
  )
}

import { Blog } from "@types"
import { captureException, captureMessage } from "@sentry/nextjs"
import { useRouter } from "next/router"
import axios from "axios"
import { useCallback } from "react"
import { useAnalytics } from "hooks/useAnalytics"

const SHAREMINT_URL = "https://sharemint.xyz/api/external/save-paragraph"

export default function useSharemint() {
  const router = useRouter()
  const { track } = useAnalytics()

  // This callback is invoked when users subscribe to a newsletter.
  // It sends the request to the Sharemint API, ONLY when the referral query parameter
  // ?r=[id] is present.
  // This tracks Sharemint referrals.
  const pingSharemint = useCallback(
    async ({ blog, email }: { blog?: Blog; email?: string }) => {
      console.log(
        "Seeing if we should ping sharemint...",
        router.query.r,
        blog,
        email
      )

      if (!router.query.r) {
        console.log("No referral ID so not pinging Sharemint")
        return
      }

      if (!blog || !email) {
        captureMessage(
          "Unable to ping shareMint with referral, since blog or email missing",
          { extra: { blog, email }, level: "error" }
        )
        console.warn(
          "Blog or email missing from props, but referral ID present, so not pinging Sharemint"
        )
        return
      }

      // Proper POST body
      const postBody = {
        // Remove the ampersand.
        paragraphSlug: blog.lowercase_url.slice(1),
        invitedById: router.query.r,
        email: email,
      }

      track("sharemint referral", postBody)

      console.log("About to ping sharemint...", postBody)

      try {
        const resp = await axios.post(SHAREMINT_URL, postBody)
        console.log("Got response with Sharemint referral!", resp)

        if (resp.status !== 200) {
          captureMessage("ShareMint returned exception", {
            extra: { resp },
            level: "error",
          })
        }
      } catch (err) {
        captureException(err)
      }
    },
    [router.query.r, track]
  )

  return pingSharemint
}

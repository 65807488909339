import { ArrowRightIcon } from "@heroicons/react/24/solid"
import Link from "next/link"
import useUser from "hooks/useUser"
import Banner from "components/Banner"

import { useSelector } from "react-redux"
import { selectError } from "features/errorSlice"
import { useRouter } from "next/router"
import Button from "components/Button"
import {
  selectActiveSubscription,
  setPasswordAndLoginUser,
} from "features/userSlice"

import EmailPrompt from "./EmailPrompt"
import WalletPrompt from "./WalletPrompt"
import useSubscribeModalState from "hooks/useSubscribeModalState"
import { AiFillCaretRight } from "react-icons/ai"
import clsx from "clsx"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { FormEvent, useEffect, useState } from "react"
import LoginEmailAndPassword from "@/components/Login/LoginEmailAndPassword"
import Logo from "@/components/Logo"
import { selectNextState } from "@/features/blogSlice"
import ConnectWalletInner from "@/components/Login/ConnectWalletInner"
import { useAppDispatch } from "@/store"

interface modelProps {
  initialFocusRef?: React.Ref<any>
  continueCallback: () => void
  initialPopup?: boolean
  setOpen: (open: boolean) => void
  appearOnScroll?: boolean
  /**
   * Pass this in as true if you want to not immediately subscribe the user
   * when the modal is opened, for instance when the modal contents is actually
   * being used as an embedded component.
   */
  doNotSubscribeImmediately?: boolean
  /**
   * If this is set, we'll prefill the email field with this value.
   */
  emailPrefilled?: string
}

/**
 * Wraps content in the subscribe modal and provides it with the white background.
 */
function Wrapper(props: {
  children: React.ReactNode
  props: modelProps
  appearOnScroll?: boolean
}) {
  const error = useSelector(selectError)

  const isExistingUserButLoggedOutErrorOrIncorrectPassword =
    error.msg === "LOGIN"
  error.msg === "SET_PASSWORD"

  const isAlreadySubscribedError = error.code === "ALREADY_SUBSCRIBED"

  return (
    <div className="min-h-screen flex justify-center flex-col py-4 sm:py-0 px-4">
      <div
        className={clsx(
          "flex flex-col border-gray-150 rounded-xl border",
          props.appearOnScroll ? "bg-white" : ""
        )}
      >
        <div className="ml-auto p-2">
          <XMarkIcon
            className="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer"
            onClick={() => props.props.setOpen(false)}
          />
        </div>

        <div className="divide-y-2 divide-gray-100 rounded-none">
          {!isAlreadySubscribedError && props.children}
          {isAlreadySubscribedError && (
            <div className="pb-8 pt-12 text-center">
              <span className="text-s">
                You're already subscribed to this publication.
              </span>
              <div
                className={`mt-4 flex justify-center flex-col space-y-8 max-w-md mx-auto`}
              >
                <Button
                  onClick={() => props.props.setOpen(false)}
                  replaceClassName="mt-12 text-xs w-32 mx-auto text-gray-700 flex flex-row justify-center"
                >
                  Continue <ArrowRightIcon className="h-4 w-4 ml-1" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {error.msg &&
        !isExistingUserButLoggedOutErrorOrIncorrectPassword &&
        !isAlreadySubscribedError && (
          <Banner className="mt-4" type={error.type} text={error.msg} />
        )}
      <Footer {...props} />
    </div>
  )
}

/**
 * This is used for publications that ONLY have FREE memberships , in the SubscribeModal.
 * It causes the user to enter their email and prompt to connect their wallet,
 * since we don't need them to choose a membership.
 *
 * For paid memberships in the SubscribeModal, we only prompt to choose an email first,
 * then memberships, then lastly connect their wallet.
 */
export function EmailAndWallet(props: modelProps) {
  const [email, setEmail] = useState(props.emailPrefilled || "")
  const [currState, setCurrState] = useState("EMAIL_ENTRY")
  const user = useUser()

  const subscription = useSelector(selectActiveSubscription)
  const { blogHasPaidMemberships } = useSubscribeModalState()
  const nextState = useSelector(selectNextState)

  useEffect(() => {
    if (nextState) {
      setCurrState(nextState)
    }
  }, [nextState])

  console.log(
    "[SUBSCRIPTION] Rendering collect email and wallet stuff...",
    currState,
    subscription,
    user.hasEmailAndWallet
  )

  useEffect(() => {
    // If the user is not subscribed or does not have both email and wallet connected, no need to do anything
    // here.
    if (!subscription || !user.hasEmailAndWallet) return

    // Otherwise, proceed automatically to the next stage.
    props.continueCallback()
  }, [])

  const userHasEmailAndIsSubscribed = user.hasEmail && !!subscription

  /**
   * If the user has an email and only if the blog has no paid memberships do we want to show the wallet prompt.
   * (If the blog has paid memberships we show the wallet prompt at the very end of the modal).
   */
  const showWalletPrompt =
    userHasEmailAndIsSubscribed && !user.hasWallet && !blogHasPaidMemberships

  return (
    <>
      <Wrapper props={props} appearOnScroll={props.appearOnScroll}>
        <>
          {currState === "LOGIN" && (
            <SubscribeLogin
              emailPrefilled={props.emailPrefilled || email}
              callback={() => setCurrState("WALLET_ENTRY")}
            />
          )}
          {currState === "SET_PASSWORD" && (
            <SubscribeSetPassword
              email={props.emailPrefilled || email}
              callback={() => setCurrState("WALLET_ENTRY")}
            />
          )}
          {currState === "EMAIL_ENTRY" && (
            <EmailPrompt
              initialFocusRef={props.initialFocusRef}
              initialPopup={props.initialPopup}
              appearOnScroll={props.appearOnScroll}
              doNotSubscribeImmediately={props.doNotSubscribeImmediately}
              emailPrefilled={props.emailPrefilled}
              email={email}
              setEmail={setEmail}
            />
          )}
          {props.appearOnScroll && !showWalletPrompt && (
            <div
              className="flex justify-center text-gray-500 py-3 text-sm hover:text-gray-900 hover:bg-gray-50 hover:cursor-pointer rounded-b-lg"
              onClick={() => {
                props.setOpen(false)
              }}
            >
              No thanks
            </div>
          )}
          {currState === "WALLET_ENTRY" && <WalletPrompt />}
        </>
      </Wrapper>
    </>
  )
}

function Footer({ props }: { props: modelProps }) {
  const user = useUser()
  const subscription = useSelector(selectActiveSubscription)
  const userHasEmailAndIsSubscribed = user.hasEmail && !!subscription

  return (
    <div>
      {props.initialPopup && (
        <div
          className="items-center justify-center text-sm text-center text-gray-400 mt-3
            hover:cursor-pointer hover:text-gray-500
  
            "
          onClick={() => {
            props.setOpen(false)
          }}
        >
          <span className="font-bold ">
            No thanks
            <AiFillCaretRight className="inline-block ml-1" />
          </span>
        </div>
      )}

      <AlreadySubscriberLink props={props} />

      {userHasEmailAndIsSubscribed && (
        <div className="flex justify-center mt-6">
          <Button
            text="Continue"
            type="button"
            replaceClassName="px-3 py-4  mt-4 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm leading-4 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full flex justify-center "
            onClick={() => {
              props.continueCallback?.()
            }}
          >
            <ArrowRightIcon
              className="w-5 h-5 ml-2 text-gray-400"
              aria-hidden="true"
            />
          </Button>
        </div>
      )}
    </div>
  )
}

function AlreadySubscriberLink({ props }: { props: modelProps }) {
  const router = useRouter()
  const user = useUser()
  const nextState = useSelector(selectNextState)

  if (
    props.initialPopup ||
    user.loggedIn ||
    props.appearOnScroll ||
    // Only show the "already a subscriber" link if we aren't going to prompt the user to login
    // or set a password
    nextState === "LOGIN" ||
    nextState === "SET_PASSWORD"
  )
    return null

  return (
    <div className="items-center justify-center text-sm text-center text-gray-400 mt-3">
      Already a subscriber?{" "}
      <span className="font-bold underline">
        <Link href={`/login?redirect=${router.asPath}`}>Log in</Link>.
      </span>
    </div>
  )
}

const SubscribeLogin = ({
  emailPrefilled,
  callback,
}: {
  emailPrefilled?: string
  callback: () => void
}) => {
  return (
    <div className="flex flex-col justify-center p-6">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex content-center">
          <Logo className="h-12 m-auto" />
        </div>
        <p className="text-center mt-6 mb-6 text-2xl font-bold">
          Subscribed! Sign in to your account to configure your subscription
        </p>
      </div>

      <LoginEmailAndPassword
        prefillEmailAddress={emailPrefilled}
        additionalLoginCallback={callback}
      />
      <ConnectWalletInner />
    </div>
  )
}

const SubscribeSetPassword = ({
  email,
  callback,
}: {
  email: string
  callback: () => void
}) => {
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    return () => {
      setButtonLoading(false)
    }
  }, [])

  const formSubmitted = async (ev: FormEvent) => {
    setButtonLoading(true)
    ev.preventDefault()
    console.log("Adding password to user..." + email)
    await dispatch(setPasswordAndLoginUser({ email, password }))
    callback?.()
  }

  return (
    <div className="flex flex-col justify-center p-6">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex content-center">
          <Logo className="h-12 m-auto" />
        </div>
        <p className="text-center mt-6 mb-6 text-2xl font-bold">
          Subscribed! Set your password
        </p>
      </div>

      <form className="space-y-6" onSubmit={formSubmitted}>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              value={password}
              autoComplete="current-password"
              required
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <Button
            text="Set Password"
            loading={buttonLoading}
            className="flex w-full ml-0"
            nonCustomizedColor={true}
          />
        </div>
      </form>
    </div>
  )
}

import { Extensions, JSONContent } from "@tiptap/react"
import { generateHTML } from "@tiptap/html"

const parseJson = (json: JSONContent | string): JSONContent => {
  try {
    return JSON.parse(json as string)
  } catch (error) {
    return json as JSONContent
  }
}

// Renamed the image block therefore need to map from the
// old name "custom-image" to the new one "image"
export const fixImageNodeName = (json: JSONContent) => {
  const content: any = json.content?.map((element: any) => {
    if (element && element.type === "custom-image")
      return { ...element, type: "image" }
    else if (Array.isArray(element.content)) return fixImageNodeName(element)
    else return element
  })
  return { ...json, content }
}

const replaceVariables = (html?: string) => {
  let updatedHtml = html || ""
  updatedHtml = updatedHtml.replace(/{{DOMAIN}}/g, "https://paragraph.xyz")
  updatedHtml = updatedHtml.replace(
    /{{IMAGE_OPTIMIZATION_PARAMS}}/g,
    "&w=640&q=75"
  )
  return updatedHtml
}

const removeTweetData = (html?: string) => {
  return html?.replace(/tweetData=".*"/g, "")
}

const removeAst = (html?: string) => {
  return html?.replace(/ast=".*"/g, "")
}

/**
 * Decode the escaped HTML into regular HTML by using DOMParser on the client-side only.
 * TipTap escapes HTML by default when using renderHTML, which means that when we use it elsewhere (eg to
 * generate the post preview), it's escaped. This function decodes it.
 */
function htmlDecode(input: string): string {
  if (typeof window === "undefined" || typeof DOMParser === "undefined")
    return input
  try {
    const doc = new DOMParser().parseFromString(input, "text/html")
    const decoded =
      doc.documentElement.querySelector("body")?.innerHTML || input
    return decoded
  } catch (err) {
    console.error("Unable to parse DOM", err)
    return input
  }
}

export const getHtml = (json: JSONContent | string, extensions: Extensions) => {
  if (!json || (json && Object.keys(json).length === 0)) return

  let parsedJson = parseJson(json)
  parsedJson = fixImageNodeName(parsedJson)

  let html = generateHTML(parsedJson, extensions)
  html = replaceVariables(html) || ""
  html = removeTweetData(html) || ""
  html = removeAst(html) || ""

  const decoded = htmlDecode(html)

  return decoded
}

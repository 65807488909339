import { useAppDispatch as useDispatch } from "store"
import { useAnalytics } from "hooks/useAnalytics"
import useSharemint from "hooks/useSharemint"
import { selectBlog, subscribeUserToBlog } from "features/blogSlice"
import { useSelector } from "react-redux"

export default function useSubmitEmail() {
  const { track } = useAnalytics()
  const dispatch = useDispatch()
  const pingSharemint = useSharemint()
  const blog = useSelector(selectBlog)

  const submitEmail = async (
    emailAddr: string,
    initialPopup?: boolean,
    appearOnScroll?: boolean
  ) => {
    track("subscribe email submit click", {
      emailAddr,
      origin: appearOnScroll
        ? "scroll"
        : initialPopup
          ? "landing-popup"
          : "button",
    })

    const hasSubscribed = await dispatch(subscribeUserToBlog(emailAddr))

    if (hasSubscribed) {
      console.log("User has subscribed, so pinging sharemint...")
      pingSharemint({ blog, email: emailAddr })
    }

    console.log("Dispatched this...")
  }

  return submitEmail
}

import { BaseSyntheticEvent, LegacyRef } from "react"
import { useAnalytics } from "hooks/useAnalytics"

function classNames(...classes: Array<string>) {
  return classes.filter(Boolean).join(" ")
}

interface Props {
  onClick?: (e: BaseSyntheticEvent) => void
  text?: string | React.ReactNode
  loading?: boolean
  className?: string
  replaceClassName?: string // This replace the entire class name of the button
  disabled?: boolean
  nonCustomizedColor?: boolean // Disable the color customization
  useSecondaryTheme?: boolean // Use the secondary theme
  type?: "button" | "submit" | "reset"
  children?: React.ReactNode
  ref?: LegacyRef<HTMLButtonElement> | undefined
  style?: React.CSSProperties
  // Optional. You can provide additional details here by passing in any object.
  trackingData?: object
}

const Loader = () => {
  const circleCommonClasses = "h-2.5 w-2.5 bg-current rounded-full"

  return (
    <div className="flex justify-center mx-1 my-1 leading-normal">
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  )
}

export default function Button(props: Props) {
  const { track } = useAnalytics()

  // If there is a button text props supplied, display that.
  // If no button text props is supplied, but there are child elements supplied, display no button text.
  // Finally, if neither the text props nor children props are supplied, display a default "Update" button text.
  const buttonText = props.text ? props.text : props.children ? "" : "Update"

  return (
    <button
      type={props.type || "submit"}
      className={
        props.replaceClassName ||
        classNames(
          props.className || "",
          props.nonCustomizedColor
            ? "bg-blue-500 hover:bg-blue-700 focus:ring-blue-500"
            : props.useSecondaryTheme
              ? "bg-secondary hover:bg-secondary-800 focus:ring-secondary-500"
              : "bg-primary hover:bg-primary-800 focus:ring-primary-500",

          "dark-btn inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm rounded-md focus:outline-none focus:ring-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-100"
        )
      }
      onClick={(e) => {
        track("click button", {
          button_text: buttonText,
          ...props.trackingData,
        })

        if (!props.onClick) return
        props.onClick(e)
      }}
      disabled={props.loading || props.disabled}
      ref={props.ref}
      style={props.style}
    >
      {props.loading && <Loader />}
      {!props.loading ? buttonText : ""}
      {!props.loading ? props.children : null}
    </button>
  )
}

import MintNewToken from "./MintNewToken"
import ImportTokenDetails from "./ImportToken"
import UseExistingToken from "./UseExistingToken"
import { Dialog } from "@headlessui/react"
import { GateTypeDescriptions } from "../GateTypeDescriptions"
import Banner from "components/Banner"
import { DoesGateExistInGroup } from "../GateModalHelperFunctions"
import { SharedProps } from "../SharedProps"

export default function GateModalStepMintOrImportToken(props: SharedProps) {
  return (
    <div>
      <Dialog.Title
        as="h3"
        className="text-2xl font-bold text-center text-gray-900 leading-6"
      >
        {props.mintOrImportToken == "MINT"
          ? "Mint new token"
          : props.mintOrImportToken == "IMPORT"
            ? "Import token"
            : "Choose token settings"}
      </Dialog.Title>
      {!props.uiSensitiveExistingGateGroups ||
      props.addingGate ||
      !DoesGateExistInGroup(
        props.uiSensitiveExistingGateGroups,
        "TOKEN",
        props.selectedGateGroupId
      ) ? (
        <div className="mt-4">
          <div className="px-1">
            {props.mintOrImportToken == "IMPORT" && (
              <ImportTokenDetails {...props} />
            )}

            {props.mintOrImportToken == "MINT" && <MintNewToken {...props} />}

            {props.selectedExistingParagraphTokenId != "" && (
              <UseExistingToken {...props} />
            )}
          </div>
        </div>
      ) : (
        // If the gate does exist and we're not adding a new gate (so therefore editing), show a different description.
        <div className="mt-4 text-gray-600">
          {props.selectedGateType && !props.addingGate
            ? "Delete selected gates."
            : GateTypeDescriptions?.find((g) => g.gateType == "TOKEN")
                ?.selectedOptionEditExistingDescription}
        </div>
      )}
      {props.mintError != "" && (
        <div className="mt-5">
          <Banner type="error" text={props.mintError} />
        </div>
      )}
      {props.createGateError != "" && (
        <div className="mt-5">
          <Banner type="error" text={props.createGateError} />
        </div>
      )}
    </div>
  )
}

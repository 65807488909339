import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { useEffect, useRef } from "react"
import CollectorTable from "./CollectorTable"

import { EnrichedCollectRecord } from "@/types/collectibles"
import { getCollectors } from "api_routes/collectibles"
import { selectPostCollectible, selectPostCollectors } from "features/pageSlice"
import { useState } from "react"
import { useSelector } from "react-redux"
import { CollectorLoader } from "components/Placeholder"
import InfiniteScroll from "react-infinite-scroller"
import { selectBlog } from "features/blogSlice"

interface modelProps {
  open: boolean
  setOpen: (val: boolean) => void
}

function Placeholders(props: { total: number }) {
  const { total } = props
  const Placeholders = []
  for (let i = 0; i < Math.min(25, total); i++) {
    Placeholders.push(
      <div className="py-2" key={i}>
        <CollectorLoader />
      </div>
    )
  }
  return <>{Placeholders}</>
}

export default function CollectorModal({ open, setOpen }: modelProps) {
  const cancelButtonRef = useRef(null)
  const postCollectible = useSelector(selectPostCollectible)
  const { total: initialTotal } = useSelector(selectPostCollectors)
  const [records, setRecords] = useState<Array<EnrichedCollectRecord>>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const scrollParentRef = useRef<HTMLDivElement | null>(null)
  const blog = useSelector(selectBlog)

  // Fetch initial data when modal is opened.
  useEffect(() => {
    if (!open) return
    console.log("INFINITE INITIALDATA FETCH")
    _fetchData(0)
  }, [open])

  /**
   * Unfortunately we only support this for new collectibles, since we
   * only began emitting onchain events for new ones.
   */
  if (postCollectible?.collectible.version === 0 || !postCollectible) {
    return null
  }

  async function _fetchData(page: number) {
    console.log("FETCHING NEW INFINITE DATA!", page)
    if (!postCollectible || isFetching) return
    setIsFetching(true)
    const lastCreatedAt =
      records.length > 0 ? records[records.length - 1]?.collectedAt : undefined
    const newRecords = await getCollectors(
      postCollectible.collectible.id,
      20,
      lastCreatedAt,
      blog.id
    )

    const combined = [...records, ...newRecords]
    setRecords(combined)
    setIsFetching(false)
    console.log("SET RECORDS", combined, initialTotal)
  }

  return (
    <div>
      <Transition.Root show={open}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-50 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
          id="collector-modal"
          ref={scrollParentRef}
        >
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              className="fixed inset-0"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              enter="ease-out duration-300"
              className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <button
                  type="button"
                  className="absolute text-gray-400 top-4 right-4 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </button>
                <div>
                  <div className="overflow-x-auto text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold text-gray-900 leading-6"
                    >
                      {initialTotal} Collectors
                    </Dialog.Title>
                    <InfiniteScroll
                      hasMore={records?.length < initialTotal}
                      // On initial page load, display the min{20, initialTotal} placeholders.
                      // Then, when the user scrolls, only display one.
                      loader={
                        <Placeholders
                          total={
                            records.length === 0
                              ? Math.min(20, initialTotal)
                              : 1
                          }
                        />
                      }
                      loadMore={_fetchData}
                      useWindow={false}
                      initialLoad={false}
                      getScrollParent={() => scrollParentRef.current}
                    >
                      <CollectorTable records={records} />
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

import { loadStripe } from "@stripe/stripe-js/pure"
import { createCheckoutSessionForCustomDomain } from "api_routes/stripe"

import { createPortalSession } from "../api_routes/stripe"
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import Button from "components/Button"
import { useState } from "react"
import { subscribeToMembership } from "api_routes/memberships"
import { PlanType } from "@types"
import { Stripe } from "@stripe/stripe-js"
import clsx from "clsx"
import { pollForSubscriptionCreationStatus } from "components/Memberships/SharedFunctions"
import { useAppDispatch } from "store"
import Spinner from "components/Spinner"
import { useSelector } from "react-redux"
import { selectCurrentNote } from "features/noteSlice"

export function StripePaymentForm({
  blogId,
  membership,
  planType,
  plan,
  coupon,
  isLoadingExistingPayment,
  successCallback,
}: {
  blogId: string
  membership: string
  planType: PlanType
  plan: string
  coupon: string | null
  isLoadingExistingPayment: boolean
  successCallback?: () => void
}) {
  const options = {
    appearance: {
      /*...*/
    },
  }

  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK || "")

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm
        blog={blogId}
        membership={membership}
        planType={planType}
        plan={plan}
        coupon={coupon}
        isLoadingExistingPayment={isLoadingExistingPayment}
        successCallback={successCallback}
      />
    </Elements>
  )
}

function SetupForm({
  blog: blogId,
  membership: membershipId,
  planType,
  plan,
  coupon,
  isLoadingExistingPayment,
  successCallback,
}: {
  blog: string
  membership: string
  planType: PlanType
  plan: string
  coupon: string | null
  /**
   * If true, the button will be disabled and show a spinner.
   * This is true whenever the user is subscribing with an existing payment method.
   */
  isLoadingExistingPayment: boolean
  successCallback?: () => void
}) {
  const dispatch = useAppDispatch()
  const noteId = useSelector(selectCurrentNote)?.id || null
  const stripe = useStripe() as Stripe
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)

  const handleError = (error: any) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setErrorMessage(undefined) // Reset error state.

    const card = elements?.getElement(CardElement)

    if (!stripe || !elements || !card) return

    setLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    console.log("User is subscribing with new payment method")

    // Create the payment setup intent and obtain clientSecret
    const clientSecret = await subscribeToMembership(
      blogId,
      noteId,
      "",
      membershipId,
      planType,
      plan,
      coupon
    )

    console.log("Created clientSecret", clientSecret)

    if (!clientSecret) {
      handleError({ message: "Something went wrong" })
      return
    }

    // Confirm the payment method using the details collected by the Payment Element
    const { error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
      },
      // TODO: This should redirect to the blog page
      return_url: window.location.href,
    })

    if (error) {
      console.log("Failed to setup payment method", error)
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error)
    } else {
      console.log("Successfuly setup payment method", error)
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    await pollForSubscriptionCreationStatus(blogId, membershipId, dispatch)

    setLoading(false)

    successCallback?.()
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full mt-4 mb-8 max-w-xs mx-auto flex flex-col justify-center items-center"
    >
      <CardElement />
      <Button
        type="submit"
        replaceClassName={clsx(
          "bg-primary shadow-sm hover:bg-primary-800 w-32",
          "mx-2 mt-6 block rounded-md py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600",
          "disabled:opacity-50 disabled:cursor-not-allowed"
        )}
        disabled={!stripe || loading || isLoadingExistingPayment}
      >
        <div className="flex justify-center items-center relative w-full">
          Checkout
          {loading && (
            <div className="absolute right-0 top-1">
              <Spinner height={4} width={4} />
            </div>
          )}
        </div>
      </Button>

      {errorMessage && <div className="mt-2 text-red-500">{errorMessage}</div>}
    </form>
  )
}

async function getPortalUrl() {
  const session = await createPortalSession()
  return session
}

export async function redirectToCheckoutForCustomDomain({
  redirectUrl,
}: {
  redirectUrl: string
}) {
  // stripeAccount is for connected accounts

  // TODO: Make this a singleton to prevent multiple calls to loadStripe
  const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK || "")

  // TODO: If this fails, handle it gracefully. Right now we dont!
  const session = await createCheckoutSessionForCustomDomain({
    redirectUrl,
  })

  return stripe?.redirectToCheckout({
    sessionId: session.sessionId,
  })
}
